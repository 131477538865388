import { CardPresentationProps } from '@src/shared/components/card-presentation/card-presentation.interface';
import { CSLCArticle } from '@src/shared/contentstack';

export const quoteFeatureMapper = (cards: CSLCArticle[]) => {
  return cards?.map((card) => {
    const { logo_card, featured_image } = card || {};
    const imageLogic = featured_image?.image?.image
      ? { image: featured_image?.image?.image }
      : { icon: logo_card?.logo.image };

    return {
      url: card?.url,
      variant: 'tall',
      titleSize: 18,
      type: 'quote',
      title: '',
      subtitle: card?.key_quote,
      ctaOrAttribution: card?.customer,
      background: card?.logo_card?.card_color?.colors,
      ...imageLogic,
    };
  }) as CardPresentationProps[];
};
