import {
  ModalCloseButtonStyled,
  ModalContentStyled,
  ModalOverlayStyled,
  ModalStyled,
} from './modal.style';
import { render } from 'react-dom';
import React from 'react';
import { CloseIcon } from '../icons';

export interface ModalProps {
  children?: any;
  showCloseButton?: boolean;
  isModalActive?: boolean;
  callbackOnCloseModal: () => void;
  size: {
    width: number;
    height: number;
    unit: string;
  };
  childrenProps?: any;
}

export const Modal = (props: ModalProps) => {
  const closeModal = (e: any) => {
    props.callbackOnCloseModal();
  };

  return (
    <ModalStyled
      className={props.isModalActive ? 'revealing' : 'revealing out'}
    >
      <ModalContentStyled
        width={props.size.width}
        height={props.size.height}
        unit={props.size.unit}
        className="modal-content"
      >
        {props.showCloseButton && (
          <ModalCloseButtonStyled onClick={closeModal}>
            <CloseIcon></CloseIcon>
          </ModalCloseButtonStyled>
        )}
        {props.children}
      </ModalContentStyled>
      <ModalOverlayStyled className="modal-overlay"></ModalOverlayStyled>
    </ModalStyled>
  );
};

export abstract class ModalHandler {
  static open(modalProps: ModalProps) {
    const div = document.createElement('div');
    div.setAttribute('id', 'modal');
    document.body.appendChild(div);
    const customModal = React.createElement(Modal, {
      isModalActive: true,
      children: modalProps.children,
      callbackOnCloseModal: modalProps.callbackOnCloseModal,
      showCloseButton: modalProps.showCloseButton,
      size: modalProps.size,
    });
    const ModalParent = document.getElementById('modal');
    if (ModalParent) {
      render(customModal, ModalParent);
    }
  }

  static close() {
    const modal = document.getElementById('modal');
    if (modal) {
      modal.remove();
    }
  }
}
