import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';

const panelConfig = {
  featureLogo: {
    width: getStyleSizes(112),
    height: getStyleSizes(40),
  },
  shared: {
    borderRadius: getStyleSizes(8),
  },
};

export const FeatureLogoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${getStyleSizes(28)};

  & > a {
    margin-top: ${getStyleSizes(16)};
  }
`;
