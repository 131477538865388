import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseIcon: React.FunctionComponent<SvgIconProps> = (
  props
): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="T&amp;C---desktop"
          transform="translate(-1258.000000, -2107.000000)"
          stroke="#ffffff"
          stroke-width="2.5"
        >
          <g transform="translate(0.000000, -30.000000)">
            <g id="X" transform="translate(1259.000000, 2138.000000)">
              <line x1="0" y1="0" x2="16" y2="16" id="Path-8"></line>
              <line
                x1="0"
                y1="0"
                x2="16"
                y2="16"
                id="Path-8"
                transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "
              ></line>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
