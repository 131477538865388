import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowIcon: React.FunctionComponent<SvgIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d="M17.028 10.0343C16.9482 9.975 16.8646 9.92144 16.778 9.87392L7.47833 3.92237L7.44411 3.89749L7.38357 3.86155C7.21199 3.7682 7.01733 3.73216 6.8257 3.75828C6.63407 3.78439 6.45462 3.87141 6.31141 4.00766C6.1682 4.14392 6.06809 4.3229 6.02448 4.5206C5.98088 4.71831 5.99588 4.92528 6.06746 5.11378V5.11378L8.10217 11.9997L6.06746 18.8828C5.9937 19.0772 5.98004 19.2911 6.02839 19.4942C6.07673 19.6972 6.18465 19.8792 6.33687 20.0143C6.48909 20.1494 6.67791 20.2308 6.87661 20.247C7.07531 20.2632 7.27384 20.2134 7.44411 20.1046V20.1046L7.80209 19.8862L9.08925 19.057L12.0505 17.1689L16.7885 14.1282C16.8699 14.0809 16.949 14.0292 17.0254 13.9734V13.9734C17.3262 13.7537 17.572 13.4608 17.7417 13.1198C17.9114 12.7788 18 12.3997 18 12.0149C18 11.6301 17.9114 11.251 17.7417 10.91C17.572 10.5689 17.3262 10.2761 17.0254 10.0564L17.028 10.0343Z" />
    </SvgIcon>
  );
};
