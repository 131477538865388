import React from 'react';
import {
  ArrowIconStyled,
  BoxedLinksSectionStyled,
  IconLinkStyled,
} from './boxed-links.styles';
import { BoxedLinksProps } from './boxed-links.interface';
import { useMainNav } from '@cms-components/header/context/main-nav.context';

export const BoxedLinks = ({ data }: BoxedLinksProps) => {
  const { closeNav } = useMainNav();
  const { box_links, box_type } = data || {};
  if (box_links && box_links.length) {
    return (
      <BoxedLinksSectionStyled type={box_type}>
        {box_links.map((link, index) => {
          const { link_url, external_link, link_text } = link || {};
          return (
            <span onClick={() => closeNav()} key={index}>
              <IconLinkStyled
                label={link_text}
                linkUrl={link_url}
                isExternal={external_link}
              />
            </span>
          );
        })}
      </BoxedLinksSectionStyled>
    );
  }
  return null;
};
