import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type NavContextProps = {
  multiple: boolean;
  opened: number[];
  open: (value: number) => void;
  close: (value: number) => void;
  closeAll: () => void;
};

const navContextDefaultValue: NavContextProps = {
  multiple: false,
  opened: [] as number[],
  open: (value: number) => value,
  close: (value: number) => value,
  closeAll: () => {},
};

const NavContext = createContext(navContextDefaultValue);

const NavProvider = ({ children, multiple }: PropsWithChildren<{ multiple: boolean }>) => {
  const [opened, setOpened] = useState<number[]>([]);

  useEffect(() => {
    setOpened([]);
    return;
  }, [multiple]);

  const open = useCallback(
    (id: number) => {
      if (!opened.includes(id)) {
        if (multiple) {
          return setOpened((current) => [...current, id]);
        }
        return setOpened([id]);
      }
    },
    [multiple, opened]
  );

  const closeAll = useCallback(() => setOpened([]), []);

  const close = useCallback(
    (id: number) => {
      if (opened.includes(id)) {
        if (multiple) {
          return setOpened((current) => current.filter((currentId) => currentId !== id));
        }
        return setOpened([]);
      }
    },
    [multiple, opened]
  );

  return <NavContext.Provider value={{ multiple, open, opened, close, closeAll }}>{children}</NavContext.Provider>;
};

const useNav = (id: number) => {
  const { opened, open, close, closeAll, ...rest } = useContext(NavContext);
  const isOpen = useMemo(() => opened.includes(id), [opened, id]);

  const toggleHandler = useCallback(
    (force?: boolean) => {
      const idToClose = force !== undefined ? !force : isOpen;
      if (idToClose) {
        return close(id);
      }
      return open(id);
    },
    [close, id, isOpen, open]
  );

  const mouseOverHandler = useCallback(() => {
    return open(id);
  }, [id, open]);

  if (!opened) {
    throw new Error('You need the NavProvider');
  }

  return {
    toggle: toggleHandler,
    mouseOver: mouseOverHandler,
    isOpen,
    open,
    closeAll,
    ...rest,
  };
};

export { NavProvider, useNav };
