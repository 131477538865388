import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { noTo_font } from '@src/styles/typography';
import {
  color_black,
  color_miami,
  color_white,
  color_light_grey,
  color_indigo
} from '@styles/colors';
import { TemplateProps } from './banner-needles.interface';

export const BannerContainer = styled('div')<TemplateProps>`
  background-image: url(${({ mobile_background_image }) => mobile_background_image});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${color_black};
  ${mq_min('md')} {
    background-image: url(${({ tablet_background_image }) => tablet_background_image});
  }
  ${mq_min('lg')} {
    background-image: url(${({ desktop_background_image }) => desktop_background_image});
  }
  ${mq_min('xl')} {
    background-image: url(${({ large_background_image }) => large_background_image});
  }
`

export const BannerInner = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: ${getStyleSizes(517)};
  max-width:  ${getStyleSizes(1280)};
  margin: 0 auto;
  padding: 0;
  flex-wrap: wrap;
  ${mq_min('lg')} {
    flex-wrap: nowrap;
    padding:  ${getStyleSizes([64,20,32])};
  }
`
export const SuperHeadlineStyled = styled('div')`
  color: ${color_white};
  font-family: ${noTo_font};
  font-style: normal;
  font-weight: 400;
  color: ${color_white};
  font-size: ${getStyleSizes(14)};
  line-height: ${getStyleSizes(22)};
`
export const SubHeadlineStyled = styled('div')`
  color: ${color_white};
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(16)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(24)};
`

export const BannerHeadlineContainer = styled('div')`
  color: ${color_white};
  font-size: ${getStyleSizes(32)};
  line-height: ${getStyleSizes(38.4)};
  font-style: normal;
  font-weight: 400;
  margin:  ${getStyleSizes([14, 0, 16])};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(36)};
    line-height: ${getStyleSizes(44)};
    margin:  ${getStyleSizes([14, 0, 29])};
  }
  ${mq_min('lg')} {
    margin:  ${getStyleSizes([6  , 0, 18])};
    max-width: ${getStyleSizes(540)};
  }

  p > span:nth-of-type(2){
    font-weight: 700;
  }
`

export const BannerContentContainer = styled('div')`
  color: ${color_white};
  font-size: ${getStyleSizes(16)};
  font-weight: 400;
  line-height: ${getStyleSizes(24)};
  margin-bottom: ${getStyleSizes(37)};
  margin-top: ${getStyleSizes(32)};
  font-family: ${noTo_font};
  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(38)};
  }
  ul,
  ol {
    padding-left: ${getStyleSizes(4)};
    li {
      display: flex;
      flex-basis: 100%;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: ${getStyleSizes(16)};
      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7.352 4.68953C7.2988 4.65 7.24307 4.61429 7.18533 4.58261L0.985552 0.614912L0.962739 0.598325L0.922379 0.574365C0.807993 0.512132 0.678219 0.488105 0.550466 0.505518C0.422712 0.522925 0.303079 0.580938 0.207606 0.671772C0.112132 0.762612 0.0453923 0.881931 0.016319 1.01373C-0.0127477 1.14554 -0.00274758 1.28352 0.0449724 1.40918L1.40145 5.9998L0.0449724 10.5885C-0.00420092 10.7181 -0.0133077 10.8607 0.0189257 10.9961C0.0511523 11.1315 0.123099 11.2528 0.224579 11.3429C0.326059 11.4329 0.451939 11.4872 0.584406 11.498C0.716872 11.5088 0.849226 11.4756 0.962739 11.4031L1.20139 11.2575L2.0595 10.7047L4.03367 9.44593L7.19233 7.4188C7.2466 7.38726 7.29933 7.3528 7.35027 7.3156C7.5508 7.16913 7.71467 6.97386 7.8278 6.74653C7.94093 6.5192 8 6.26647 8 6.00993C8 5.7534 7.94093 5.50066 7.8278 5.27333C7.71467 5.04593 7.5508 4.85073 7.35027 4.70427L7.352 4.68953Z' fill='%2325C9EF'/%3E%3C/svg%3E");
        margin-right: ${getStyleSizes(13)};
        margin-top: 0;
      }
      span{
        &:first-of-type{
          font-weight: 700;
          color: ${color_miami.base};
        }
      }
    }
  }
`
export const BannerLogoImageStyled = styled('img')`
  width: ${getStyleSizes(147)};
  height: ${getStyleSizes(40)};
`

export const BannerLeftSideContainer = styled('div')`
  width: 100%;
  align-self: center;
  max-width: 100%;
  margin: ${getStyleSizes([56,20,28])};
  ${mq_min('md')} {
    margin: ${getStyleSizes([64,19,-15])};
  }
  ${mq_min('lg')} {
    width: 45%;
    max-width: ${getStyleSizes(500)};
    margin: 0;
  }
`
export const BannerRightSideContainer = styled('div')`
  width: 100%;
  padding: ${getStyleSizes([0, 20, 32])};
  ${mq_min('md')} {
    padding: ${getStyleSizes([5, 31, 0, 27])};
    margin-bottom: ${getStyleSizes(-5)};
  }
  ${mq_min('lg')} {
    width: 55%;
    padding: ${getStyleSizes([15, 0, 23, 50])};
    margin-bottom: 0;
  }
`

export const BannerImageStyled = styled('img')` 
  width: 100%;
`

export const BannerSocialProofContainer = styled('div')`
  padding: ${getStyleSizes([56, 20])};
  background-color: ${color_black};
  ${mq_min('md')} {
    padding: ${getStyleSizes([64, 20])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes([60, 100])};
  }
`

export const BannerSocialProofInner = styled('div')`
  max-width: ${getStyleSizes(1240)};
  margin: auto;
`

export const CTASubtextContainer = styled('div')`
  *{
    color: ${color_white};
    font-family: ${noTo_font};
    font-size:  ${getStyleSizes(13)};
    font-style: normal;
    font-weight: 400;
    line-height: ${getStyleSizes(22)};
    margin-top: ${getStyleSizes(16)};
  }
  a{
    text-decoration-color:${color_white};
    text-decoration-line: underline;
  }
`

export const ButtonContainer = styled('div')<TemplateProps>`
  span{
    margin: 0!important;
  }
  svg{
    display: none !important;
  }
`