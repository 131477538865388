import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { color_blue, color_miami } from '@styles/colors';
import { mq_min } from '@styles/breakpoints';
import { HeroBannerContainerStyledProps } from '@cms-components/hero-banner/hero-banner.interface';
import { GridColProp } from '@cms-components/hero-banner/components/banner-standard/banner-standard.interface';

export const HeroBannerContainer = styled.div<HeroBannerContainerStyledProps>`
  ${mq_min('lg')} {
    ${({ heroType }) =>
      heroType === 'standard_banner' ? 'overflow: hidden;' : null};
  }
`;

export const HeroBannerWrapper = styled.div``;

export const HeroBannerTextWrapper = styled.div``;

export const HeroBannerButtonsWrapper = styled.div`
  margin: ${getStyleSizes([40, 0])};

  & > *:not(:last-child) {
    margin-bottom: ${getStyleSizes(22)};
  }
`;

export const HeroBannerHeadline = styled.h1`
  line-height: 1;
  font-size: ${getStyleSizes(34)};
  font-weight: normal;

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
  }
`;

export const HeroBannerEyebrow = styled.div<GridColProp>`
  color: ${color_blue};
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  line-height: 0.75;
  margin-bottom: ${getStyleSizes(4)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
    margin-bottom: ${getStyleSizes(11)};
  }
`;

export const HeroBannerCopy = styled.div<GridColProp>`
  ul,
  ol {
    display: flex;
    flex-direction: row;
    text-align: initial;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: ${getStyleSizes(16)};
  }

  ul,
  ol {
    li {
      display: flex;
      flex-basis: 100%;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      &:before {
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='${encodeURIComponent(
          color_miami.base
        )}' d='M3 12L6 9L10 13L19 4L22 7L10 19L3 12Z'/%3e%3c/svg%3e");
        flex: 0 0 ${getStyleSizes(20)};
        margin-right: ${getStyleSizes(5)};
        margin-top: ${getStyleSizes(2)};
      }
      ${mq_min('lg')} {
        flex-basis: 48%;
        width: 48%;
      }
    }
  }
`;

export const HeroBannerLogo = styled.div`
  width: ${getStyleSizes(200)};
  height: ${getStyleSizes(35)};
  margin-bottom: ${getStyleSizes(25)};

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(32)};
  }
`;

export const HeroBannerLogoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left center;
`;

export const HeroBannerButton = styled.div``;

export const HeroBannerButtonSecondary = styled.div``;

export const HeroBannerPicture = styled.picture`
  display: block;
`;

export const HeroBannerImg = styled.img`
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
`;
