import { ChildrenType } from '../interfaces/rich-text.resolver';
import { IconFontNameProps } from '@shared/interfaces/general.interfaces';

export const processTextForColors = (text: string) => {
  const processOpeners = text
    .replaceAll(/{{/g, `<span class="color-secondary">`)
    .replaceAll(/{/g, '<span class="color-primary">');
  return processOpeners.replaceAll(/}}|}/g, '</span>');
};

export const hasContent = (childrenList: ChildrenType[]): boolean => {
  return (
    childrenList?.filter((item) => {
      if (item.hasOwnProperty('text')) {
        return item.text?.length;
      }
      if (item.type === 'reference') {
        return 1;
      }

      if (item.children) {
        return hasContent(item.children);
      }
      return false;
    }).length > 0
  );
};

/** Determine what color brackets are in the string
 * @summary the primary use for this is to determine whether a substring has color brackets.
 * @example the promo card component needs to programmatically add a font icon arrow to the end of the string.
 * this will break if there is already xml in the string. This function lets the 'addIconToEndOfSting' function know
 * where the brackets are so that it can add in the color styling and not break nesting or wrap the icon inadvertently.
 * it is also possible that the substring may only need to open or close the brackets.
 *
 * @param string
 */

export const colorBracketPosition = (
  string: string
): { type: string | null; open: string | boolean; close: string | boolean } => {
  const type = string.includes('{{')
    ? 'color-secondary'
    : string.includes('{')
    ? 'color-primary'
    : null;
  const open = !!type;
  const close = string.includes('}}') || string.includes('}');
  return { type, open, close };
};

/** Add icon to string end
 * @summary in order to ensure the icon doesn't drop to its own line
 * we're wrapping it with the last word in the string.
 * @param text
 * @param icon - the ligature name of the icon font
 * @class last-word-icon class is in base-layout.styles.ts
 * @class .icon- is in the global.css file
 *
 * @return string with xml. must use: dangerouslySetInnerHTML={{__html: [string]}}
 */

export const addIconToEndOfSting = ({
  text,
  icon,
}: {
  text: string | null;
  icon?: IconFontNameProps;
}): string | null => {
  if (!text) {
    return null;
  }
  const iconToAdd = !!icon ? `<i class="icon-">${icon}</i>` : '';
  return text
    .split(' ')
    .map((word: string, index: number, array: string[]) => {
      const { type, open, close } = colorBracketPosition(word);
      const stripBrackets = word.replace(/{/g, '').replace(/}/g, '');

      const startColor = open ? `<span class="${type}">` : '';
      const endColor = close ? `</span>` : '';

      return index === array.length - 1
        ? `<span class="last-word-icon">${startColor}${stripBrackets}${endColor}${iconToAdd}</span>`
        : `${startColor}${stripBrackets}${endColor}`;
    })
    .join(' ');
};

export const processTextForBoldStyle = (text: string) => {
  const processOpeners = text.replaceAll(/<</g, `<span class="bold">`);
  return processOpeners.replaceAll(/>>|}/g, '</span>');
};

export const processTextForCleanStyle = (text: string) => {
  const processOpeners = text.replaceAll(/<</g, ``);
  return processOpeners.replaceAll(/>>|}/g, '');
};

export const processSimpleTextToBaseIdFormat = (text: string) => {
  return text.replaceAll(' ', '-').toLowerCase();
};
