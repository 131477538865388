import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { ActiveImageProps } from './active-image.interface';

const activeImageDefault: ActiveImageProps = {
  activeImage: 0,
  setActiveImage: () => {},
};

export const ActiveImageContext = createContext(activeImageDefault);

const ActiveImageProvider = ({ children }: PropsWithChildren<any>) => {
  const [activeImage, setActiveImage] = useState<number>(0);
  const value = useMemo(() => ({ activeImage, setActiveImage }), [activeImage, setActiveImage]);

  return <ActiveImageContext.Provider value={value}>{children}</ActiveImageContext.Provider>;
};

const useActiveImage = () => {
  const { activeImage, setActiveImage } = useContext(ActiveImageContext);

  if (typeof activeImage !== 'number') {
    throw new Error('You need the ActiveImageProvider');
  }

  return {
    activeImage,
    setActiveImage,
  };
};

export { ActiveImageProvider, useActiveImage };
