import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { RichText, TextColor } from '@src/shared/components';

import {
  ListPanelContainer,
  ListPanelInner,
  ListPanelDetail,
  ListPanelContent,
  Eyebrow,
  Headline,
  Description,
} from './list-panel.styles';
import { ListPanelProps } from './list-panel.interface';

import { ListContent } from './components/list-content';
import { FaqContent } from './components/faq-content';

import { CSListPanel } from '@shared/contentstack';
import { listPanelMapper } from '@cms-components/list-panel/mappers/list-panel.mapper';
import { default_breakpoints } from '@src/styles/breakpoints';

export const ListPanel = ({
  variant = 'light',
  headline,
  eyebrow,
  copy,
  listType,
  paragraphContent,
  bulletsContent,
  faqContent,
  uid
}: ListPanelProps) => {
  return (
    <ListPanelContainer id={uid || copy?.uid} variant={variant} data-testid="lis_panel_container">
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <ListPanelInner>
          <ListPanelDetail listType={listType}>
            {eyebrow && <Eyebrow variant={variant}>{eyebrow}</Eyebrow>}
            <Headline variant={variant}>
              <TextColor>{headline}</TextColor>
            </Headline>
            <Description variant={variant}>
              <RichText content={copy} />
            </Description>
          </ListPanelDetail>
          <ListPanelContent listType={listType}>
            {listType === 'paragraph' && paragraphContent && (
              <ListContent
                variant={variant}
                listContentData={paragraphContent}
                listType={listType}
              />
            )}
            {listType === 'bullet' && bulletsContent && (
              <ListContent
                variant={variant}
                listContentData={bulletsContent}
                listType={listType}
              />
            )}

            {listType === 'faq' && faqContent && (
              <FaqContent variant={variant} faqContentData={faqContent} />
            )}
          </ListPanelContent>
        </ListPanelInner>
      </CMSComponentWrapper>
    </ListPanelContainer>
  );
};

export const MappedListPanel = (data: CSListPanel) => {
  const mapper: ListPanelProps = listPanelMapper(data);
  return <ListPanel {...mapper} />;
};
