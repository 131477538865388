import { global_colors } from './colors';

export const border_defaults: any = {
  width: 1,
  style: 'solid',
  radius: 4,
  color: global_colors.border,
};

export const shadow_defaults: any = {
  size: [0, 4, 30],
  color: global_colors.border.dark,
};

export const spacing_defaults: any = {
  margin: 10,
  padding: 10,
};
