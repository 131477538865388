import React, { useEffect } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import { usePageHeaderStyle } from '@shared/context/pageHeaderStyle.context';
import BasicLink from '@shared/components/basic-link';

import { NavMenuItem } from '@cms-components/header/components/nav-menu-item/nav-menu-item';
import {
  NavProps,
  RenderMenuItemsProps,
} from '@cms-components/header/components/nav/nav.interface';

import { NavProvider } from '../../context/nav.context';
import * as Styled from './nav.styles';
import { useMainNav } from '../../context/main-nav.context';
import {
  CSMenuDropDown,
  MainNavDataProps,
  MenuSection,
} from '@cms-components/header/components/main-nav/main-nav.interface';

const renderMenuItems = (allMenuSections: MenuSection[]) => {
  return allMenuSections?.map(({ menu_dropdown, menu_name }, index: number) => {
    return (
      <NavMenuItem
        key={index}
        id={index}
        menuSection={menu_name}
        menuDropDown={menu_dropdown[0]}
      />
    );
  });
};

export const Nav = ({ allMenuSections, headerData }: MainNavDataProps) => {
  const { navState, openNav, closeNav } = useMainNav();
  const { currentColor } = usePageHeaderStyle();
  const isDesktop = useBreakpoint(up('lg'));

  const { sign_in_link } = headerData || {};

  const {
    link_text: signInText,
    link_url: signInUrl,
    external_link: signInIsExternal,
  } = sign_in_link;

  useEffect(() => {
    if (isDesktop) {
      return openNav();
    }
    return closeNav();
  }, [isDesktop, openNav, closeNav]);

  return (
    <NavProvider multiple={!isDesktop}>
      <Styled.Nav state={navState} color={currentColor}>
        <Styled.NavList>
          {allMenuSections && renderMenuItems(allMenuSections)}

          <li>
            <Styled.NavIconLink>
              <Styled.PersonIconStyled />
              <BasicLink
                text={signInText}
                url={signInUrl}
                isExternal={signInIsExternal}
              />
            </Styled.NavIconLink>
          </li>
        </Styled.NavList>
      </Styled.Nav>
    </NavProvider>
  );
};
