import React from 'react';
import 'swiper/css';
import { default_breakpoints } from '@src/styles/breakpoints';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import {
  Container,
  Header,
  Title,
  SeeAllLink,
  Inner,
  ArticleWrapper,
} from './lc-filtered-article-list.styles';
import { LCFilteredArticleListProps } from './lc-filtered-article-list.interface';

import BasicLink from '@src/shared/components/basic-link';
import { LCArticleTile } from '../lc-article-tile';

export const LCFilteredArticleList = ({
  variant = 'light',
  title,
  articleList,
  numberOfResults,
  navigationName,
  seeAllLink,
  isSuppressImages,
  fallbackImage
}: LCFilteredArticleListProps) => {
  if (!articleList.length) return <></>;

  return (
    <Container
      variant={variant}
      data-testid="lc-filtered-article-list__container"
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <Header>
          <Title>{title}</Title>
          {numberOfResults > articleList.length && (
            <SeeAllLink>
              <BasicLink
                url={seeAllLink}
                text={`See ${numberOfResults} ${navigationName} Results`}
              />
            </SeeAllLink>
          )}
        </Header>
        <Inner>
          {articleList.map(
            (
              { image, topics, headline, headlineLink, teaser, logoCard },
              index
            ) => (
              <ArticleWrapper key={index}>
                <LCArticleTile
                  image={image}
                  topics={topics}
                  headline={headline}
                  headlineLink={headlineLink}
                  teaser={teaser}
                  logoCard={logoCard}
                />
              </ArticleWrapper>
            )
          )}
        </Inner>
      </CMSComponentWrapper>
    </Container>
  );
};
