import React, { useState } from 'react';
import { RichText, TextColor } from '@shared/components';
import BasicLink from '@shared/components/basic-link';
import { CheckIcon } from '@shared/components';
import { color_miami } from '@styles/colors';

import {
  ListContentContainer,
  ListContentItemContainer,
  Headline,
  ListContentItem,
  Copy,
  SeeAll,
  Bullet,
  DetailContainer,
  LinkContainer,
} from './list-content.styles';
import { ListContentProps } from './list-content.interface';

export const ListContent = ({
  variant,
  listType,
  listContentData,
}: ListContentProps) => {
  const [showAllItems, setShowAllItems] = useState<boolean>(false);
  const itemsToShowMobile = 3;

  return (
    <ListContentContainer>
      <ListContentItemContainer>
        {listContentData?.map(({ headline, copy, link }, index) => {
          return (
            <ListContentItem
              key={index}
              listType={listType}
              itemsToShowMobile={itemsToShowMobile}
              showAllItems={showAllItems}
            >
              {listType === 'bullet' && (
                <Bullet>
                  <CheckIcon />
                </Bullet>
              )}
              <DetailContainer>
                <Headline variant={variant}>
                  <TextColor>{headline}</TextColor>
                </Headline>
                {(copy) && (<Copy variant={variant}>
                  <RichText content={copy} />
                </Copy>)}
                {(link && link.text) && (
                  <LinkContainer variant={variant}>
                    <BasicLink
                      underline
                      text={link.text}
                      url={link.url}
                      isExternal={link.isExternal}
                    />
                  </LinkContainer>
                )}
              </DetailContainer>
            </ListContentItem>
          );
        })}
      </ListContentItemContainer>
      {!showAllItems && listContentData.length > itemsToShowMobile && (
        <div>
          <SeeAll variant={variant} onClick={() => setShowAllItems(true)}>
            See All
          </SeeAll>
        </div>
      )}
    </ListContentContainer>
  );
};
