import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import { color_darkest_grey, color_lightest_grey, color_white } from '@styles/colors';

import { BackgroundProp, ImageScrollingProp } from './content-panel.interface';

export const themes = {
  light: {
    containerPanelBackground: color_white,
    headlineColor: color_darkest_grey,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
  },
  dark: {
    containerPanelBackground: color_darkest_grey,
    headlineColor: color_white,
  },
};

export const ContentPanelContainer = styled.div<BackgroundProp & ImageScrollingProp>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ background }) => themes[background || 'light']?.containerPanelBackground};

  ${mq_min('lg')} {
    ${({ imageScrolling }) =>
      imageScrolling &&
      `
        padding: ${getStyleSizes([100, 0])};
    `}
  }
`;

export const Headline = styled.h3<BackgroundProp & ImageScrollingProp>`
  font-family: Causten;
  font-size: ${getStyleSizes(34)};
  font-weight: normal;
  text-align: center;
  color: ${({ background }) => themes[background]?.headlineColor};
  margin-bottom: ${({ imageScrolling }) => getStyleSizes(imageScrolling ? 100 : 52)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
  }
`;

export const ContentPanelInner = styled.div<ImageScrollingProp>`
  ${mq_min('lg')} {
    ${({ imageScrolling }) =>
      imageScrolling &&
      `
        display: flex;
        flex-direction: row;
    `}
  }
`;
