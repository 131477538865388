import React from 'react';
import { CustomerTestimonialProps } from './testimonial-slider.interface';
import { StandardSlider, NeedlesSlider, TrialworksSlider } from './slider-layouts';

// Styles
import {
  TestimonialStyled
} from './testimonial-slider.styles';

// Default theme
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

export const TestimonialSlider = ({
  itemList,
  slider_layout
}: CustomerTestimonialProps) => {
  if (itemList.length) {

    const AccordionTypeComponent = () =>{
      const props = {
        itemList,
        slider_layout
      }
  
      switch(slider_layout){
        case 'Needles':
          return <NeedlesSlider {...props} />;
        case 'Trialworks':
          return <TrialworksSlider {...props} />;
        default: 
          return <StandardSlider {...props}/>;
      }
    }

    return (
      <TestimonialStyled data-testid="testimonial__wrapper"> 
        {AccordionTypeComponent()}
      </TestimonialStyled>
    );
  }

  return <></>;
};
