import React, { useState, useRef, MutableRefObject, useEffect, useCallback } from 'react';
import { ContactFormProps } from '../form-panel-baseline.interface';
import useMarketo from '@shared/hooks/useMarketo';
import { isBrowser, isElement } from '@shared/utilities';
import { CONTACT_FORM_CONSTANTS } from '../form-panel.constants';
import { 
  ContactFormContainer,
  FormTitleStyled } from './contact-form.styles';

interface FieldData {
  name: string;
  label: string
}

export const ContactLongFormBaseline = ({
  base_url,
  tracking_code,
  form_id,
  title = CONTACT_FORM_CONSTANTS.TITLE,
  success_message = CONTACT_FORM_CONSTANTS.SUCCESS_MESSAGE,
}: ContactFormProps) => {
  const formRef: MutableRefObject<any> = useRef();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const isValidFormConfig: boolean = !!base_url && !!form_id && !!tracking_code;
  const form_element_id = `${CONTACT_FORM_CONSTANTS.FORM_BASE_ID}_${form_id}`;

  const initForm = useCallback((form: any): void => {
    if (formRef.current) {
      const currentForm = formRef.current;
      const fieldData = [
        { name: 'FirstName', label: 'First Name'},
        { name: 'LastName', label: 'Last Name'},
        { name: 'Number_of_Users__c', label: 'Number of Users'}
      ]
      fieldMapper(fieldData);

      // Replace number field with custom buttons
      if(currentForm.querySelector('#Number_of_Users__c')){
        currentForm.querySelector('#Number_of_Users__c').insertAdjacentHTML("afterend",`
          <div class="arrow-up">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M1 5L5 1L9 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="arrow-down">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M9 1L5 5L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </div>`);
        const minValue = 1;
        const maxValue = 1000;
        const inputField = currentForm.querySelector('#Number_of_Users__c');
        const arrowUp = currentForm.querySelector('.arrow-up');
        const arrowDown = currentForm.querySelector('.arrow-down');

        let debounceTimeout: any;
        let isHandlingChange = false;

        const debounceChangeEvent = (element: any) => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
          }
          debounceTimeout = setTimeout(() => {
            triggerChangeEvent(element);
          }, 100); // Adjust the debounce delay as needed
        };

        const triggerChangeEvent = (element: any) => {
          if (!isHandlingChange) {
            isHandlingChange = true;
            const event = new Event('change', {
                bubbles: true,
                cancelable: true
            });
            element.dispatchEvent(event);
            isHandlingChange = false;
          }
        };
        arrowUp.addEventListener('click', (event:any) => {
          event.preventDefault();
          if(inputField.value === ''){
            inputField.value = 0;
            inputField.parentElement.classList.add('filled');
          }
          let currentValue = parseInt(inputField.value, 10);
          if (currentValue < maxValue) {
            inputField.value = currentValue + 1;
          }
          debounceChangeEvent(inputField);
        });
        arrowDown.addEventListener('click', (event:any) => {
          event.preventDefault();
          if(inputField.value === ''){
            inputField.value = minValue;
            inputField.parentElement.classList.add('filled');
          } 
          let currentValue = parseInt(inputField.value, 10);
          if (currentValue > minValue) {
            inputField.value = currentValue - 1;
          }
          debounceChangeEvent(inputField);
        });
      }

      // Submit Button text
      if(currentForm.querySelector('button.mktoButton')){
        currentForm.querySelector('button.mktoButton').innerHTML=`
        <span>Submit</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
        <path d="M7.352 4.68953C7.2988 4.65 7.24307 4.61429 7.18533 4.58261L0.985552 0.614912L0.962739 0.598325L0.922379 0.574365C0.807993 0.512132 0.678219 0.488105 0.550466 0.505518C0.422712 0.522925 0.303079 0.580938 0.207606 0.671771C0.112132 0.762612 0.0453923 0.881931 0.016319 1.01373C-0.0127477 1.14554 -0.00274758 1.28352 0.0449724 1.40918L1.40145 5.9998L0.0449724 10.5885C-0.00420091 10.7181 -0.0133077 10.8607 0.0189257 10.9961C0.0511523 11.1315 0.123099 11.2528 0.224579 11.3429C0.326059 11.4329 0.451939 11.4872 0.584406 11.498C0.716872 11.5088 0.849226 11.4756 0.962739 11.4031L1.20139 11.2575L2.0595 10.7047L4.03367 9.44593L7.19233 7.4188C7.2466 7.38726 7.29933 7.3528 7.35027 7.3156C7.5508 7.16913 7.71467 6.97386 7.8278 6.74653C7.94093 6.5192 8 6.26646 8 6.00993C8 5.7534 7.94093 5.50066 7.8278 5.27333C7.71467 5.04593 7.5508 4.85073 7.35027 4.70426L7.352 4.68953Z" fill="#1A1A1A"></path>
        </svg>`;
      }

      form.onSuccess(onSuccess);
    }
  }, []);

  const fieldMapper = (fields: FieldData[]) => {
    fields.forEach(field => {
      const fieldElement = formRef.current.querySelector(`#${field.name}`);
      if(fieldElement === null) return;
      
      if(!fieldElement.closest('.mktoFormRow').classList.contains(`field-${field.name}`)){
        //Field Wrapper names
        fieldElement.closest('.mktoFormRow').classList.add(`field-${field.name}`);

        // Replace labels
        const labelElement = formRef.current.querySelector(`#Lbl${field.name}`);
        if (labelElement) {
          labelElement.textContent = field.label;
        }

        // Define event handlers
        const handleFocus = (event: any) => {
          event.target.closest('.mktoFieldWrap').classList.add('typing');
        };

        const handleBlur = (event: any) => {
          event.target.closest('.mktoFieldWrap').classList.remove('typing');
          if (event.target.value === '') {
            event.target.closest('.mktoFieldWrap').classList.remove('filled');
          } else {
            event.target.closest('.mktoFieldWrap').classList.add('filled');
          }
        };

        // Remove existing event listeners
        manageEventListeners(fieldElement, 'focus', handleFocus, 'remove');
        manageEventListeners(fieldElement, 'blur', handleBlur, 'remove');

        // Add new event listeners
        manageEventListeners(fieldElement, 'focus', handleFocus, 'add');
        manageEventListeners(fieldElement, 'blur', handleBlur, 'add');
      }
    });
  }

  const manageEventListeners = (element: any, eventType: string, handler: any, action: string) => {
    if (element) {
      if (action === 'add') {
        element.addEventListener(eventType, handler);
      } else if (action === 'remove') {
        element.removeEventListener(eventType, handler);
      }
    }
  };

  const watchFormState = useCallback((mutationsList: any) => {
    for (var mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        let mktoFieldWraps = document.querySelectorAll('.mktoFieldWrap');
        mktoFieldWraps.forEach(fieldWrap => {
          let mktoErrorElemet = fieldWrap.querySelector('.mktoError');
          let isVisible = mktoErrorElemet ? getComputedStyle(mktoErrorElemet).display !== 'none' : false;
          if(mktoErrorElemet && isVisible){
            fieldWrap.classList.add('error');
          }else{
            fieldWrap.classList.remove('error');
          }
        })
      }else if(mutation.type === 'childList'){
        let isCompanyExists = !!document.querySelector('#Company');
        if(isCompanyExists){
          // Change field position
          if(formRef.current.querySelector('.field-Company + .field-Email')){
            formRef.current.querySelector('#Company')?.closest('.mktoFormRow').before(formRef.current.querySelector('#Email').closest('.mktoFormRow'));
          }
          // Remove select first option text
          const stateField = formRef.current.querySelector('#State');
          if (stateField && stateField.options.length > 0) {
            stateField.options[0].textContent = '';
          }
          const currentCustomerField = formRef.current.querySelector('#Lead_Type__c');
          if (currentCustomerField && currentCustomerField.options.length > 0) {
            currentCustomerField.options[0].textContent = '';
          }
          const watchFields = [
            { name: 'Company', label: 'Firm Name'},
            { name: 'Email', label: 'Work Email'},
            { name: 'Phone', label: 'Phone Number'},
            { name: 'State', label: 'State'},
            { name: 'Lead_Type__c', label: 'Current Customer?'}
          ];
          fieldMapper(watchFields);
        }
      }
    }
  }, []);

  const useMutationObserver = (
    ref: any,
    callback: any,
    options = {
      subtree: true, 
      childList: true, 
      attributes: true
    }
  ) => {
    useEffect(() => {
      if (ref) {
        const observer = new MutationObserver(callback);
        observer.observe(ref, options);
        return () => observer.disconnect();
      }
    }, [ ref ]);
  };

  useMutationObserver(formRef.current, watchFormState);


  // If needed in the future, the Marketo success callback includes:
  // - submitted form values
  // - redirect url
  const onSuccess = () => {
    setFormSubmitted(true);
    return false;
  };

  if (isBrowser()) {
    useMarketo({
      baseUrl: base_url,
      munchkinId: tracking_code,
      formId: form_id,
      callback: () => {},
      onLoadCallback: (form: any) => initForm(form),
      exists:
        formRef.current &&
        isElement(formRef.current) &&
        formRef.current.childNodes.length > 0,
    });
  }
  return (
    <ContactFormContainer>
      <FormTitleStyled>{title}</FormTitleStyled>
      {formSubmitted && <div>{success_message}</div>}
      {isValidFormConfig && !formSubmitted && (
        <form ref={formRef} id={form_element_id} />
      )}
    </ContactFormContainer>
  );
};
