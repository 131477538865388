import styled from '@emotion/styled';

import * as Styled from '@cms-components/hero-banner/hero-banner.styles';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import {
  BannerStandardButtonsWrapperProps,
  BannerStandardImageContainerProps,
  BannerStandardStyledProps,
  GridColProp,
} from '@cms-components/hero-banner/components/banner-standard/banner-standard.interface';

export const BannerStandard = styled.div<BannerStandardStyledProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto;
  padding: ${getStyleSizes([32, 0])};
  color: ${({ color }) => color};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([85, 0])};
    ${({ noImage }) => {
      return noImage
        ? `text-align: center;`
        : `display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: ${getStyleSizes(80)};`;
    }}
  }

  h1,
  h2 {
    line-height: 1;
    font-size: ${getStyleSizes(34)};
    font-weight: normal;
    margin-bottom: ${getStyleSizes(25)};
  }

  h1 {
    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
    }
  }
`;

export const BannerStandardEyebrow = styled(Styled.HeroBannerEyebrow)`
  margin-bottom: ${getStyleSizes(5)};
  ${mq_min('lg')} {
    ${({ gridCol }) => (gridCol ? `grid-column: ${gridCol}` : null)}
  }
`;

export const BannerStandardLogo = styled.div<GridColProp>`
  margin-bottom: ${getStyleSizes(30)};
  & img {
    height: auto;
    width: 90px;
  }

  ${mq_min('lg')} {
    ${({ gridCol }) => (gridCol ? `grid-column: ${gridCol}` : null)}
  }
`;

export const SimpleHeaderWrapper = styled.div<GridColProp>`
  color: ${({ color }) => color};
  ${mq_min('lg')} {
    ${({ gridCol }) => (gridCol ? `grid-column: ${gridCol}` : null)}
  }
`;

export const BannerStandardCopy = styled(Styled.HeroBannerCopy)`
  margin-bottom: ${getStyleSizes(25)};
  ${mq_min('lg')} {
    ${({ gridCol }) => (gridCol ? `grid-column: ${gridCol}` : null)}
  }
`;

export const BannerStandardButtonsWrapper = styled.div<BannerStandardButtonsWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-end: -1;
  & > * {
    margin-bottom: ${getStyleSizes(20)};
  }

  ${mq_min('lg')} {
    flex-direction: row;
    align-items: baseline;
    justify-content: ${({ noImage }) => (noImage ? 'center' : 'flex-start')};
    grid-row-end: auto;
    & > * {
      margin-bottom: 0;
    }
    ${({ gridCol }) => (gridCol ? `grid-column: ${gridCol}` : null)}
  }
`;

export const BannerStandardButton = styled(Styled.HeroBannerButton)``;

export const BannerStandardButtonSecondary = styled(
  Styled.HeroBannerButtonSecondary
)`
  ${mq_min('lg')} {
    margin-left: ${getStyleSizes(25)};
  }
`;

export const BannerStandardImageContainer = styled.div<BannerStandardImageContainerProps>`
  margin: 0 5% 8%;

  ${mq_min('md')} {
    margin: 0 10% 8%;
  }

  ${mq_min('lg')} {
    margin: 0;
    grid-column: ${({ gridCol }) => (gridCol ? gridCol : null)};
    grid-row: 1 / span 5;
    align-self: center;
    position: relative;
  }

  .gatsby-image-wrapper {
    transform: translateX(0);
    transition: transform 500ms;
  }

  ${({ media, gridCol }) => {
    if (media === 'right') {
      return `.gatsby-image-wrapper {
        ${mq_min('lg')} {
          transform: translateX(${gridCol && gridCol === 1 ? '-25%' : '25%'});
        }
        ${mq_min('cw')} {
          transform: translateX(0);
        }
      }`;
    }
  }}
`;

export const WrapperImage = styled.div`
  position: relative;
`;
