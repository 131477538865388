import React from 'react';
import { TextColor } from '@shared/components';

export const SimpleHeader = ({ text, isH1 }: { text: string; isH1: boolean }) =>
  isH1 ? (
    <h1>
      <TextColor>{text}</TextColor>
    </h1>
  ) : (
    <h2>
      <TextColor>{text}</TextColor>
    </h2>
  );
