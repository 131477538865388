import { useLocation } from '@reach/router';

import { LCTopicsProps } from '../components/learning-center/lc-menu/lc-menu.interface';
import { parse } from 'query-string';

const QUERY = 'f';

export const encodeURItopic = (name: string) => {
  return encodeURIComponent(name)
    .replaceAll('%26', 'and')
    .replaceAll('%20', '+');
};

export const decodeURItopic = (name: string) => {
  return decodeURIComponent(name).replaceAll('and', '&');
};

export const getTopicsFromURL = () => {
  const location = useLocation();
  const query = parse(location.search)[QUERY];
  let topicsURL: any = [];

  if (typeof query === 'string') {
    topicsURL = [decodeURItopic(query)];
  }
  if (Array.isArray(query)) {
    topicsURL = query?.map((item: any) => decodeURItopic(item));
  }

  return topicsURL;
};

export const updateTopicsURL = (topics: LCTopicsProps[] = []) => {
  if (!!history.pushState) {
    const url =
      topics
        .map((topic, i) => (i === 0 ? '?' : '&') + `${QUERY}=${topic.url}`)
        .join('') || '';

    var newUrl = window.location.origin + window.location.pathname + url;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};
