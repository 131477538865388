import styled from '@emotion/styled';

import { ArrowIcon, IconLink } from '@shared/components';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import {
  color_darkest_grey,
  color_lighter_grey,
  color_lightest_grey,
  color_white,
  global_colors,
} from '@styles/colors';

import { BoxedLinksSectionStyledProps } from './boxed-links.interface';

export const BoxedLinksSectionStyled = styled.div<BoxedLinksSectionStyledProps>`
  background: ${({ type }) => (type === 'solid' ? `${color_white}` : 'transparent')};
  border-radius: ${getStyleSizes(8)};
  padding: ${getStyleSizes([15, 22])};
  margin-top: ${getStyleSizes(25)};
  ${({ type }) => (type === 'outline' ? `border: 1px solid ${color_lighter_grey};` : null)};

  & > *:not(:last-child) {
    margin-bottom: ${getStyleSizes(10)};
  }

  ${mq_min('lg')} {
    background: ${({ type }) => (type === 'solid' ? `${color_lightest_grey}` : 'transparent')};
  }
`;

export const IconLinkStyled = styled(IconLink)`
  font-size: ${getStyleSizes(14)};
  color: ${color_darkest_grey};
`;

export const ArrowIconStyled = styled(ArrowIcon)`
  color: ${global_colors.link.default};
  font-size: ${getStyleSizes(12)};
`;
