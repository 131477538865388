import { ImageComponent, RichText } from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { default_breakpoints } from '@styles/breakpoints';
import React, { useEffect, useState } from 'react';
import { QuotePromoProps } from './quote-promo.interface';
import {
  Attribution,
  DetailContainer,
  Firm,
  ImageContainer,
  Quote,
  QuotePromoContainer,
  QuotePromoInner,
  WrapperFullwidth,
} from './quote-promo.styles';

export const QuotePromo = ({
  backgroundColor = 'light',
  variant,
  quote,
  attribution,
  firm,
  logoImage,
  backgroundImage,
  maxWidth,
  uid
}: QuotePromoProps) => {
  const [hasImage, setHasImage] = useState(false);

  useEffect(() => {
    setHasImage(!!backgroundImage || !!logoImage);
  }, [backgroundImage, logoImage]);

  backgroundColor = variant === 'full-width' ? 'dark' : backgroundColor;
  return (
    <QuotePromoContainer
      background={backgroundColor}
      variant={variant}
      data-testid="quote-promo__container"
      id={uid || quote?.uid}
    >
      <CMSComponentWrapper maxWidth={maxWidth || default_breakpoints.cw}>
        <QuotePromoInner variant={variant} background={backgroundColor}>
          <DetailContainer variant={variant}>
            <Quote background={backgroundColor}>
              <RichText content={quote} />
            </Quote>
            <Attribution background={backgroundColor}>
              {attribution}
            </Attribution>
            <Firm background={backgroundColor}>{firm}</Firm>
          </DetailContainer>
          {hasImage && (
            <ImageContainer variant={variant}>
              {variant === 'boxed' && logoImage?.image && (
                <ImageComponent
                  image={logoImage.image.gatsbyImageData}
                  alt_text={logoImage.image_alt_text}
                />
              )}

              {variant === 'full-width' && backgroundImage?.image && (
                <WrapperFullwidth>
                  <ImageComponent
                    image={backgroundImage.image.gatsbyImageData}
                    alt_text={backgroundImage.image_alt_text}
                  />
                </WrapperFullwidth>
              )}
            </ImageContainer>
          )}
        </QuotePromoInner>
      </CMSComponentWrapper>
    </QuotePromoContainer>
  );
};

export default QuotePromo;
