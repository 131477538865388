import React from 'react';
import { ImageComponent } from '@src/shared/components';
import CMSComponentWrapper from '@src/shared/components/component-wrapper';
import { background_color_mapper } from '@src/styles/colors';
import { FaqContent } from '../list-panel/components/faq-content';

import { ImageFaqPanelProps } from './image-faq-panel.interface';
import {
  ContainerImageFaqPanel,
  PanelAccordeon,
  WrapperImage,
} from './image-faq-panel.styles';

export const ImageFaqPanel = ({
  background_color,
  image_faq_panel,
}: ImageFaqPanelProps) => {
  const backgroundColor = background_color_mapper[background_color || 'dark'];

  return image_faq_panel?.map((fp) => {
    return (
      <CMSComponentWrapper
        uid={fp.uid}
        backgroundColor={backgroundColor}
        maxWidth={1240}
      >
        <ContainerImageFaqPanel variantColor={background_color}>
          <PanelAccordeon>
            {fp.panel_image && (
              <WrapperImage>
                <ImageComponent
                  image={fp.panel_image.gatsbyImageData}
                  fallback={fp.panel_image.url}
                  alt_text={fp.panel_image.alt_text}
                />
              </WrapperImage>
            )}
            <FaqContent
              variant={background_color}
              faqContentData={fp.accordion?.map((_item) => ({
                items: _item.accordion_items,
                title: _item.accordion_title,
                additionalInfo: {
                  title: _item.additional_information_title,
                  content: _item.additional_information_content,
                },
              }))}
            />
          </PanelAccordeon>
        </ContainerImageFaqPanel>
      </CMSComponentWrapper>
    );
  });
};

export const CMSImageFaqPanelWrapper = (data: any) => {
  // Handle Live Preview, etc here...
  return <ImageFaqPanel {...data} />;
};
