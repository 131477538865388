import React from 'react';
import {
  RichText,
} from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import {
  CSHeroBannerSPZProps,
} from '../../hero-banner.interface';
import { 
  BannerHeadlineContainer,
  BannerContainer,
  BannerInner,
  BannerLogoImageStyled,
  BannerLeftSideContainer,
  BannerRightSideContainer,
  BannerImageStyled,
  BannerContentContainer,
  SuperHeadlineStyled,
  SubHeadlineStyled,
  BannerSocialProofContainer,
  BannerSocialProofInner,
  CTASubtextContainer,
  ButtonContainer
} from './banner-needles.styles';
 import { PrimaryButton } from '@shared/components';
 import { default_breakpoints } from '@src/styles/breakpoints';

export const BannerNeedles = ({
  variant = 'light',
  template = '',
  logo,
  superheadline,
  headline,
  subheadline,
  content,
  button_link,
  banner_image,
  cta_subtext,
  tablet_banner_image,
  mobile_banner_image,
  large_background_image,
  desktop_background_image,
  tablet_background_image,
  mobile_background_image,
  desktop_social_proof,
  tablet_social_proof_image,
  mobile_social_proof_image
}: CSHeroBannerSPZProps) => {

  return (
    <>
    <BannerContainer
      data-testid={`banner-${template}__container`}
      large_background_image={large_background_image?.image?.url}
      desktop_background_image={desktop_background_image?.image?.url}
      tablet_background_image={tablet_background_image?.image?.url}
      mobile_background_image={mobile_background_image?.image?.url}
    >
      <CMSComponentWrapper 
        horizontalPadding={0}
      >
        <BannerInner>
          <BannerLeftSideContainer>
            {logo?.image?.url && (
              <BannerLogoImageStyled
                src={logo?.image.url}
                alt={logo?.image_alt_text}
              />
            )}
            {superheadline && <SuperHeadlineStyled>{superheadline}</SuperHeadlineStyled>}
            <BannerHeadlineContainer>
              <RichText content={headline} />
            </BannerHeadlineContainer>
            {subheadline && <SubHeadlineStyled>{subheadline}</SubHeadlineStyled>}
            <BannerContentContainer>
              <RichText content={content} />
            </BannerContentContainer>
            <ButtonContainer>
              <PrimaryButton
                ariaLabel="see more"
                path={button_link?.link_url}
                label={`${button_link?.link_text}`}
                size={'large'}
                variant={'contained'}
              />
            </ButtonContainer>
            {cta_subtext && (
              <CTASubtextContainer>
                <RichText content={cta_subtext} />
              </CTASubtextContainer>
            )}
          </BannerLeftSideContainer>
          <BannerRightSideContainer>
            {banner_image?.image?.url && (
              <picture>
                  <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={banner_image?.image.url} />
                  <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={tablet_banner_image?.image.url} />
                  <BannerImageStyled
                      src={mobile_banner_image?.image.url}
                      alt={banner_image?.image_alt_text}
                    />
                </picture>
            )}
          </BannerRightSideContainer>
        </BannerInner>
      </CMSComponentWrapper>
    </BannerContainer>
    {desktop_social_proof?.image?.url && (
      <BannerSocialProofContainer>
        <BannerSocialProofInner>
          <picture>
            <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={desktop_social_proof?.image.url} />
            <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={tablet_social_proof_image?.image.url} />
            <BannerImageStyled
                src={mobile_social_proof_image?.image.url}
                alt={desktop_social_proof?.image_alt_text}
              />
          </picture>
        </BannerSocialProofInner>
      </BannerSocialProofContainer>
    )}
    </>
  );
};
