import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { default_breakpoints } from '@src/styles/breakpoints';

import CMSComponentWrapper from '@shared/components/component-wrapper';

import {
  Container,
  Header,
  Title,
  SeeAllLink,
  Inner,
  SeeAllTile,
  SeeAllTileContainer,
  SeeAllTileDetail,
  SeeAllLinkMobile,
} from './lc-carousel-article-list.styles';
import { LCCarouselArticleListProps } from './lc-carousel-article-list.interface';
import { ArrowControl, IconLink } from '@shared/components';

import BasicLink from '@src/shared/components/basic-link';
import { LCArticleTile } from './../lc-article-tile';
import { color_black } from '@styles/colors';

export const LCCarouselArticleList = ({
  variant = 'light',
  title,
  articleList,
  seeAllLinkText,
  seeAllLink,
  isSuppressImages = false,
  defaultMobileAllLink,
}: LCCarouselArticleListProps) => {
  if (!articleList.length) return <></>;
  const itemsMax = 6;

  return (
    <Container
      variant={variant}
      data-testid="lc-carousel-article-list__container"
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <Header>
          <Title>{title}</Title>

          <SeeAllLink>
            <BasicLink url={seeAllLink} text={seeAllLinkText} />
          </SeeAllLink>

          <SeeAllLinkMobile>
            <BasicLink url={seeAllLink} text={defaultMobileAllLink} />
          </SeeAllLinkMobile>
        </Header>
        <Inner>
          <Swiper
            spaceBetween={40}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            slidesPerView="auto"
          >
            <div className="swiper-button-prev">
              <ArrowControl left />
            </div>
            <div className="swiper-button-next">
              <ArrowControl />
            </div>
            {articleList
              .slice(0, itemsMax)
              .map(
                (
                  { image, topics, headline, headlineLink, teaser, logoCard },
                  index
                ) => (
                  <SwiperSlide key={index}>
                    <LCArticleTile
                      image={image}
                      topics={topics}
                      headline={headline}
                      headlineLink={headlineLink}
                      teaser={teaser}
                      logoCard={logoCard}
                      suppressImages={isSuppressImages}
                    />
                  </SwiperSlide>
                )
              )}
            {articleList.length >= 2 && (
              <SwiperSlide key={9999}>
                <SeeAllTile>
                  <SeeAllTileContainer>
                    <SeeAllTileDetail>
                      <IconLink
                        linkUrl={seeAllLink}
                        label={'See All'}
                        color={color_black}
                        iconColor={color_black}
                      />
                    </SeeAllTileDetail>
                  </SeeAllTileContainer>
                </SeeAllTile>
              </SwiperSlide>
            )}
          </Swiper>
        </Inner>
      </CMSComponentWrapper>
    </Container>
  );
};
