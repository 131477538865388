import React from 'react';
import { RichText, TextColor } from '@shared/components';
import { CSSimpleBannerProps } from '@src/shared/contentstack';
import CMSComponentWrapper from '@src/shared/components/component-wrapper/component-wrapper';
import { default_breakpoints } from '@styles/breakpoints';
import { background_color_mapper, text_color_mapper } from '@styles/colors';

import * as Styled from './banner-simple.styles';

export const BannerSimple = (props: CSSimpleBannerProps) => {
  const {
    headline,
    banner_copy,
    make_h1_headline,
    banner_background_color,
    site_configuration,
  } = props || {};

  const backgroundColor =
    background_color_mapper[banner_background_color || 'dark'];
  const textColor = text_color_mapper[banner_background_color || 'dark'];

  return (
    <CMSComponentWrapper
      maxWidth={default_breakpoints.cw}
      backgroundColor={backgroundColor}
      data-testid="banner-simple__container"
      uid={banner_copy?.uid}
    >
      <Styled.BannerSimple className="banner-simple" color={textColor}>
        {headline && (
          <Styled.BannerSimpleHeadline>
            {make_h1_headline ? (
              <h1>
                <TextColor>{headline}</TextColor>
              </h1>
            ) : (
              <TextColor>{headline}</TextColor>
            )}
          </Styled.BannerSimpleHeadline>
        )}
        {banner_copy && (
          <Styled.BannerSimpleCopy>
            <RichText content={banner_copy} />
          </Styled.BannerSimpleCopy>
        )}
      </Styled.BannerSimple>
    </CMSComponentWrapper>
  );
};
