import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { LeadSectionContainer } from './lead-section.styles';
import { CSLeadSection } from '@src/shared/contentstack';
import {
    HeadlineStyled,
    SubheadlineStyled,
    CTAContainer
} from './lead-section.styles';
import { PrimaryButton } from '@src/shared/components';

export const LeadSection = ({
  headline,
  subheadline,
  lead_cta,
  uid,
}: CSLeadSection) => {
  return (
    <LeadSectionContainer data-testid="lead-section_container">
      <CMSComponentWrapper 
        uid={uid} 
        maxWidth={1240}
        horizontalPadding={0}
      >
        <HeadlineStyled>{headline}</HeadlineStyled>
        <SubheadlineStyled>{subheadline}</SubheadlineStyled>
        <CTAContainer>
            <PrimaryButton
                ariaLabel={lead_cta?.link_text}
                path={lead_cta?.link_url}
                label={`${lead_cta?.link_text}`}
                size={'large'}
                variant={'contained'}
                icon={'text'}
            />
        </CTAContainer>
      </CMSComponentWrapper>
    </LeadSectionContainer>
  );
};
