import React from 'react';
import { CustomerTestimonialItem } from '../../testimonial-slider.interface';
import { 
    TestimonialCardStyled,
    TestimonialPictureContainerStyled,
    TestimonialPictureStyled,
    TestimonialTextContainerStyled,
    TestimonialTextQuoteStyled,
    TestimonialCustomerInfoContainerStyled,
    TestimonialCustomerInfoTextStyled,
    TestimonialCustomerTitleTextStyled,
    TestimonialCustomerInfoBorderStyled,
    TestimonialTextQuoteIconStyled
} from './standard-slider.styles';
import { RichText } from '@shared/components';

export const SliderCard = ({ 
    variant = 'light',
    customer_name,
    image,
    customer_designation,
    customer_quote,
    loop
}: CustomerTestimonialItem) => {
    return (
        <TestimonialCardStyled loop={loop}>
            {image?.image?.url && (
                <TestimonialPictureContainerStyled loop={loop}>
                    <TestimonialPictureStyled 
                        src={image.image.url} 
                        alt={image.image_alt_text}  
                        loop={loop}
                    />
                </TestimonialPictureContainerStyled>
            )}
            <TestimonialTextContainerStyled loop={loop}>
                <TestimonialTextQuoteStyled variant={variant} loop={loop}>
                    <TestimonialTextQuoteIconStyled src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='84' height='55' viewBox='0 0 84 55' fill='none'%3E%3Cg opacity='0.2' clip-path='url(%23clip0_8675_21346)'%3E%3Cpath d='M46.2629 36.4374C46.355 40.6866 47.8441 44.5904 50.2895 47.7113C53.7635 52.1493 59.1702 55 65.2431 55C67.3999 55 69.4708 54.6408 71.4035 53.9776C78.8641 51.4201 84.2278 44.3433 84.2278 36.0153C84.2278 25.5305 75.7279 17.0306 65.2431 17.0306C63.6772 17.0306 62.1544 17.2209 60.6991 17.5771C64.9099 12.4713 71.2807 9.2107 78.399 9.2107V3.05176e-05C60.9862 3.05176e-05 46.7634 13.9189 46.2722 31.215H46.2583V36.4374H46.2629Z' fill='%2325C9EF'/%3E%3Cpath d='M0.45216 36.4374C0.544266 40.6866 2.03332 44.5904 4.47875 47.7113C7.95271 52.1493 13.3594 55 19.4323 55C21.5891 55 23.66 54.6408 25.5927 53.9776C33.0533 51.4201 38.417 44.3433 38.417 36.0153C38.417 25.5305 29.9171 17.0306 19.4323 17.0306C17.8665 17.0306 16.3436 17.2209 14.8883 17.5771C19.0992 12.4713 25.4699 9.2107 32.5882 9.2107V3.05176e-05C15.1754 3.05176e-05 0.952602 13.9189 0.461369 31.215H0.447548V36.4374H0.45216Z' fill='%2325C9EF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8675_21346'%3E%3Crect width='84' height='55' fill='white' transform='matrix(-1 0 0 -1 84 55)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E" alt="quote" />
                    <RichText content={customer_quote} />
                </TestimonialTextQuoteStyled>
                <TestimonialCustomerInfoContainerStyled>
                    <TestimonialCustomerInfoTextStyled loop={loop}>
                        {customer_name}
                    </TestimonialCustomerInfoTextStyled>
                    {customer_designation && (
                        <>
                            <TestimonialCustomerInfoBorderStyled variant={variant}/>
                            <TestimonialCustomerTitleTextStyled loop={loop}>
                                {customer_designation}
                            </TestimonialCustomerTitleTextStyled>
                        </>
                    )}
                </TestimonialCustomerInfoContainerStyled>
            </TestimonialTextContainerStyled>
        </TestimonialCardStyled>
    )
}