import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_blue,
  color_dark_grey,
  color_white,
  color_miami,
  color_light_grey,
} from '@styles/colors';

import {
  BackgroundProp,
  ImageScrollingProp,
} from './../../content-panel.interface';
import { ContentGroupItemProps } from './content-groups.interface';
import { global_typography } from '@src/styles/typography';

export const themes: any = {
  light: {
    eyebrowColor: color_blue,
    headlineColor: color_darkest_grey,
    copyColor: color_dark_grey,
    linkColor: color_darkest_grey,
    imageShadow: color_light_grey,
  },
  mid: {
    eyebrowColor: color_blue,
    headlineColor: color_darkest_grey,
    copyColor: color_dark_grey,
    linkColor: color_darkest_grey,
    imageShadow: color_light_grey,
  },
  dark: {
    eyebrowColor: color_miami.base,
    headlineColor: color_white,
    copyColor: '#EFF2F8',
    linkColor: color_white,
    imageShadow: color_dark_grey,
  },
};

export const ContentGroupsContainer = styled.div<ImageScrollingProp>`
  ${mq_min('lg')} {
    ${({ imageScrolling }) =>
      imageScrolling &&
      `
        flex-basis: 50%;
    `}
  }
`;

export const ContentGroupItem = styled.div<ContentGroupItemProps>`
  margin-bottom: ${getStyleSizes(60)};
  display: flex;
  flex-direction: column-reverse;

  &:last-child {
    margin-bottom: 0;
  }

  ${mq_min('lg')} {
    margin-bottom: 0;
    padding: ${getStyleSizes([48, 0])};
    flex-direction: row;
    align-items: center;

    ${({ imageScrolling, imageHeight }) =>
      imageScrolling &&
      `
        padding: ${getStyleSizes([75, 0])};
        min-height: ${imageHeight}px;
    `}

    ${({ imageScrolling }) =>
      !imageScrolling &&
      `
        &:nth-of-type(odd) {
          flex-direction: row-reverse;

          > div:first-of-type {
            padding: ${getStyleSizes([0, 0, 0, 50])};
          }

          > div:last-of-type {
            justify-content: flex-start;
            padding: ${getStyleSizes([0, 50, 0, 0])};
          }
        }
    `}
  }
`;

export const DetailContainer = styled.div<ImageScrollingProp>`
  ${mq_min('lg')} {
    flex-basis: 50%;
    padding-right: ${getStyleSizes(50)};

    ${({ imageScrolling }) =>
      imageScrolling &&
      `
      flex-basis: 100%;
    `}
  }
`;

export const VideoContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: ${getStyleSizes(40)};
`;

export const ImageContainer = styled.div<ImageScrollingProp>`
  display: flex;
  justify-content: center;
  padding: ${getStyleSizes([0, 28])};
  margin-bottom: ${getStyleSizes(40)};

  ${mq_min('lg')} {
    flex-basis: 50%;
    justify-content: flex-end;
    padding: ${getStyleSizes([0, 0, 0, 50])};
    margin-bottom: 0;

    ${({ imageScrolling }) =>
      imageScrolling &&
      `
      display: none;
    `}
  }
`;

export const Image = styled.img<BackgroundProp>`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  filter: drop-shadow(
    0px 4px 30px ${({ background }) => themes[background || 'light']?.imageShadow}
  );
  border-radius: ${getStyleSizes(4)};
`;

export const BackgroundImage = styled.img`
  width: auto;
  max-height: 200px;
  object-fit: cover;

  ${mq_min('lg')} {
  }
`;

export const Eyebrow = styled.p<BackgroundProp>`
  font-family: Causten;
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  color: ${({ background }) => themes[background || 'light']?.eyebrowColor};
  margin-bottom: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
    margin-bottom: ${getStyleSizes(12)};
  }
`;

export const Headline = styled.h3<BackgroundProp>`
  font-family: Causten;
  font-weight: 400;
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(30)};
  color: ${({ background }) => themes[background || 'light']?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
    line-height: ${getStyleSizes(40)};
  }
`;

export const Copy = styled.div<BackgroundProp>`
  font-family: Causten;
  font-size: ${getStyleSizes(global_typography.copy.base)};
  color: ${({ background }) => themes[background || 'light']?.copyColor};
  margin-top: ${getStyleSizes(12)};

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(32)};
  }
`;

export const LinkContainer = styled.div`
  margin-top: ${getStyleSizes(16)};
  a {
    font-weight: 600;
  }

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(24)};
  }
`;
