import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { global_colors } from '@styles/colors';

import * as StyledHero from '../../hero-banner.styles';

export const FormBannerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding: ${getStyleSizes([50, 0])};
  column-gap: ${getStyleSizes(50)};
  row-gap: ${getStyleSizes(20)};

  ${mq_min('md')} {
    grid-template-columns: auto 55%;
    grid-template-rows: 1fr;
    padding-top: ${getStyleSizes(84)};
  }

  ${mq_min('lg')} {
    column-gap: ${getStyleSizes(140)};
    grid-template-columns: auto ${getStyleSizes(568)};
  }
`;

export const FormBannerContent = styled.div`
  color: ${({ color }) => color};

  ${mq_min('md')} {
    padding: ${getStyleSizes([80, 0])};
  }

  h1,
  h2 {
    line-height: 1;
    font-size: ${getStyleSizes(34)};
    font-weight: normal;
    margin-bottom: ${getStyleSizes(25)};

    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
    }
  }
}`;

export const BannerFormLogo = styled.div`
  margin-bottom: ${getStyleSizes(30)};
  width: 150px;
`;

export const BannerFormEyebrow = styled(StyledHero.HeroBannerEyebrow)`
  margin-bottom: ${getStyleSizes(5)};
  color: ${({ color }) => color};
`;

export const BannerFormCopy = styled(StyledHero.HeroBannerCopy)`
  margin-bottom: ${getStyleSizes(25)};
`;

export const FormBannerForm = styled.div`
  padding: ${getStyleSizes(20)};
  background: ${global_colors.background.white};
  border-radius: 4px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
  text-align: center;

  ${mq_min('md')} {
    padding: ${getStyleSizes(50)};
  }
`;

export const FormBannerTitle = styled.h2`
  font-size: ${getStyleSizes(24)};
  margin-bottom: 0;

  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(15)};
  }
`;
