import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import {
  color_light_grey,
  color_lightest_grey,
  color_white,
} from '@styles/colors';

import {
  BottomLinksProps,
  NavInnerProps,
  NavPanelStyledProps,
} from './nav-panel.interface';
import { zIndices } from '@styles/z-indices';
import { mq_min } from '@styles/breakpoints';
import { css } from '@emotion/react';

export const NavPanelStyled = styled.div<NavPanelStyledProps>`
  background-color: ${color_lightest_grey};
  padding: ${getStyleSizes([32, 30])};
  margin-left: ${getStyleSizes(-16)};
  margin-right: ${getStyleSizes(-16)};
  border-top: ${getStyleSizes(1)} solid ${color_light_grey};

  ${({ state }) => `display: ${state === 'open' ? 'grid' : 'none'};`};

  ${mq_min('lg')} {
    position: absolute;
    width: ${getStyleSizes(368)};
    top: 100%;
    left: calc(50% - ${getStyleSizes(100)});
    border: 0;
    background: inherit;
    padding: ${getStyleSizes([35, 0, 0, 0])};
    z-index: ${zIndices.header + 1};

    ${({ variant }) =>
      variant === 'two-column' &&
      `
        width: ${getStyleSizes(690)};
        left: calc(50% - ${getStyleSizes(200)});
        `};
  }
`;

export const NavInner = styled.div<NavInnerProps>`
  ${mq_min('lg')} {
    margin: ${getStyleSizes([42, 48])};

    ${({ variant }) =>
      variant === 'two-column' &&
      ` display: grid;
        grid-template-columns: ${getStyleSizes(120)} 1fr;
        grid-template-rows: 1fr;
        column-gap: 50px;
    `};

    ${({ variant }) =>
      variant === 'three-column' &&
      ` display: grid;
        grid-template-rows: 1fr;
        column-gap: 50px;
    `};
  }
`;

export const NavWrapper = styled.div`
  ${mq_min('lg')} {
    background: ${color_white};
    box-shadow: ${getStyleSizes([0, 4, 30])} rgba(199, 200, 205, 0.25);
    border-radius: ${getStyleSizes(8)};
  }
`;

export const BottomLinks = styled.div<BottomLinksProps>`
  ${({ variant }) =>
    variant === 'three-column' &&
    css`
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: baseline;
    `};
`;
