import styled from '@emotion/styled';

import { mq_max, mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import {
  color_darkest_grey,
  color_lightest_grey,
  color_white,
  color_dark_grey,
  color_text_grey,
} from '@styles/colors';
import {
  PromoCardsContainerProps,
  HeadlineProps,
  PromoTextProps,
  PromoCardsInnerProps,
  DetailContainerProps,
  CardsContainerProps,
} from './promo-cards.interface';
import { css } from '@mui/material';
import { noTo_font } from '@styles/typography';

const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  mid: {
    containerBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    promoTextColor: color_text_grey,
  },
};

export const PromoCardsContainer = styled.div<PromoCardsContainerProps>`
  padding: ${getStyleSizes([30, 0])};
  background: ${({ background }) => themes[background]?.containerBackground};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
  box-sizing: border-box;
  ${mq_min('lg')} {
    padding: ${getStyleSizes([50, 0])};
  }
`;

export const PromoCardsInner = styled.div<PromoCardsInnerProps>`
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.h3<HeadlineProps>`
  grid-area: headline;
  font-weight: 400;
  font-size: ${getStyleSizes(34)};
  margin-bottom: ${getStyleSizes(10)};
  color: ${({ background }) => themes[background]?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(48)};

    ${({ isCentered }) =>
      !isCentered &&
      `
        flex-basis: 50%;
    `}
  }
`;

export const Inner = styled.div`
  grid-area: cards;
  overflow-x: auto;
  margin-bottom: ${getStyleSizes(10)};

  .swiper-slide {
    &:nth-of-type(-n + 2) {
      margin-right: ${getStyleSizes(24)};
    }
  }

  ${mq_min('lg')} {
    .swiper-slide {
      &:nth-of-type(-n + 2) {
        margin-right: ${getStyleSizes(41)};
      }
    }
  }
`;

export const DetailContainer = styled.div<DetailContainerProps>`
  padding-top: ${getStyleSizes(16)};
  display: grid;
  ${({ gridType }) => {
    return gridType === 'stack'
      ? `grid-template-columns: 1fr;
    grid-template-areas:
    'headline'
    'copy'
    'button'
    'cards';`
      : `grid-template-columns: 1fr;
      grid-template-areas:
      'headline'
      'copy'
      'cards'
      'button';

            ${mq_min('md')} {
              grid-template-columns: 1fr auto;
              grid-template-areas:
              'headline button'
              'copy copy'
              'cards cards';
            }
            `;
  }}}
`;

export const PromoText = styled.div<PromoTextProps>`
  grid-area: copy;
  font-family: ${noTo_font};
  padding: ${getStyleSizes([0, 20, 30, 20])};
  color: ${({ background }) => themes[background]?.promoTextColor};
  letter-spacing: 0.01em;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: ${getStyleSizes(16)};
  font-weight: 350;
  line-height: ${getStyleSizes(26)};

  ${mq_min('md')} {
    font-size: ${getStyleSizes(18)};
    line-height: ${getStyleSizes(32)};
  }
`;

const gridBoxed = css`
  display: grid;
  grid-auto-columns: 1fr;
  width: 100%;
  margin: 0 auto;
  ${mq_min('md')} {
    width: 80%;
  }
  article {
    width: 100%;
    ${mq_min('md')} {
      justify-content: center;
    }
  }
`;

export const StyledButton = styled.div`
  grid-area: button;
  margin-bottom: ${getStyleSizes([20])};
  ${mq_min('md')} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${getStyleSizes([20])};
  }
`;

export const CardsContainer = styled.div<CardsContainerProps>`
  display: grid;
  column-gap: 50px;
  margin-top: ${getStyleSizes(30)};
  grid-template-columns: 1fr;
  row-gap: 50px;

  ${mq_min('md')} {
    &[data-length='2'] {
      grid-template-columns: 1fr 1fr;
      justify-content: center;
    }
    &[data-length='1'] {
      grid-template-columns: auto;
      justify-content: center;
    }
  }

  ${({ cardType }) => {
    switch (cardType) {
      case 'boxed':
        return css`
          & h3 {
            text-align: center;
          }
          ${mq_min('md')} {
            &[data-length='1'] {
              ${gridBoxed}
            }
            &[data-length='2'] {
              ${gridBoxed}
            }
            &[data-length='3'] {
              grid-template-columns: 1fr 1fr 1fr;
              justify-content: center;
              ${mq_max('md')} {
                grid-template-columns: auto;
                article {
                  width: 100%;
                }
              }
            }
          }
        `;
      case 'square':
        return css`
          ${mq_min('md')} {
            &[data-length='3'] {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
        `;
      case 'tall':
        return css`
          ${mq_min('md')} {
            &[data-length='3'] {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
        `;
    }
  }}
`;
