import { CSAsset, CSStandardPromo } from '@shared/contentstack';
import { BackgroundType } from '@src/shared/interfaces/theme.interface';
import { StandardPromoProps, VariantType } from '../standard-promo.interface';

export function standardPromoMapper(
  query: CSStandardPromo,
  props: {
    backgroundColor: BackgroundType;
    button: boolean;
    variant: VariantType;
  }
): StandardPromoProps {
  const {
    headline,
    subhead,
    copy,
    promo_image,
    promo_type,
    promo_link,
    image_alignment,
    uid,
  } = query || {};

  return {
    image: {
      src: promo_image?.image?.url,
      width: promo_image?.image?.dimension?.width,
      height: promo_image?.image?.dimension?.height,
      alt: promo_image?.image_alt_text,
    },
    headline,
    subhead,
    copy,
    promoType: promo_type === 'standard' ? 'standard' : 'headline',
    imageAlignment: image_alignment === 'inset' ? 'inset' : 'right',
    link: {
      isExternal: !!promo_link?.external_link,
      text: promo_link?.link_text,
      url: promo_link?.link_url,
    },
    // fields shareds
    backgroundColor: props?.backgroundColor,
    variant: props?.variant,
    linkAsButton: props?.button,
    uid,
  };
}
