import React, { useState, useRef, MutableRefObject } from 'react';
import { navigate } from 'gatsby';
import * as Styled from './contact-form.styles';
import useMarketo from '@shared/hooks/useMarketo';
import { isBrowser, isElement } from '@shared/utilities';
import { CONTACT_FORM_CONSTANTS } from '@shared/components/contact-form/contact-form.constants';

export interface ContactFormProps {
  base_url: string;
  tracking_code: string;
  form_id: string;
  title: string;
  success_message: string;
}

export const ContactForm = ({
  base_url,
  tracking_code,
  form_id,
  title = CONTACT_FORM_CONSTANTS.TITLE,
  success_message = CONTACT_FORM_CONSTANTS.SUCCESS_MESSAGE,
}: ContactFormProps) => {
  const formRef: MutableRefObject<any> = useRef();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const isValidFormConfig: boolean = !!base_url && !!form_id && !!tracking_code;
  const form_element_id = `${CONTACT_FORM_CONSTANTS.FORM_BASE_ID}_${form_id}`;

  const initForm = (form: any): void => {
    if (formRef.current) {
      form.onSuccess(onSuccess);
    }
  };

  // If needed in the future, the Marketo success callback includes:
  // - submitted form values
  // - redirect url
  const onSuccess = (form: any, redirect: string) => {
    if (redirect) {
      navigate(redirect);
    } else {
      setFormSubmitted(true);
    }
    return false;
  };

  if (isBrowser()) {
    useMarketo({
      baseUrl: base_url,
      munchkinId: tracking_code,
      formId: form_id,
      callback: () => {},
      onLoadCallback: (form: any) => initForm(form),
      exists:
        formRef.current &&
        isElement(formRef.current) &&
        formRef.current.childNodes.length > 0,
    });
  }
  return (
    <>
      <Styled.ContactForm>
        <Styled.FormTitle>{title}</Styled.FormTitle>
        {formSubmitted && <div>{success_message}</div>}
        {isValidFormConfig && !formSubmitted && (
          <form ref={formRef} id={form_element_id} />
        )}
      </Styled.ContactForm>
    </>
  );
};
