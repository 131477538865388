import React from 'react';

import { component_mapper } from '@src/cms-components';
import * as contentStackInterfaces from '@shared/contentstack/contentstack.interface';
import { contentStackComponentMapper } from '@shared/contentstack/contentstack.constants';

export const mappedComponent = (
  componentType: string,
  data: any,
  index: number
) => {
  // get the component based on the componentType provided by Contentstack
  const Component = component_mapper[componentType];
  // if there is a component for the type provided, return it with its data
  // otherwise, log that it or its data is missing.
  // console.log('mappedComponentmappedComponent', data);

  // Check to make sure that there is corresponding contentstack data for the component.
  // data will be a handled with an array of ids [ids] passed to the component's mapper.
  // the [hero_banner] is an exception to this rule as its data is part of the page data
  // it doesn't have an [ids] property but it still needs to be mapper to a component.
  // [hasNoData] handles this condition. If additional components meet this criterial,
  // add their contentstack key to the [contentStackComponentsWithNoIds] array.

  const hasNoData = false;
  // !contentStackComponentsWithNoIds.includes(componentType) &&
  // !data.ids?.length;

  // ANY GLOBAL DATA THAT NEED TO BE PASSED TO A COMPONENT NEEDS TO HAPPEN HERE!!
  return !Component || hasNoData ? (
    console.info(`No component was found for [${componentType}]`)
  ) : (
    <Component
      key={`component-${componentType}-${index}`}
      variant={componentType}
      {...data}
    />
  );
};

const normalizedComponent = ([name, component]: [string, any]) => {
  const type = name.toLowerCase();

  const key: string | null = contentStackComponentMapper[type];
  const data = !!key && component[key] ? component[key][0] : component;

  if (key) {
    const { [key]: id, ...rest } = component;
    return { type, ...data, ...rest, _lp: rest };
  }
  return { type, ...data };
};

// Remove any empty components.
export const filterDefinedBanners = (banners: any) => {

  return banners && !!banners.length ? banners.reduce(
    (memo: any, current: contentStackInterfaces.CSPageBannerType) => {
      const [banner] = Object.entries(current).filter(([k, v]) => !!v);
      return !!banner ? [...memo, normalizedComponent(banner)] : memo;
    },
    []
  ): [];
};

// Remove any empty components.
export const filterDefinedComponents = (components: any) => {
  return components.reduce((memo: any, current: any) => {
    const [component] = Object.entries(current).filter(([k, v]) => !!v);
    return !!component ? [...memo, normalizedComponent(component)] : memo;
  }, []);
};
