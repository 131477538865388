import { AccordionProps } from '../accordion.interface';
import { CSAccordion } from '@shared/contentstack/contentstack.interface';
import { CSRichText } from '@shared/contentstack';

export function accordionMapper({
  accordion_title,
  accordion_items,
  additional_information_content,
  additional_information_title,
  site_configuration,
}: CSAccordion): AccordionProps {
  return {
    title: accordion_title,
    itemList:
      accordion_items?.map(
        ({ headline, content }: { headline: string; content: CSRichText }) => ({
          summary: headline,
          details: content,
        })
      ) || [],
    additionalInformationContent: additional_information_content,
    additionalInformationTitle: additional_information_title,
    site_configuration,
  };
}
