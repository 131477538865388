import React from 'react';
import { QuotePromo } from '../quote-promo';
import { quotePromoMapper } from '../quote-promo/mappers/quote-promo.mapper';
import { StandardPromo } from '../standard-promo';
import { standardPromoMapper } from '../standard-promo/mappers/standard-promo.mapper';

export const FullwidthPromoWrapper = (data: any) => {
  if (data.internal?.type === 'Contentstack_standard_promo') {
    return (
      <StandardPromo
        {...standardPromoMapper(data, {
          backgroundColor: data.background_color,
          button: data.button,
          variant: 'full-width',
        })}
      />
    );
  }

  if (data.hasOwnProperty('quote')) {
    return (
      <QuotePromo
        {...quotePromoMapper(data, {
          backgroundColor: data.background_color,
          variant: 'full-width',
        })}
      />
    );
  }

  return null;
};
