import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { ArrowControlProps } from './arrow-control.interface';

export const Container = styled.div<ArrowControlProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 1px solid ${({ color }) => color};
  border-radius: 50%;
  transition: 0.2s linear;
  cursor: pointer;

  &:hover {
    background: ${({ color }) => color}20;
  }

  ${({ left }) =>
    left &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`;
