import { color_logo_grad_10 } from '@src/styles/colors';
import styled from 'styled-components';

export const CheckIconStyled = styled.svg`
  width: 24px;
  height: 24px;
  path {
    fill: ${color_logo_grad_10};
  }
`;
