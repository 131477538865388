import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';

import { color_black, color_white } from '@styles/colors';
import { ArrowIcon, PrimaryButton } from '@shared/components';
import { getStyleSizes } from '@styles/styles.utils';

import {
  HamburgerMenuButtonProps,
  LogoStyledProps,
} from './top-menu.interface';
import { mq_min, mq_max } from '@styles/breakpoints';
import { global_typography } from '@src/styles/typography';

const TopMenuConfiguration = {
  menu: {
    width: getStyleSizes(40),
    height: getStyleSizes(40),
    border: getStyleSizes(1),
  },
};

export const TopMenuStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;

  ${mq_min('lg')} {
    width: auto;
  }
`;

export const GetADemo = styled(PrimaryButton)`
  font-weight: 600;
  font-size: ${getStyleSizes(global_typography.copy.base)};
`;

export const LinkIcon = styled(ArrowIcon)`
  position: relative;
  top: ${getStyleSizes(2)};
  font-size: ${getStyleSizes(12)};
  margin-left: ${getStyleSizes(5)};
`;

export const LogoStyled = styled(GatsbyLink)<LogoStyledProps>`
  background-color: ${({ color }) =>
    color === 'dark' ? color_black : color_white};
  margin-top: ${getStyleSizes(5)};

  ${mq_max('xs')} {
    max-width: ${getStyleSizes(100)};
  }

  ${mq_max('xl')} {
    margin-right: ${getStyleSizes(25)};
  }

  img {
    max-width: ${getStyleSizes(125)};
  }
`;

export const MobileMenuButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  ${mq_min('lg')} {
    display: none;
  }
`;

export const HamburgerMenuButton = styled('a')<HamburgerMenuButtonProps>`
  border: 0;
  background: none;
  height: ${TopMenuConfiguration.menu.width};
  width: ${TopMenuConfiguration.menu.height};
  position: relative;
  padding: ${getStyleSizes([0, 6])};
  margin-left: ${getStyleSizes(8)};
  top: ${getStyleSizes(20)};

  ${mq_min('xs')} {
    margin-left: ${getStyleSizes(15)};
  }

  &:before {
    position: relative;
    content: '';
    display: block;
    width: 100%;
    border-top: ${TopMenuConfiguration.menu.border} solid
      ${({ reverse }) => (reverse ? color_white : color_black)};
    transition: transform 100ms linear;
    ${({ state }) =>
      `transform: ${
        state === 'open' ? 'rotate(45deg);' : 'rotate(0) translateY(-5px);'
      }`};
  }

  &:after {
    position: relative;
    content: '';
    display: block;
    width: 100%;
    border-top: ${TopMenuConfiguration.menu.border} solid
      ${({ reverse }) => (reverse ? color_white : color_black)};
    transition: transform 100ms ease-in-out;
    ${({ state }) =>
      `transform: ${
        state === 'open'
          ? 'rotate(-45deg) translateY(-1px);'
          : 'rotate(0) translateY(5px);'
      }`};
  }
`;
