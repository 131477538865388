import { PageHeaderStyleType } from '@shared/context/pageHeaderStyle.context';

export const resolveColonDelimited = (string: string) => {
  return !!string && string.indexOf(':') > -1 ? string.split(':')[1] : string;
};

export function resolveHeaderStyle(pageContext: any): PageHeaderStyleType {
  let color: PageHeaderStyleType = 'light';

  if (!pageContext) {
    throw new Error(`pageContext doesn't exist`);
  }

  const { header_style } = pageContext || {};

  if (header_style) {
    color = header_style.header_style as PageHeaderStyleType;
  }
  return color;
}
