import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { FAQAccordion as AccordionComponent } from '@shared/components';

import { AccordionContainer, AccordionInner } from './accordion.styles';
import { AccordionProps } from './accordion.interface';

import { CSFAQAccordion } from '@shared/contentstack/contentstack.interface';
import { accordionMapper } from './mappers/accordion.mapper';

export const FAQAccordion = ({
  headline,
  itemList,
  uid,
}: AccordionProps) => {
  return (
    <AccordionContainer data-testid="accordion_container">
      <CMSComponentWrapper 
        uid={uid} 
        maxWidth={1200}
        horizontalPadding={0}
      >
        <AccordionInner>
          <AccordionComponent
            headline={headline}
            itemList={itemList?.map(({ question ,answer }) => ({
              question, answer
            }))}
          />
        </AccordionInner>
      </CMSComponentWrapper>
    </AccordionContainer>
  );
};

export const MappedFAQAccordion = (data: CSFAQAccordion) => {
  return <FAQAccordion {...accordionMapper(data)} />;
};
