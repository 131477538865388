import { AccordionProps } from '../accordion.interface';
import { CSFeaturesAccordion, CSFeaturesAccordionLink, CSImageAlt } from '@shared/contentstack/contentstack.interface';
import { CSRichText } from '@shared/contentstack';

export function accordionMapper({
  title,
  layout,
  headline,
  template,
  accordion_items,
  additional_information_content,
  additional_information_title,
  site_configuration,
}: CSFeaturesAccordion): AccordionProps {
  return {
    title,
    layout,
    template,
    headline,
    itemList:
      accordion_items?.map(
        ({ headline, content, link, video_id, feature_image, feature_icon, tablet_feature_image, mobile_feature_image }: { 
          headline: string; 
          video_id?: string;
          content: CSRichText; 
          link: CSFeaturesAccordionLink;
          feature_image: CSImageAlt;
          feature_icon: CSImageAlt;
          tablet_feature_image: CSImageAlt;
          mobile_feature_image: CSImageAlt;
        }) => ({
          summary: headline,
          details: content,
          link,
          video_id,
          feature_image,
          tablet_feature_image,
          mobile_feature_image,
          feature_icon
        })
      ) || [],
    additionalInformationContent: additional_information_content,
    additionalInformationTitle: additional_information_title,
    site_configuration,
  };
}
