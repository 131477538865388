import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

import CMSComponentWrapper from '@shared/components/component-wrapper';
import { ArrowControl, ImageComponent } from '@shared/components';
import { color_miami } from '@styles/colors';

import {
  Container,
  Inner,
  Item,
  ItemImage,
  ItemDetail,
  ItemTopics,
  ItemHeadline,
} from './lc-carousel-banner.styles';
import { LCCarouselBannerProps } from './lc-carousel-banner.interface';

import BasicLink from '@src/shared/components/basic-link';
import { default_breakpoints } from '@src/styles/breakpoints';

export const LCCarouselBanner = ({
  articleList,
  fallbackImage,
}: LCCarouselBannerProps) => {
  if (!articleList.length) return <></>;

  return (
    <Container data-testid="lc-carousel-banner__container">
      <Inner>
        <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
          <Swiper
            loop
            spaceBetween={40}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            slidesPerView="auto"
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            <div className="swiper-button-prev">
              <ArrowControl left size={40} color={color_miami.base} />
            </div>
            <div className="swiper-button-next">
              <ArrowControl size={40} color={color_miami.base} />
            </div>
            {articleList.map(
              ({ image, topics, headline, headlineLink }, index) => {
                const itemImage = image.image ? image : fallbackImage;
                return (
                  <SwiperSlide key={index}>
                    <Item>
                      {itemImage?.image && (
                        <ItemImage>
                          <ImageComponent
                            image={itemImage.image?.gatsbyImageData}
                            alt_text={itemImage?.image_alt_text}
                          />
                        </ItemImage>
                      )}

                      <ItemDetail>
                        <ItemTopics>{topics.join(', ')}</ItemTopics>
                        <ItemHeadline>
                          <BasicLink url={headlineLink} text={headline} />
                        </ItemHeadline>
                      </ItemDetail>
                    </Item>
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </CMSComponentWrapper>
      </Inner>
    </Container>
  );
};
