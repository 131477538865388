import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LinkIcon: React.FunctionComponent<SvgIconProps> = (
  props
): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5166 2.28088C15.6607 0.425011 12.6518 0.425016 10.7959 2.28089L7.85281 5.22397C7.60057 5.47621 7.80841 5.86762 8.1648 5.85233L9.67642 5.78748C9.97297 5.77476 10.254 5.65125 10.4639 5.44136L12.2101 3.6951C13.2849 2.62028 15.0276 2.62028 16.1024 3.6951C17.1772 4.76992 17.1772 6.51255 16.1024 7.58738L13.1593 10.5305C12.0845 11.6053 10.3418 11.6053 9.26702 10.5305C9.03684 10.3003 8.73569 10.1361 8.41046 10.15L8.30129 10.1547C7.53382 10.1876 7.02938 10.9682 7.51201 11.5658C7.61765 11.6966 7.73125 11.8231 7.85281 11.9447C9.70868 13.8005 12.7176 13.8005 14.5735 11.9447L17.5166 9.00159C19.3725 7.14572 19.3725 4.13676 17.5166 2.28088Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0315 9.77394C11.8495 9.74609 12.3668 8.8988 11.8078 8.30089C11.7704 8.26082 11.7321 8.22124 11.693 8.18215C9.81091 6.30004 6.7594 6.30005 4.87729 8.18216L2.02922 11.0302C0.147113 12.9123 0.147116 15.9638 2.02922 17.8459C3.91134 19.7281 6.96284 19.7281 8.84495 17.8459L11.693 14.9979C11.8883 14.8026 11.7325 14.4935 11.4564 14.5029L9.7394 14.5613C9.44786 14.5712 9.17097 14.6915 8.96471 14.8978L7.43074 16.4317C6.32968 17.5328 4.5445 17.5328 3.44344 16.4317C2.34238 15.3307 2.34238 13.5455 3.44344 12.4444L6.29151 9.59637C7.39257 8.49531 9.17774 8.49531 10.2788 9.59637C10.3977 9.7153 10.5568 9.7901 10.7249 9.78437L11.0315 9.77394Z"
      />
    </SvgIcon>
  );
};
