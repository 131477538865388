import {
  PromoCardsProps,
  VariantType,
} from '@cms-components/promos/promo-cards/promo-cards.interface';
import { BackgroundType } from '@src/shared/interfaces/theme.interface';
import { BackgroundType as BackgroundTypeCard } from '@src/shared/components/card-presentation/card-presentation.interface';
import { CSPromoCards } from '@src/shared/contentstack';

export function PromoCardsMapper(
  data: CSPromoCards,
  props: {
    light_secondary_background: boolean;
    card_type: VariantType;
    primary_background_color: BackgroundType;
  }
): PromoCardsProps {
  const { headline, title, cards, content, button, uid } = data || {};

  return {
    uid,
    headline,
    title,
    content,
    button,
    cards: cards?.map((card) => ({
      background: card.background_color.colors as BackgroundTypeCard,
      icon: card?.icon_or_logo?.image,
      image: props.card_type === 'square' ? card?.background_image.image : null,
      title: card.card_headline,
      subtitle: card.card_content,
      variant: props.card_type,
      ctaOrAttribution: card.cta_or_attribution,
      url: card.url,
    })),

    isCentered: true,
    cardType: props.card_type,
    backgroundColor: props.primary_background_color || 'light',
    lightSecondaryBackground: props.light_secondary_background,
  };
}
