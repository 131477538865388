import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import { color_dark_grey, color_white, color_blue } from '@styles/colors';

import { VariantProp } from '../../list-panel.interface';
import {
  HeaderItemProps,
  AccordionContainerProps,
} from './faq-content.interface';
import { global_typography } from '@src/styles/typography';

export const variants = {
  light: {
    headerItemColor: color_dark_grey,
    headerItemActiveColor: color_blue,
  },
  mid: {
    headerItemColor: color_dark_grey,
    headerItemActiveColor: color_blue,
  },
  dark: {
    headerItemColor: color_white,
    headerItemActiveColor: '#65DFFF',
  },
};

export const Header = styled.div`
  margin-bottom: ${getStyleSizes(32)};
`;

export const HeaderItem = styled.label<HeaderItemProps>`
  font-size: ${getStyleSizes(16)};
  font-weight: 400;
  color: ${({ variant }) => variants[variant]?.headerItemColor};
  margin-right: ${getStyleSizes(32)};
  padding-bottom: ${getStyleSizes(4)};
  border-bottom: 1px solid transparent;
  cursor: pointer;

  ${({ isActive, variant }) =>
    isActive &&
    `
    cursor: initial;
    font-weight: 600;
    color: ${variants[variant]?.headerItemActiveColor};
    border-bottom-color: ${variants[variant]?.headerItemActiveColor};
  `}

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const List = styled.div``;

export const ItemHeadline = styled.h4<VariantProp>`
  font-weight: 600;
  font-size: ${getStyleSizes(16)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const FaqContentContainer = styled.div``;

export const AccordionContainer = styled.div<AccordionContainerProps>`
  ${({ isActive }) =>
    !isActive &&
    `
      display: none;
  `}
  p {
    font-size: ${getStyleSizes(global_typography.copy.base)};
  }
`;
