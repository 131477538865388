import styled from '@emotion/styled';
import { mq_min } from '@src/styles/breakpoints';
import {
  color_blue_10,
  color_darkest_grey,
  color_lightest_grey,
  color_white,
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';
import { causten_font } from '@src/styles/typography';
import {
  FaqContentContainer,
  HeaderItem,
} from '../list-panel/components/faq-content/faq-content.styles';
import { BackgroundProp } from './image-faq-panel.interface';

export const variants: any = {
  light: {
    headerItemColor: color_darkest_grey,
    backgroundColor: color_white,
  },
  mid: {
    headerItemColor: color_darkest_grey,
    backgroundColor: color_lightest_grey,
  },
  dark: {
    headerItemColor: color_blue_10,
    backgroundColor: color_darkest_grey,
  },
};

export const ContainerImageFaqPanel = styled.div<BackgroundProp>`
  background: ${({ variantColor }: any) => variants[variantColor]?.backgroundColor};
  padding: ${getStyleSizes([50, 0])};

  ${FaqContentContainer} {
    width: 100%;
    ${mq_min('lg')} {
      width: ${getStyleSizes(462)};
    }

    ${HeaderItem} {
      font-size: ${getStyleSizes(26)};
      color: ${({ variantColor }) => variants[variantColor]?.headerItemColor};
      border-bottom: none;
      font-family: ${causten_font};
      line-height: ${getStyleSizes(32)};
      font-weight: 400;
      ${mq_min('lg')} {
        font-size: ${getStyleSizes(36)};
        line-height: ${getStyleSizes(40)};
      }
    }
  }
`;

export const PanelAccordeon = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${mq_min('lg')} {
    flex-direction: row;
  }
`;

export const WrapperImage = styled.div`
  width: 100%;
  ${mq_min('lg')} {
    width: ${getStyleSizes(490)};
  }
  .gatsby-image-wrapper {
    img {
      width: 100%;
      height: 100%;
      ${mq_min('lg')} {
        width: ${getStyleSizes(490)};
        height: ${getStyleSizes(433)};
      }
    }
  }
`;
