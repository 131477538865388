import React from 'react';

import BaseLayout from './src/templates/base-layout/base-layout';

import './src/styles/global.css';
import { PageHeaderStyleType } from './src/shared/context/pageHeaderStyle.context';
import { resolveHeaderStyle } from './src/shared/resolvers/content-stack.resolver';
import pageMetaMapper from './src/shared/mappers/pageMeta.mapper';

export const wrapPageElement = ({ element }: any) => {
  const { props } = element;

  const { pageContext, location } = props;
  const {
    page_configuration,
    social_fallback_image,
    domain,
    site_configuration,
    hasGraphQL,
  } = pageContext;

  const pageMeta = pageMetaMapper(
    page_configuration,
    social_fallback_image,
    domain,
    site_configuration
  );
  const headerStyle: PageHeaderStyleType = resolveHeaderStyle(pageContext);

  return (
    <BaseLayout
      headerStyle={headerStyle}
      pageMeta={pageMeta}
      location={location}
      isLivePreview={!hasGraphQL}
    >
      {!!element && element}
    </BaseLayout>
  );
};
