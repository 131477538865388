import { Logo, MailIcon, PhoneIcon, PrimaryButton } from '@shared/components';
import BasicLink from '@shared/components/basic-link';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { Link } from 'gatsby';
import React from 'react';
import { FooterLinks } from './components/';
import { fetchFooterData } from './footer.facade';
import { FooterProps } from './footer.interface';
import * as Styled from './footer.styles';
import { footerMapper } from './mappers/footer.mapper';

export const Footer: React.FC<FooterProps> = ({
  copyright,
  demoButton,
  email,
  footerLinks,
  phone,
  utilityLinks,
}) => {
  return (
    <div data-testid="footer__container">
      <CMSComponentWrapper maxWidth={1240} margin={[50, 0]}>
        <Styled.FooterContainer>
          <Styled.FooterLogo>
            <Link to={'/'} aria-label="Home Page">
              <Logo width={'148px'} />
            </Link>
          </Styled.FooterLogo>
          <Styled.FooterContainerLinkSection>
            {!!footerLinks &&
              footerLinks.map((footerLinkItem, index: number) => (
                <FooterLinks
                  key={`footer-links-${index}`}
                  linkList={footerLinkItem.linkList}
                  menuSection={footerLinkItem.menuSection}
                />
              ))}
          </Styled.FooterContainerLinkSection>
          <Styled.FooterContactInfoSection>
            <div>
              <Styled.DemoButtonContainer>
                <PrimaryButton
                  ariaLabel="get a demo"
                  path={demoButton?.url}
                  label={demoButton?.text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                  fullwidth={true}
                />
              </Styled.DemoButtonContainer>
            </div>
            <Styled.FooterContactInfo>
              <Styled.FooterContactLink href={`tel:${phone}`}>
                <PhoneIcon sx={{ fontSize: 20 }} />
                <span>{phone}</span>
              </Styled.FooterContactLink>
              <Styled.FooterContactLink href={`mailto:${email}`}>
                <MailIcon sx={{ fontSize: 20 }} />
                <span>{email}</span>
              </Styled.FooterContactLink>
            </Styled.FooterContactInfo>
          </Styled.FooterContactInfoSection>
        </Styled.FooterContainer>
      </CMSComponentWrapper>
      <CMSComponentWrapper maxWidth={1240} border={'Top'} margin={[30, 20]}>
        <Styled.FooterUtility>
          <div>
            <Styled.FooterUtilityLinks>
              {!!utilityLinks &&
                utilityLinks.map(({ url, text, isExternal }, index) => (
                  <li key={`footer-utility-link-${index}`}>
                    <BasicLink
                      underline={true}
                      url={url}
                      text={text}
                      isExternal={isExternal}
                    />
                  </li>
                ))}
            </Styled.FooterUtilityLinks>
          </div>
          <div>
            <Styled.FooterUtilityCopyright>
              &#169;{copyright}
            </Styled.FooterUtilityCopyright>
          </div>
        </Styled.FooterUtility>
      </CMSComponentWrapper>
    </div>
  );
};

export const CMSFooter = () => {
  // GRAPHQL data
  const data = fetchFooterData();

  const mapper = footerMapper({ ...data });

  return <Footer {...mapper} />;
};

export default Footer;
