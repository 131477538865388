import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useFetchArticles = (): any[] => {
  const { allContentstackLearningCenterContentPage }: any =
    useStaticQuery(graphql`
      {
        allContentstackLearningCenterContentPage(
          sort: { fields: publication_date, order: DESC }
        ) {
          nodes {
            publication_date(formatString: "MMMM DD, YYYY", locale: "en")
            id
            uid
            title
            page_url
            key_quote
            featured_image {
              image {
                image {
                  url
                  gatsbyImageData
                }
                image_alt_text
              }
            }
            headline
            lc_content_type {
              url_segment
              navigation_name
              id
            }
            teaser_text
            customer
            topics {
              display_name
            }
            logo_card {
              logo {
                image {
                  gatsbyImageData
                  url
                }
                image_alt_text
              }
              card_color {
                colors
              }
            }
          }
        }
      }
    `);
  return useMemo(
    () => allContentstackLearningCenterContentPage.nodes,
    [allContentstackLearningCenterContentPage]
  );
};
