import React from 'react';
import BasicLink from '../basic-link';
import { ImageComponent } from '@shared/components';
import { cardPresentationTextMapper } from './card-presentation.mapper';

import { CardPresentationProps } from './card-presentation.interface';
import * as Styled from './card-presentation.styles';

export const CardPresentation = ({
  icon,
  title,
  subtitle,
  titleSize = 28,
  background = null,
  ctaOrAttribution,
  url,
  image,
  variant,
  type,
}: CardPresentationProps) => {

  const content = cardPresentationTextMapper({
    title,
    subtitle,
    background,
    type,
    ctaOrAttribution,
  });

  return (
    <BasicLink url={url || ''} underline={false}>
      <Styled.Container
        variant={variant}
        background={background}
        data-testid="card-presentation__container"
      >
        {image && (
          <Styled.ImageContainer>
            <ImageComponent image={image?.gatsbyImageData} fallback={image.url} fallback_shadow={false} alt_text={''} />
          </Styled.ImageContainer>
        )}

        <Styled.Inner
          className={`inner-${variant || 'base'}`}
          variant={variant}
          color={content?.textColor}
          hasimage={image ? 1 : 0}
        >
          <div className={`logo-icon ${content?.iconPositionClass} ${!icon?.gatsbyImageData ? 'fallback-image' : ''}`}>
            {icon && (
              <Styled.StyledLogo>
                <ImageComponent image={icon.gatsbyImageData} fallback={icon.url} fallback_shadow={false} alt_text={''} is_icon={true} />
              </Styled.StyledLogo>
            )}
          </div>
          <header className={`header-${variant || 'base'}`}>
            <h3>
              <span dangerouslySetInnerHTML={{ __html: content?.title }} />
            </h3>
          </header>
          <Styled.ContainerCopy
            className={`copy-${variant || 'base'} type-${type || 'base'}`}
          >
            {content.subtitle && (
              <Styled.Paragraph>
                <span dangerouslySetInnerHTML={{ __html: content.subtitle }} />
              </Styled.Paragraph>
            )}
            {content.ctaOrAttribution && (
              <Styled.ContainerAttribution
                className={`cta-${variant || 'base'}  type-${type || 'base'}`}
                variant={variant}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: content.ctaOrAttribution }}
                />
              </Styled.ContainerAttribution>
            )}
          </Styled.ContainerCopy>
        </Styled.Inner>
      </Styled.Container>
    </BasicLink>
  );
};
