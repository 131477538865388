import React from 'react';
import { RichTextProps, TagNodeType } from './rich-text.interface';
import {
  Paragraph,
  Ul,
  Ol,
  Li,
  Span,
  Blockquote,
  H2,
  H3,
  Image,
} from './rich-text.styles';
import { TextType, ChildrenType } from '@shared/interfaces/rich-text.resolver';
import { TextColor, VideoPlayer } from '@shared/components';
import BasicLink from '@shared/components/basic-link';

const sentences: TagNodeType[] = [
  {
    id: 'p',
    nodeStyled: Paragraph,
  },
  {
    id: 'span',
    nodeStyled: Span,
  },
  {
    id: 'ul',
    nodeStyled: Ul,
  },
  {
    id: 'ol',
    nodeStyled: Ol,
  },
  {
    id: 'li',
    nodeStyled: Li,
  },
  {
    id: 'blockquote',
    nodeStyled: Blockquote,
  },
  {
    id: 'code',
    nodeStyled: Blockquote,
  },
  {
    id: 'h2',
    nodeStyled: H2,
  },
  {
    id: 'h3',
    nodeStyled: H3,
  },
  {
    id: 'fragment',
    nodeStyled: Span,
  },
];

export const RichText = ({ content }: RichTextProps) => {
  const renderSpan = (
    { text, bold, italic, underline, superscript, children }: TextType,
    key: any
  ) => {
    return (
      <Span
        key={key}
        isBold={Boolean(bold)}
        isItalic={Boolean(italic)}
        isUnderline={Boolean(underline)}
        isSuper={Boolean(superscript)}
      >
        {text ? (
          <TextColor>{text}</TextColor>
        ) : children ? (
          renderElements(children || [])
        ) : (
          <>&#xFEFF;</>
        )}
      </Span>
    );
  };

  const renderElements = (childrenList: ChildrenType[]) => {
    return childrenList?.map((child, index) => {
      const {
        type,
        children,
        text,
        bold,
        italic,
        underline,
        attrs,
        superscript,
      } = child;
      if (type === 'span' || (!type && !!text)) {
        return renderSpan(
          { text, bold, italic, underline, superscript, children },
          index
        );
      }

      if (type === 'a' && !!attrs?.url) {
        return (
          <BasicLink
            key={`${attrs?.url}-${index}`}
            url={attrs?.url}
            text={text}
            isExternal={attrs?.target === '_blank'}
          >
            {renderElements(children || [])}
          </BasicLink>
        );
      }
      if (
        type === 'reference' &&
        attrs?.['display-type'] === 'display' &&
        /^image\//.test(attrs?.['asset-type'] || '')
      ) {
        return (
          <Image
            key={index}
            src={attrs['asset-link']}
            width={attrs.width}
            height={attrs.height}
            alt={attrs.alt || ''}
          />
        );
      }

      if (type === 'embed' && attrs?.src) {
        return <VideoPlayer id={attrs.src} key={index} />;
      }

      const sentence = sentences.find(({ id }) => id === type);
      if (sentence) {
        const NodeStyled = sentence.nodeStyled;
        return type === 'p' ? (
          <Paragraph
            id={attrs?.id}
            key={index}
            isBold={Boolean(children && children[0].bold)}
            isItalic={Boolean(children && children[0].italic)}
            isUnderline={Boolean(children && children[0].underline)}
          >
            {renderElements(children || [])}
          </Paragraph>
        ) : type === 'li' ? (
          <Li id={attrs?.id} key={index}>
            <div>{renderElements(children || [])}</div>
          </Li>
        ) : (
          <NodeStyled id={attrs?.id} key={index}>
            {renderElements(children || [])}
          </NodeStyled>
        );
      }

      if (!type && !!text) {
        return text;
      }

      // no text or children
      return <>&#xFEFF;</>;
    });
  };

  return content?.children ? (
    <div data-testid="rich-text__container">
      {renderElements(content?.children || [])}
    </div>
  ) : (
    <></>
  );
};
