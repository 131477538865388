import styled from '@emotion/styled';
import { color_blue, color_miami } from '@src/styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { ParagraphProps, SpanProps } from './rich-text.interface';

export const Paragraph = styled.p<ParagraphProps>`
  padding: ${getStyleSizes([0])};
  margin-bottom: ${getStyleSizes(10)};
  font-weight: ${({ isBold }) => (isBold ? '600' : '')};
  font-style: ${({ isItalic }) => (isItalic ? 'italic' : '')};
  text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : '')};
  & a {
    text-decoration: none;
    color: ${color_blue};
  }
`;

export const Ul = styled.ul`
  margin: 0;
  li::marker {
    color: ${color_miami.base};
  }
  li {
    & a {
      text-decoration: none;
      color: ${color_blue};
    }
  }
`;

export const Ol = styled.ol`
  margin: 0;
`;

export const Li = styled.li`
  padding: ${getStyleSizes(0)};
  margin-bottom: ${getStyleSizes(10)};
`;

export const Blockquote = styled.blockquote`
  font-size: ${getStyleSizes(18)};
  line-height: ${getStyleSizes(22)};
`;

export const H2 = styled.h2`
  font-family: Causten;
  font-weight: 400;
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(30)};
  &:not(:first-child) {
    margin-bottom: ${getStyleSizes(10)};
  }
`;

export const H3 = styled.h3`
  font-family: Causten;
  font-weight: 400;
  font-size: ${getStyleSizes(22)};
  line-height: ${getStyleSizes(26)};
  &:not(:first-child) {
    margin-bottom: ${getStyleSizes(10)};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: ${getStyleSizes(10)};
`;

export const Span = styled.span<SpanProps>`
  font-weight: ${({ isBold }) => (isBold ? '600' : '')};
  font-style: ${({ isItalic }) => (isItalic ? 'italic' : '')};
  text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : '')};
  ${({ isSuper }) =>
    isSuper &&
    `
    vertical-align: super;
    font-size: ${getStyleSizes(12)};
  `}
`;
