import React, { useCallback, useEffect, useState } from 'react';
// plugin ref: https://www.npmjs.com/package/@u-wave/react-vimeo
import PauseIcon from '@mui/icons-material/Pause';

import { ArrowIcon } from '@shared/components';

import { VideoPlayerProps } from '@shared/components/video-player/video-player.interface';
import {
  VideoPlayerPauseButtonStyled,
  VideoPlayerPlayButtonStyled,
  VideoPlayerStyled,
  VideoShadowStyled,
  VimeoStyled,
} from '@shared/components/video-player/video-player.styles';
import { useOutOfBounds } from '@shared/hooks/outOfBounds';
import { ModalHandler } from '../modal/modal';

export const VideoPlayer = ({
  id,
  height,
  mobileHeight,
  autoplay = false,
  fullwidth = false,
  playingAsPopup = false,
}: VideoPlayerProps) => {
  const [pause, setPause] = useState<boolean>(!autoplay);
  const [start, setStart] = useState<number>(0);
  const [componentRef, isOutOfBounds] = useOutOfBounds();
  const [showPause, setShowPause] = useState<boolean>(true);

  useEffect(() => {
    if (isOutOfBounds) {
      return setPause(true);
    }
    return;
  }, [isOutOfBounds]);

  const onVideoEnd = useCallback(() => {
    setStart(0);
    setPause(true);
  }, []);

  return (
    <VideoPlayerStyled
      paused={pause}
      data-testid="video-player__container"
      // @ts-ignore
      ref={componentRef}
    >
      <VideoShadowStyled
        fullwidth={fullwidth}
        onMouseLeave={() => setShowPause(false)}
        onMouseEnter={() => setShowPause(true)}
      >
        <VideoPlayerPlayButtonStyled
          className={'video-player__play'}
          onClick={() => {
            setPause(false);
            if (playingAsPopup) {
              ModalHandler.open({
                children: (
                  <VimeoStyled
                    width={'100%'}
                    video={id}
                    controls={true}
                    paused={pause}
                    showPortrait={true}
                    height={height || ''}
                    mobileHeight={mobileHeight}
                    onEnd={() => onVideoEnd()}
                    start={start}
                    playsInline={true}
                    autoplay={true}
                  />
                ),
                callbackOnCloseModal: () => {
                  ModalHandler.close();
                  setPause(true);
                },
                showCloseButton: true,
                size: {
                  width: 700,
                  height: 392,
                  unit: 'px',
                },
              });
            }
          }}
        >
          <ArrowIcon />
        </VideoPlayerPlayButtonStyled>
        {showPause && (
          <VideoPlayerPauseButtonStyled
            className={'video-player__pause'}
            onClick={() => setPause(true)}
          >
            <PauseIcon />
          </VideoPlayerPauseButtonStyled>
        )}
      </VideoShadowStyled>

      <VimeoStyled
        video={id}
        controls={false}
        paused={playingAsPopup ? true : pause}
        showPortrait={true}
        height={height || ''}
        mobileHeight={mobileHeight}
        onEnd={() => onVideoEnd()}
        start={start}
        playsInline={true}
        autoplay={autoplay}
      />
    </VideoPlayerStyled>
  );
};
