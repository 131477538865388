import React from 'react';

import { linkIsExternal } from '@shared/utilities/link.utils';
import { StyledLink, ExternalLink, StyledLinkText } from './basic-link.styles';
import { BasicLinkInterface } from '@shared/components/basic-link/basic-link.interface';

const BasicLink: React.FC<BasicLinkInterface> = (props): any => {
  const { url, text, isExternal, underline = false } = props || {};
  const hasChildren = !!props.children;
  const decoration = underline && !hasChildren ? 'underline' : 'none';

  const content = hasChildren ? props.children : text;

  if (!url || url === '') {
    return hasChildren ? content : <StyledLinkText>{content}</StyledLinkText>;
  }

  return isExternal || linkIsExternal(url) ? (
    <ExternalLink underline={decoration} href={url} target="_blank">
      {content}
    </ExternalLink>
  ) : (
    <StyledLink underline={decoration} to={url}>
      {content}
    </StyledLink>
  );
};

export default BasicLink;
