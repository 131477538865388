import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_miami,
  color_lightest_grey,
  global_colors,
} from '@styles/colors';

export const FormTitle = styled.h2`
  font-family: Causten;
  font-size: ${getStyleSizes(28)};
  font-weight: 400;
  margin-bottom: ${getStyleSizes(20)};
  color: ${global_colors.text.dark};
`;

export const ContactForm = styled.section`
  form {
    text-align: left;
    position: relative;
    width: 100% !important;
  }

  .mktoForm label.mktoLabel {
    display: none;
  }

  .mktoOffset,
  .mktoAsterix,
  .mktoClear,
  .mktoGutter,
  .mktoErrorArrowWrap {
    display: none !important;
  }
  .mktoFieldWrap,
  .mktoFormCol {
    float: none !important;
  }

  .mktoForm input[type='text'],
  .mktoForm input[type='url'],
  .mktoForm input[type='email'],
  .mktoForm input[type='tel'],
  .mktoForm input[type='number'],
  .mktoForm input[type='date'],
  .mktoForm select.mktoField,
  .mktoForm textarea.mktoField {
    font-family: Causten;
    font-size: ${getStyleSizes(18)};
    font-weight: 600;
    border: 1px solid transparent;
    background-color: ${color_lightest_grey} !important;
    padding: ${getStyleSizes([15, 20])} !important;
    box-shadow: none;
    border-radius: 4px;
    width: 100%;
    flex: 1;
    &::placeholder {
      font-weight: 400;
    }
    &.invalid.error {
      border: 1px solid ${global_colors.notification.error};
    }
  }

  button[type='submit'],
  .mktoButton {
    background: ${color_miami.base} !important;
    padding: ${getStyleSizes([15, 30])} !important;
    border: 0 !important;
    border-radius: 4px !important;
    display: block !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    text-transform: initial !important;
    margin-right: ${getStyleSizes(10)} !important;
    cursor: pointer !important;
  }

  .mktoForm .mktoError {
    position: absolute;
    right: 0 !important;
    top: 100% !important;
    bottom: auto !important;
    margin: 0 !important;
  }

  .mktoErrorMsg {
    font-family: Causten;
    font-size: ${getStyleSizes(12)};
    list-style: none;
    margin: 0 !important;
    background-image: none !important;
    background-color: transparent !important;
    color: ${global_colors.notification.error} !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border: 0 !important;
    max-width: 100% !important;
    padding: 0 !important;
  }

  .mktoFormRow .mktoErrorMsg {
    text-align: right;
  }

  .mktoButtonRow .mktoErrorMsg {
    text-align: left;
    margin-top: 10px !important;
  }

  .hs_error_rollup {
    position: relative;
  }
  .submitted-message {
    font-size: ${getStyleSizes(18)};
    font-style: italic;
  }

  .hs_recaptcha {
    margin-bottom: ${getStyleSizes(24)};
  }

  .mktoField:not([name='emailOptIn']) {
    width: 100% !important;
    float: none !important;
  }
  .mktoForm .mktoFormRow:not(:last-of-type) .mktoFieldWrap,
  .mktoCaptchaDisclaimer {
    margin-bottom: 20px;
  }

  .form-utm-container,
  .form-utm-container > fieldset {
    visibility: hidden;
    height: 0;
    margin: 0 !important;
    line-height: 0;
  }

  .mktoFormRow:nth-last-child,
  .mktoButtonRow {
    margin: 0 !important;
  }

  .mktoFormRow,
  .mktoButtonRow {
    width: 100%;
  }

  .mktoButtonWrap {
    margin: 0 !important;
  }

  .mktoValid {
    border: 1px solid transparent !important;
  }
  .mktoInvalid {
    border: 1px solid ${global_colors.notification.error} !important;
  }

  .mktoFormCol {
    width: 100%;
  }

  .form-select-container {
    display: flex !important;
    align-items: center;
    column-gap: 20px;
  }

  .form-select-container > label.mktoLabel {
    display: block !important;
    font-family: Causten !important;
    font-size: ${getStyleSizes(18)} !important;
    font-weight: 400 !important;
  }

  select {
    width: auto;
  }
`;
