import React from 'react';

import { HeroBannerContainer } from '@cms-components/hero-banner/hero-banner.styles';
import { BannerStandard, BannerNeedles, BannerTrialworks } from './components';

export const HeroBannerSPZ = (props: any) => {
  const bannerTemplate = props?.template?.toLowerCase();
  
  const ComponentRenderer = () => {
    switch(bannerTemplate){
      case 'needles':
        return <BannerNeedles {...props} />;
      case 'trialworks':
        return <BannerTrialworks {...props} />;
      default:
        return <BannerStandard {...props} />;
    }
  };

  return (
    <HeroBannerContainer
      id={props.uid}
      heroType={bannerTemplate}
      data-testid="hero-banner__container"
    >
      {ComponentRenderer()}
    </HeroBannerContainer>
  );
};

export const CMSHeroBannerSPZ = (props: any) => {
  return <HeroBannerSPZ {...props} />;
};
