import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { LPHeader } from '@shared/contentstack';

export const LivePreviewHeader = () => {
  return (
    <>
      <CMSComponentWrapper maxWidth={1240} backgroundColor={'#000000'}>
        <LPHeader>
          <h3>Live Preview</h3>
          <img
            alt="Assembly Logo"
            src="https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt25a57f5a7357f86e/62a0ca1cc949fd5059e88800/Assembly-logo-light-01.png?width=125&height=27&format=png&quality=90"
          />
        </LPHeader>
      </CMSComponentWrapper>
    </>
  );
};
