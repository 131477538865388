import { styled } from '@mui/material/styles';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { AccordionLayoutProps } from '../../accordion.interface';

import { MuiTabsProps  } from '../../accordion.interface';

import {
    color_black,
    color_dark_grey,
    color_text_grey,
    color_darkest_grey,
    color_white,
    color_concrete,
    color_miami,
  } from '@styles/colors';

export const variants = {
    light: {
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    mid: {
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    dark: {
      headlineColor: color_white,
      detailColor: color_text_grey,
      iconColor: color_white,
    },
};

export const MuiTabsContainerStyled = styled(Box)`
  margin: 1px auto 0px; 
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(17)};
  }
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(0)};
  }
  .MuiTabs-scroller{
    padding-left: ${getStyleSizes(24)};
    ${mq_min('md')} {
      padding-left: ${getStyleSizes(0)};
      padding-right: 0;
    }
    ${mq_min('lg')} {
      padding-right: ${getStyleSizes(32)};
    }
  }
`
export const MuiTabsStyled = styled(Tabs)`
  width: 100%;

  .MuiTabs-scroller{
    border-bottom: 1px solid #E8EBF2;
    margin-bottom: 2px !important;
  }
`

export const MuiTabStyled = styled(Tab)`
  min-width: ${getStyleSizes(152)};
  color: ${color_concrete.base};
  font-size: ${getStyleSizes(20)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(30)};
  text-transform: capitalize;
  height: ${getStyleSizes(45)};
  min-height: 0;
  padding: ${getStyleSizes([10,0,22])};
  
  &.Mui-selected{
    color: ${color_black};
    &:after{
      content: '';
      height: 3px;
      width: 100%;
      display: block;
      bottom: 0;
      left: 0;
      background: ${color_black};
      position: absolute;
    }
  }
  ${mq_min('md')} {
    min-width: ${getStyleSizes(248)};
  }
`
export const MuiTabsContentPanelStyled = styled('div') <MuiTabsProps & AccordionLayoutProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top:  ${getStyleSizes(20)};
  flex-wrap: wrap;
  ${({ template }) => (template === 'needles' || template === 'trialworks') && `
    flex-direction: column-reverse;
  `}
  ${mq_min('lg')} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  ${({ hidden }) => hidden && `
    max-height: 0px;
    overflow: hidden;
    padding: 0px;
  `}
`
export const MuiTabsContentStyled = styled('div')<AccordionLayoutProps>`
  width: 100%; 
  ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
    padding: ${getStyleSizes([0,20,0])};
  ` : `
    padding: ${getStyleSizes([24,49,48])};
  `}
  ${mq_min('md')} {
    ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
      padding: ${getStyleSizes([0,20,0])};
    ` : `
      padding: ${getStyleSizes([39,49,51,82])};
    `}
  }
  ${mq_min('lg')} {
    width: 50%;
    padding-top: auto;
    padding: ${getStyleSizes([64,0,50,50])};
    max-width: ${getStyleSizes(550)};
    ${({ template }) => (template === 'needles' || template === 'trialworks') && `
      text-align: left;
    `}
  }
`
export const MuiTabsHeadlineStyled = styled('h1')<AccordionLayoutProps>`
  color: ${color_black};
  font-style: normal;
  font-weight: 600;
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(31.2)};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(32)};
    line-height: ${getStyleSizes(38.4)};
  }
`
export const MuiTabsCopyStyled = styled('div')<AccordionLayoutProps>`
  color: ${color_dark_grey};
  font-style: normal;
  font-weight: 400;
  margin: ${getStyleSizes([24, 0, -5])};
  ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
    font-size: ${getStyleSizes(16)};
    line-height: ${getStyleSizes(22)};
    div[data-testid="rich-text__container"]{
      p{
        margin-bottom: ${getStyleSizes(24)};
      }
    }
    ${mq_min('md')} {
      font-size: ${getStyleSizes(18)};
      line-height: ${getStyleSizes(27)};
    }
  ` : `
    font-size: ${getStyleSizes(18)};
    line-height: ${getStyleSizes(27)};
    div[data-testid="rich-text__container"]{
      p{
        margin-bottom: ${getStyleSizes(28)};
      }
    }
  `}
`
export const MuiTabsImageContentStyled = styled('div')`
  width: 100%;
  ${mq_min('lg')} {
    width: 50%;
  }
`
export const MuiTabsImageStyled = styled('img')`
  width: 100%;
  transition: 0.3s opacity cubic-bezier(0.22, 1, 0.36, 1);
`
export const MuiTabsActiveIndicatorStyled = styled('div')<MuiTabsProps>`
  height: 3px;
  background: ${color_miami.base};
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ width }) => width}%;
  display: block;
  margin-bottom: 0 !important;
  z-index: 1;
  transition: width 0.2s linear;
`

export const CTAContainer = styled('div')<AccordionLayoutProps>`
  ${({ template }) => (template === 'needles') && `
    span{
      margin: 0!important;
    }
    svg{
      display: none !important;
    }
  `};
`