import { base_font_pixel_size } from './typography';
import { border_defaults } from './settings';

export const getEmRemFromPixel = (value: number, unit: string = 'rem') => `${value / base_font_pixel_size}${unit}`;

export type styleSizeType = string | number;
export type styleSizeInput = styleSizeType | [styleSizeType, styleSizeType?, styleSizeType?, styleSizeType?];

/** Build CSS size property:
 * @param sizes -- provide array of pixel sizes (minus 'px) (or 'auto'/'inherit').
 * @param unit -- provide: ['em', 'rem']?. Default: 'rem'
 * @summary pixel sizes will be converted to em or rem
 * @returns: CSS size property string in em or rem as a string:
 *  > [single value] 1em;
 *  > [vertical/horizontal values] 1em 2em;
 *  > [top horizontal bottom values] 1em 2em 1.5em;
 *  > [top right bottom left values] 1em 2em 1.5em 2em;
 */
export const getStyleSizes = (sizes: styleSizeInput = base_font_pixel_size, unit: string = 'rem'): string => {
  const arr = Array.isArray(sizes) ? sizes : [sizes];
  return arr
    .slice(0, 4)
    .map((size: string | number | undefined) => (typeof size === 'number' ? getEmRemFromPixel(size, unit) : size))
    .join(' ');
};

/** Build CSS border property:
 *
 * @param width provide pixel size (minus 'px)
 * @param style provide the border style
 * @param color provide a border color
 * @summary pixel sizes will be converted to em or rem
 * @returns: CSS border property value as a string
 *  [width style color]: 1px solid #000000
 */
export const getStyleBorder = (
  width: number = border_defaults.width,
  style: string = border_defaults.style,
  color: string = border_defaults.color.dark
): string => `${getEmRemFromPixel(width, 'rem')} ${style} ${color}`;



export const getLinearGradient = (startColor: string, endColor: string, changeAt: number) => {
  return `linear-gradient(180deg, ${startColor} 0%, ${startColor} ${changeAt}%, ${endColor} ${changeAt}%, ${endColor} 100%);`;
}
