import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { getStyleSizes } from '@styles/styles.utils';

export const ButtonLabel = styled.span<{ weight: number }>`
  display: block;
  font-size: inherit;
  font-weight: ${(props) => props.weight};
  text-decoration: none;
  text-transform: initial;
  margin-right: ${getStyleSizes(10)};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
