import styled from '@emotion/styled';
import { css } from '@mui/material';
import { causten_font } from '@src/styles/typography';
import { mq_min, mq_max } from '@styles/breakpoints';
import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
} from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import {
  FeatureCardContainerProps,
  HeadLineFeatureProps,
  InnerProps,
  StyledHeadProps,
  TeaserTextFeatureProps,
} from './feature-cards.interface';

export const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    descriptionColor: color_dark_grey,
    linkColor: color_darkest_grey,
  },
  mid: {
    containerBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
    descriptionColor: color_dark_grey,
    linkColor: color_darkest_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    descriptionColor: '#EFF2F8',
    linkColor: color_white,
  },
};

export const FeatureCardContainer = styled.div<FeatureCardContainerProps>`
  background: ${({ background }) =>
    !!background
      ? themes[background]?.containerBackground
      : themes['light']?.containerBackground};
`;

export const Inner = styled.div<InnerProps>`
  overflow-x: auto;
  .swiper {
    &.withArrows {
      .swiper-button-prev,
      .swiper-button-next {
        ${mq_min('lg')} {
          display: block;
        }
      }
      ${mq_min('lg')} {
        padding-top: ${getStyleSizes(100)};
      }
    }

    .swiper-pagination {
      display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    ${mq_min('lg')} {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        top: ${getStyleSizes(25)};
        left: ${getStyleSizes(100)};
        &:after {
          display: none;
        }
      }
      .swiper-button-next {
        left: ${getStyleSizes(150)};
      }
    }
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide {
    img {
      border-radius: ${getStyleSizes(4)};
    }
  }
`;

export const FeatureContainer = styled.div`
  padding: ${getStyleSizes([40, 0])};
`;

export const HeadLineFeature = styled.h3<HeadLineFeatureProps>`
  font-weight: 400;
  color: ${({ background }) =>
    !!background
      ? themes[background]?.headlineColor
      : themes['light']?.headlineColor};
  font-family: ${causten_font};
  font-size: ${getStyleSizes(34)};
  line-height: ${getStyleSizes(36)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
    line-height: ${getStyleSizes(48)};
  }
`;

export const StyledButton = styled.div`
  ${mq_min('lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledHead = styled.div<StyledHeadProps>`
  ${({ withButton }) =>
    withButton &&
    `
    ${mq_max('lg')} {
      text-align: center;
    }
  `}

  ${({ withTeaserText }) =>
    withTeaserText
      ? css`
          display: flex;
          flex-direction: column;
          ${mq_min('lg')} {
            align-items: center;

            ${StyledButton} {
              margin-top: ${getStyleSizes(20)};
            }
          }
          ${StyledButton} {
            margin-bottom: ${getStyleSizes(20)};
          }
          ${TeaserTextFeature} {
            margin-bottom: ${getStyleSizes(20)};
            ${mq_min('lg')} {
              margin-bottom: ${getStyleSizes(12)};
            }
          }
        `
      : css`
          margin-bottom: ${getStyleSizes(25)};
          ${mq_min('lg')} {
            margin: ${getStyleSizes([0, 100, 20, 100])};
            display: flex;
            justify-content: space-between;
            ${HeadLineFeature} {
              width: ${getStyleSizes(525)};
            }
          }
        `}
`;

export const TeaserTextFeature = styled.p<TeaserTextFeatureProps>`
  font-family: NoToSans;
  font-size: ${getStyleSizes(18)};
  text-align: center;
  color: ${({ background }) =>
    !!background && themes[background]?.headlineColor};
  line-height: ${getStyleSizes(26)};
  margin: ${getStyleSizes([12, 0, 12, 0])};

  ${mq_min('lg')} {
    line-height: ${getStyleSizes(32)};
    margin: ${getStyleSizes([12, 0, 12, 0])};
  }
`;

export const StyledBottomButton = styled(StyledButton)`
  margin-top: ${getStyleSizes(20)};
`;
