import React from 'react';
import { default_breakpoints } from '@styles/breakpoints';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { PageRteWrapper } from './page-rte.styles';

export const PageRte = (data: any) => {
  const { rich_text_editor } = data || {};
  if (rich_text_editor) {
    return (
      <>
        <CMSComponentWrapper maxWidth={default_breakpoints.md}>
          <PageRteWrapper
            dangerouslySetInnerHTML={{ __html: rich_text_editor }}
          />
        </CMSComponentWrapper>
      </>
    );
  }
  return <></>;
};
