import React from 'react';
import { AccordionProps } from './accordion.interface';
// Styles
import {
  AccordionStyled,
  AccordionTitleStyled,
} from './accordion.styles';
import { VerticalAccordion, HorizontalAccordion } from './components';

export const FeaturesAccordion = ({
  variant = 'light',
  itemList,
  headline,
  title,
  summarySize = 'normal',
  additionalInfo,
  layout,
  template
}: AccordionProps) => {

  const AccordionTypeComponent = () =>{
    const props = {
      variant,
      itemList,
      summarySize,
      additionalInfo,
      layout,
      template
    }
    switch(layout){
      case 'Vertical':
        return <VerticalAccordion {...props} />;
      case 'Horizontal':
        return <HorizontalAccordion {...props} />;
      default: 
        return <VerticalAccordion {...props}/>;
    }
  }
  
  if (itemList.length) {
    return (
      <>
        {title && (
          <AccordionTitleStyled 
            variant={variant} 
            template={template?.toLocaleLowerCase()}
          >{headline}</AccordionTitleStyled>
        )}
        <AccordionStyled 
          data-testid="accordion__wrapper" 
          template={template?.toLocaleLowerCase()} 
          layout={layout}
        >
          {AccordionTypeComponent()}
        </AccordionStyled>
      </>
    );
  }

  return <></>;
};