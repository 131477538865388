import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

export type PageHeaderStyleType = 'light' | 'dark';
export type PageHeaderStyleProps = {
  current: PageHeaderStyleType;
};

const pageHeaderStyleDefaultProps: PageHeaderStyleProps = {
  current: 'light',
};

const PageHeaderStyleContext = createContext(pageHeaderStyleDefaultProps);

/**
 * This context provider has been added in: src/templates/base-layout/base-layout.tsx
 * @param children - Render React Nodes
 * @param color - value to initialize the color provider. ('light' or 'dark')
 * @constructor
 */
const PageHeaderStyleProvider = ({
  children,
  color,
}: PropsWithChildren<{ color: PageHeaderStyleType }>) => {
  const [current, setCurrent] = useState<PageHeaderStyleType>('light');

  useEffect(() => {
    setCurrent(color);
    return;
  }, [color]);

  return (
    <PageHeaderStyleContext.Provider value={{ current }}>
      {children}
    </PageHeaderStyleContext.Provider>
  );
};

/**
 * This is a Custom Hook to share the state initialized on the Context Provider
 * Add this inside the component that needs access to the color state
 * const { currentColor } = usePageHeaderStyle();
 * ref: src/cms-components/header/components/main-nav/main-nav.tsx
 */
const usePageHeaderStyle = () => {
  const { current } = useContext(PageHeaderStyleContext);

  if (!current) {
    throw new Error('You need the PageHeaderStyleProvider');
  }

  return {
    currentColor: current,
  };
};

export { PageHeaderStyleProvider, usePageHeaderStyle };
