import styled from '@emotion/styled';
import { getStyleSizes } from '../../styles/styles.utils';
import { FeatureCardContainerProps } from '../feature-cards/feature-cards.interface';
import {
  color_dark_grey,
  color_darkest_grey,
  color_eggshell,
  color_white,
} from '@styles/colors';
import { mq_min } from '../../styles/breakpoints';
import {
  LayoutProps,
  TechStackContainerProps,
  TechStackImageProps,
} from './tech-stack.interface';

const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  mid: {
    containerBackground: color_eggshell.base,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    promoTextColor: '#EFF2F8',
  },
};

export const TechStackContainer = styled.div<TechStackContainerProps>`
  padding: ${getStyleSizes([80, 0])};
  text-align: center;
  background: ${({ background_color, background }) =>
    background_color || themes[background]?.containerBackground};
  background-image: ${({ background_image }) =>
    `url(${background_image})` || ''};
  background-size: cover;
  background-position: center left 20%;
  ${mq_min('lg')} {
    padding:  ${getStyleSizes([100, 0])};
    background-position: center;
  }
`;

export const TechStackTitle = styled.h1<LayoutProps>`
  font-size: ${getStyleSizes(32)};
  font-weight: 600;
  color: ${color_white};
  margin-bottom: ${getStyleSizes(46)};
  line-height: ${getStyleSizes(38.4)};
  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(64)};
    ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
      font-size: ${getStyleSizes(36)};
      line-height: ${getStyleSizes(44)};
    ` : `
      font-size: ${getStyleSizes(40)};
      line-height: ${getStyleSizes(48)};
    `}
  }
`;

export const TechContainerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${getStyleSizes([0, 12])};
  ${mq_min('lg')} {
    padding: ${getStyleSizes(0)};
  }
`;

export const TechStackImage = styled.img<TechStackImageProps>`
  content: url(${({ mobile_image }) => mobile_image});
  width: 100%;
  object-fit: contain;
  height: ${getStyleSizes(358)};
  ${({template}) =>
    (template === 'needles' || template === 'trialworks') ? `
    height: auto;
  ` : `
  `};
  ${mq_min('md')} {
    content: url(${({ tablet_image }) => tablet_image});
    height: ${getStyleSizes(145)};

    ${({template}) =>
      (template === 'needles' || template === 'trialworks') ? `
      height: auto;
    ` : `
    `};
  }
  ${mq_min('lg')} {
    content: url(${({ desktop_image }) => desktop_image});
    height: ${getStyleSizes(172)};
  }
`;

export const CTAContainer = styled('div')`
  text-align: center;
  margin-top: ${getStyleSizes(48)};
  ${mq_min('lg')} {
  margin-top: ${getStyleSizes(64)};
  }
`
