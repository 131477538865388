import React, { useState } from 'react';

import { Snackbar } from '@mui/material';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';

import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  MailIcon,
  LinkIcon,
} from '@shared/components';

import { isBrowser } from '@shared/utilities';
import { global_colors } from '@styles/colors';

import {
  SocialNetworksInner,
  TextShare,
  LinkContainer,
  Link,
} from './social-networks.styles';
import CMSComponentWrapper from '@shared/components/component-wrapper';

export const SocialNetworks = () => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const pageLink = isBrowser() ? window.location.href : '/';

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onLinkCopied = () => {
    navigator.clipboard.writeText(pageLink).then(() => setOpenSnackbar(true));
  };

  return (
    <CMSComponentWrapper maxWidth={836}>
      <SocialNetworksInner>
        <TextShare>SHARE</TextShare>
        <LinkContainer
          className="fb-share-button"
          data-href={pageLink}
          data-layout="button"
          data-size="small"
        >
          <FacebookShareButton url={pageLink}>
            <FacebookIcon
              sx={{
                fontSize: 20,
                color: global_colors.socialNetwork.background,
              }}
            />
          </FacebookShareButton>
          <LinkedinShareButton url={pageLink}>
            <LinkedinIcon
              sx={{
                fontSize: 20,
                color: global_colors.socialNetwork.background,
              }}
            />
          </LinkedinShareButton>
          <TwitterShareButton url={pageLink}>
            <TwitterIcon
              sx={{
                fontSize: 20,
                color: global_colors.socialNetwork.background,
              }}
            />
          </TwitterShareButton>
          <EmailShareButton url={pageLink}>
            <MailIcon
              sx={{
                fontSize: 20,
                color: global_colors.socialNetwork.background,
              }}
            />
          </EmailShareButton>
          <Link onClick={onLinkCopied}>
            <LinkIcon
              sx={{
                fontSize: 20,
                color: global_colors.socialNetwork.background,
              }}
            />
          </Link>
        </LinkContainer>
      </SocialNetworksInner>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message="Page link copied"
        autoHideDuration={2000}
      />
    </CMSComponentWrapper>
  );
};
