import React from 'react';
import { IconLink, ImageComponent } from '@src/shared/components';
import {
  Container,
  Image,
  Topics,
  Headline,
  Teaser,
  Detail,
  ItemDate,
} from './lc-article-tile.styles';
import { LCArticleTileProps } from './lc-article-tile.interface';

export const LCArticleTile = ({
  uid,
  image,
  topics,
  headline,
  headlineLink,
  teaser,
  publicationDate,
  urlSegment,
  logoCard,
  suppressImages = false,
}: LCArticleTileProps) => {
  const imageSelected = image.image ? image : logoCard.image;
  const isFallbackImage = !image.image && logoCard?.image?.image;

  return (
    <Container id={uid} data-testid="lc-article-tile__container">
      {imageSelected && imageSelected.image && !suppressImages && (
        <Image
          objectFit={isFallbackImage ? 'contain' : 'cover'}
          backgroundColor={isFallbackImage ? logoCard?.background || '' : ''}
        >
          <ImageComponent
            image={imageSelected.image.gatsbyImageData}
            alt_text={imageSelected.image_alt_text}
          />
        </Image>
      )}

      <Detail hasImage={Boolean(image)}>
        <Topics>{topics.join(', ')}</Topics>
        <Headline>
          <IconLink isBold label={headline} linkUrl={headlineLink} />
        </Headline>
        <Teaser hasImage={Boolean(image)}>{teaser}</Teaser>

        {urlSegment?.indexOf('blogs') !== -1 && publicationDate && (
          <ItemDate>{publicationDate}</ItemDate>
        )}
      </Detail>
    </Container>
  );
};
