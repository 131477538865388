import React from 'react';

import { FooterLink } from '../../footer.interface';
import {
  FooterLinkSection,
  FooterLinkList,
  FooterLinkItem,
  FooterLinkListItem,
  FooterLinkHeader,
} from './footer-links.styles';

export const FooterLinks: React.FC<FooterLink> = ({
  menuSection,
  linkList,
}) => {
  return (
    <FooterLinkSection>
      <header>
        <FooterLinkHeader>{menuSection}</FooterLinkHeader>
      </header>
      <FooterLinkList>
        {linkList.map(({ text, url, isExternal }, index) => (
          <FooterLinkListItem key={`item-${index}`}>
            <FooterLinkItem url={url} text={text} isExternal={isExternal} />
          </FooterLinkListItem>
        ))}
      </FooterLinkList>
    </FooterLinkSection>
  );
};
