import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { ContactFormBaseline } from '@shared/components/form-panel-baseline';
import { CSFormPanelBaseline } from '@shared/contentstack';
import { RichText } from '@src/shared/components';
import { hasContent } from '@src/shared/utilities/string.utils';
import BasicLink from '@shared/components/basic-link';
import { CMSFooter } from '@cms-components/footer';
import { 
    FormBaselineContainer,
    FormPanelContainer,
    FormPanelFormStyled,
    FormPanelCopyStyled,
    PageHeadlineStyled,
    LogoContainer,
    FooterStyled,
    FormInner
 } from './form-baseline.styles';

export const FormBaseline = ({
  form_copy, 
  form, 
  headline,
  form_logo,
  large_screen_background,
  desktop_background,
  tablet_background,
  mobile_background
}: CSFormPanelBaseline) => {

  const isValidFormConfig: boolean =
    !!form?.base_url && !!form.form_id && !!form.tracking_code;

  return (
    <>
    <FormBaselineContainer 
      large_screen_background={large_screen_background?.image?.url}
      desktop_background={desktop_background?.image?.url}
      tablet_background={tablet_background?.image?.url}
      mobile_background={mobile_background?.image?.url}
    >
      {form_logo && (
        <LogoContainer>
          <BasicLink url="/">
            <img 
              src={form_logo?.image.url}
              alt={form_logo?.image_alt_text}
            />
            </BasicLink>
          </LogoContainer>
      )}
      <PageHeadlineStyled>{headline}</PageHeadlineStyled>
      <CMSComponentWrapper
        horizontalPadding={20}
      >
        <FormInner>
          <FormPanelContainer>
            <FormPanelFormStyled>
              {isValidFormConfig ? (
                <ContactFormBaseline
                  base_url={form?.base_url}
                  title={form?.form_title}
                  success_message={form?.success_message}
                  tracking_code={form?.tracking_code}
                  form_id={form?.form_id}
                />
              ) : (
                <span>Please provide a form ID, base URL and tracking code</span>
              )}
            </FormPanelFormStyled>
            {hasContent(form_copy?.children) && (
              <FormPanelCopyStyled
                id={form_copy.uid}
              >
                <RichText content={form_copy} />
              </FormPanelCopyStyled>
            )}
          </FormPanelContainer>
        </FormInner>
      </CMSComponentWrapper>
    </FormBaselineContainer>
    <FooterStyled>
        <CMSFooter />
    </FooterStyled>
    </>
  );
};
