import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_white,
  color_miami,
  color_text_grey,
} from '@styles/colors';

export const Container = styled.div`
  overflow: hidden;
  ${mq_min('lg')} {
    padding-bottom: ${getStyleSizes(60)};
  }
`;

export const Inner = styled.div`
  padding: ${getStyleSizes([24, 0])};
  background: ${color_darkest_grey};

  .swiper {
    overflow: initial;

    .swiper-pagination {
      position: relative;
      text-align: left;
      bottom: 0;
      margin-top: ${getStyleSizes(32)};

      span {
        background: ${color_text_grey};
      }

      .swiper-pagination-bullet-active {
        background: ${color_miami.base};
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  .swiper-slide {
    width: auto;
  }

  ${mq_min('lg')} {
    padding: ${getStyleSizes([54, 0, 0, 0])};

    .swiper-slide {
      width: 100%;
      bottom: ${getStyleSizes(-60)};
    }

    .swiper {
      .swiper-pagination {
        position: absolute;
        left: ${getStyleSizes(580)};
        bottom: ${getStyleSizes(-44)};
        margin: 0;
        width: auto;

        span {
          opacity: 1;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: block;
        width: auto;
        top: auto;
        bottom: ${getStyleSizes(20)};
        right: auto;

        &:after {
          content: '';
        }
      }

      .swiper-button-prev {
        left: ${getStyleSizes(580)};
      }
      .swiper-button-next {
        left: ${getStyleSizes(632)};
      }
    }
  }

  ${mq_min('xl')} {
    .swiper {
      .swiper-pagination {
        left: ${getStyleSizes(780)};
      }

      .swiper-button-prev {
        left: ${getStyleSizes(780)};
        bottom: ${getStyleSizes(60)};
      }
      .swiper-button-next {
        left: ${getStyleSizes(832)};
        bottom: ${getStyleSizes(60)};
      }

      .swiper-slide-prev {
        h2 {
          display: none;
        }
      }
    }
  }
`;

export const Item = styled.div`
  width: ${getStyleSizes(324)};
  margin-right: ${getStyleSizes(24)};

  &:last-of-type {
    margin-right: 0;
  }

  ${mq_min('lg')} {
    width: initial;
    display: flex;
    align-items: end;
  }
`;

export const ItemImage = styled.div`
  width: ${getStyleSizes(324)};
  height: ${getStyleSizes(174)};
  border-radius: 4px;
  flex: 0 0 ${getStyleSizes(324)};

  ${mq_min('lg')} {
    width: ${getStyleSizes(550)};
    height: ${getStyleSizes(300)};
    flex: 0 0 ${getStyleSizes(550)};
  }

  ${mq_min('xl')} {
    width: ${getStyleSizes(720)};
    height: ${getStyleSizes(386)};
    flex: 0 0 ${getStyleSizes(720)};
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const ItemDetail = styled.div`
  ${mq_min('lg')} {
    padding: ${getStyleSizes([0, 0, 148, 30])};
  }

  ${mq_min('xl')} {
    padding: ${getStyleSizes([0, 0, 200, 60])};
  }
`;

export const ItemTopics = styled.div`
  font-size: ${getStyleSizes(12)};
  font-weight: 600;
  color: ${color_miami.base};
  margin-top: ${getStyleSizes(24)};
  text-transform: uppercase;

  ${mq_min('lg')} {
    margin-top: 0;
  }
`;

export const ItemHeadline = styled.h2`
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(32)};
  color: ${color_white};
  font-weight: 400;
  margin-top: ${getStyleSizes(14)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
    line-height: ${getStyleSizes(40)};
  }
`;
