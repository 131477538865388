import React from 'react';

import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import { RichText, SimpleHeader, ImageComponent } from '@shared/components';

import CMSComponentWrapper from '@shared/components/component-wrapper';

import { default_breakpoints } from '@styles/breakpoints';
import { getLinearGradient } from '@styles/styles.utils';
import {
  background_color_mapper,
  text_color_mapper,
  eyebrow_color_mapper,
} from '@styles/colors';
import { CSHeroBannerFormProps } from '@src/shared/contentstack';

import { mappedFormBannerData } from './mappers/banner-form.mapper';
import * as Styled from './banner-form.styles';
import { ContactForm } from '@src/shared/components/contact-form';

export const BannerForm = (props: CSHeroBannerFormProps) => {
  const {
    banner_background_color,
    secondary_background_color,
    make_headline_h1,
    banner_copy,
    form,
    eyebrow,
    headline,
    logo,
  } = mappedFormBannerData(props);

  const hasLogo = logo?.image;

  const isDesktop = useBreakpoint(up('md')) || true;
  const isValidFormConfig: boolean =
    !!form?.base_url && !!form.form_id && !!form.tracking_code;
  const secondaryBackgroundColor = isDesktop
    ? secondary_background_color
    : banner_background_color;

  const textColor = text_color_mapper[banner_background_color || 'dark'];
  const eyebrowColor = eyebrow_color_mapper[banner_background_color || 'dark'];

  return (
    <CMSComponentWrapper
      maxWidth={default_breakpoints.cw}
      backgroundColor={getLinearGradient(
        background_color_mapper[banner_background_color || 'dark'],
        background_color_mapper[secondaryBackgroundColor || 'dark'],
        80
      )}
      data-testid="banner-form__container"
    >
      <Styled.FormBannerContainer>
        <Styled.FormBannerContent color={textColor}>
          {hasLogo && (
            <Styled.BannerFormLogo>
              <ImageComponent
                image={logo.image.gatsbyImageData}
                fallback={logo.image.url}
                alt_text={logo.image_alt_text || ''}
              />
            </Styled.BannerFormLogo>
          )}

          {eyebrow && (
            <Styled.BannerFormEyebrow color={eyebrowColor}>
              {eyebrow}
            </Styled.BannerFormEyebrow>
          )}

          {headline && (
            <SimpleHeader text={headline} isH1={Boolean(make_headline_h1)} />
          )}

          {banner_copy && (
            <Styled.BannerFormCopy>
              <RichText content={banner_copy} />
            </Styled.BannerFormCopy>
          )}
        </Styled.FormBannerContent>
        <div>
          <Styled.FormBannerForm>
            {isValidFormConfig ? (
              <ContactForm
                base_url={form?.base_url}
                title={form?.form_title}
                success_message={form?.success_message}
                tracking_code={form?.tracking_code}
                form_id={form?.form_id}
              />
            ) : (
              <span>Please provide a form ID, base URL and tracking code</span>
            )}
          </Styled.FormBannerForm>
        </div>
      </Styled.FormBannerContainer>
    </CMSComponentWrapper>
  );
};
