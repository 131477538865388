import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getStyleBorder, getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

import {
  color_black,
  color_dark_grey,
  color_text_grey,
  color_darkest_grey,
  color_white,
} from '@styles/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
  AccordionDetailTextStyledProps,
  AccordionSummaryTextStyledProps,
  MuiAccordionSummaryStyledProps,
  VariantProp,
} from '@shared/components/accordion/accordion.interface';

export const variants = {
  light: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  mid: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  dark: {
    headlineColor: color_white,
    detailColor: color_text_grey,
    iconColor: color_white,
  },
};

export const AccordionStyled = styled('div')``;

export const AccordionTitleStyled = styled('div')<VariantProp>`
  background-color: transparent;
  font-size: ${getStyleSizes(12)};
  color: ${({ variant }) => variants[variant]?.headlineColor};
  margin-bottom: ${getStyleSizes(26)};
  text-transform: uppercase;
`;

export const MuiAccordionStyled = styled(MuiAccordion)`
  background-color: transparent;
  border: 0;

  &:before {
    display: none;
  }

  &:last-child {
    border-bottom: ${getStyleBorder()};
  }
`;

export const MuiAccordionSummaryStyled = styled(
  MuiAccordionSummary
)<MuiAccordionSummaryStyledProps>`
  padding-left: 0;
  padding-right: 0;
  border-top: ${getStyleBorder()};

  .MuiAccordionSummary-content {
    margin: ${({ size }) =>
      size === 'large' ? getStyleSizes([28, 0]) : getStyleSizes([18, 0])};
  }
`;

export const MuiAccordionDetailsStyled = styled(MuiAccordionDetails)`
  padding: ${getStyleSizes([0, 0, 20, 0])};
  border-top: 0;
`;

export const AccordionSummaryTextStyled = styled(
  'div'
)<AccordionSummaryTextStyledProps>`
  letter-spacing: ${({ size }) => (size === 'large' ? getStyleSizes(-1.5) : 0)};
  line-height: 1;
  font-weight: 600;
  font-size: ${({ size }) => getStyleSizes(size === 'large' ? 26 : 18)};
  color: ${({ variant }) => variants[variant]?.headlineColor};

  ${mq_min('lg')} {
    ${({ size }) =>
      size === 'large' &&
      `
      font-size: ${getStyleSizes(28)};
    `}
  }
`;

export const AccordionDetailTextStyled = styled(
  'div'
)<AccordionDetailTextStyledProps>`
  font-size: ${({ size }) =>
    size === 'large' ? getStyleSizes(16) : getStyleSizes(14)};
  line-height: ${getStyleSizes(20)};
  padding-right: 10%;
  color: ${({ variant }) => variants[variant]?.detailColor};
`;

export const ExpandMoreIconStyled = styled(ExpandMoreIcon)<VariantProp>`
  color: ${({ variant }) => variants[variant]?.iconColor};
`;

export const AccordionAddtionalInfoStyled = styled('div')`
  border-top: ${getStyleBorder()};
  padding-top: ${getStyleSizes(34)};
`;

export const AccordionAdditionalInfoTitleStyled = styled(
  AccordionTitleStyled
)<VariantProp>`
  color: ${({ variant }) => variants[variant]?.headlineColor};
  font-weight: 600;
`;

export const AccordionAddtionalInfoContentStyled = styled('div')<VariantProp>`
  &,
  a {
    color: ${({ variant }) => variants[variant]?.detailColor};
  }
  * {
    padding: 0;
  }
`;
