import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
  color_blue,
} from '@styles/colors';

import { VariantProp, ListTypeProp } from './list-panel.interface';
import { global_typography } from '@src/styles/typography';

export const themes = {
  light: {
    containerPanelBackground: color_white,
    headlineColor: color_darkest_grey,
    eyebrowColor: color_blue,
    description: color_dark_grey,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
    eyebrowColor: color_blue,
    description: color_darkest_grey,
  },
  dark: {
    containerPanelBackground: color_darkest_grey,
    headlineColor: color_white,
    eyebrowColor: color_white,
    description: color_white,
  },
};

export const ListPanelContainer = styled.div<VariantProp>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ variant }) =>
    themes[variant || 'light']?.containerPanelBackground};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};
  }
`;

export const ListPanelInner = styled.div`
  ${mq_min('lg')} {
    display: flex;
    flex-direction: row;
  }
`;

export const ListPanelDetail = styled.div<ListTypeProp>`
  margin-bottom: ${getStyleSizes(24)};

  ${mq_min('lg')} {
    flex-basis: ${({ listType }) =>
      getStyleSizes(listType === 'faq' ? 560 : 460)};
    padding-right: ${getStyleSizes(100)};
  }
`;

export const ListPanelContent = styled.div<ListTypeProp>`
  ${mq_min('lg')} {
    flex-basis: calc(
      100% - ${({ listType }) => getStyleSizes(listType === 'faq' ? 560 : 460)}
    );
  }
`;

export const Eyebrow = styled.p<VariantProp>`
  font-family: Causten;
  font-size: ${getStyleSizes(16)};
  font-weight: 600;
  margin-bottom: ${getStyleSizes(20)};
  color: ${({ variant }) => themes[variant || 'light']?.eyebrowColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const Headline = styled.h3<VariantProp>`
  font-family: Causten;
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(32)};
  font-weight: 400;
  color: ${({ variant }) => themes[variant || 'light']?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
    line-height: ${getStyleSizes(40)};
  }
`;

export const Description = styled.div<VariantProp>`
  font-family: NoToSans;
  font-size: ${getStyleSizes(global_typography.copy.base)};
  font-weight: 200;
  color: ${({ variant }) => themes[variant || 'light']?.description};
  margin-top: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(36)};
  }
`;
