// LOGO COLORS
import { LogoColors } from './styles.interface';
import {
  color_logo_blue_dk,
  color_logo_blue_lt,
  color_logo_grad_20,
  color_logo_grad_40,
  color_logo_grad_70,
  color_logo_grad_100,
  color_white,
} from './colors';

// LOGO COLORS
export const logo_colors: LogoColors = {
  text: color_logo_blue_dk,
  text_reverse: color_white,
  blue: color_logo_blue_lt,
  grad: [
    { color: color_logo_grad_20, alpha: '20%' },
    { color: color_logo_grad_40, alpha: '40%' },
    { color: color_logo_grad_70, alpha: '70%' },
    { color: color_logo_grad_100, alpha: '100%' },
  ],
};
