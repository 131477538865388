import { styled } from '@mui/material/styles';
import {  getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { Swiper } from 'swiper/react';

import {
  color_black,
  color_dark_grey,
  color_text_grey,
  color_darkest_grey,
  color_light_grey,
  color_white,
  color_miami,
} from '@styles/colors';
import {
  VariantProp
} from '@shared/components/accordion/accordion.interface';
import { noTo_font } from '@src/styles/typography';
import { TestimonialArrowDirectionProp, SwiperProps } from './testimonial-slider.interface';
import { Splide } from '@splidejs/react-splide';

export const variants = {
  light: {
    borderColor: color_light_grey,
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  mid: {
    borderColor: color_light_grey,
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  dark: {
    borderColor: color_light_grey,
    headlineColor: color_white,
    detailColor: color_text_grey,
    iconColor: color_white,
  },
};


export const TestimonialStyled = styled('div')`
  max-width: 100%;
  width: 100%;
`;
