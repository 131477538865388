import React, { useState } from 'react';

import { Accordion } from '@shared/components';
import { FaqContentType } from '../../list-panel.interface';

import {
  FaqContentContainer,
  List,
  Header,
  HeaderItem,
  ItemHeadline,
  AccordionContainer,
} from './faq-content.styles';
import { FaqContentProps } from './faq-content.interface';

export const FaqContent = ({ variant, faqContentData }: FaqContentProps) => {
  const [faqContentActive, setFaqContentActive] = useState<number>(0);

  function getItemList(faqContent: FaqContentType) {
    return faqContent?.items
      .filter(({ headline }) => headline)
      .map(({ headline, content }) => ({
        summary: <ItemHeadline variant={variant}>{headline}</ItemHeadline>,
        details: content,
      }));
  }

  return (
    <FaqContentContainer>
      <Header>
        {faqContentData?.map(({ title }, index) => {
          return (
            <HeaderItem
              key={index}
              variant={variant}
              isActive={faqContentActive === index}
              onClick={() => setFaqContentActive(index)}
            >
              {title}
            </HeaderItem>
          );
        })}
      </Header>
      <List>
        {faqContentData?.map((faqContent, index) => (
          <AccordionContainer isActive={faqContentActive === index} key={index}>
            <Accordion
              variant={variant}
              itemList={getItemList(faqContent)}
              additionalInfo={faqContent.additionalInfo}
            />
          </AccordionContainer>
        ))}
      </List>
    </FaqContentContainer>
  );
};
