import React from 'react';
import { SeeAllLinkStyled } from './see-all-link.styles';
import { SeeAllLinkProps } from './see-all-link.interface';
import BasicLink from '@shared/components/basic-link';
import { useMainNav } from '@cms-components/header/context/main-nav.context';

export const SeeAllLink = ({ data }: SeeAllLinkProps) => {
  const { closeNav } = useMainNav();
  const { link_text, link_url, external_link } = data;
  return (
    <SeeAllLinkStyled onClick={() => closeNav()}>
      <BasicLink
        url={link_url}
        isExternal={external_link}
        text={link_text}
        underline={true}
      />
    </SeeAllLinkStyled>
  );
};
