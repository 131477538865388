import { LCArticleMainItemProps } from '@src/shared/components/learning-center/lc-main-article-list/lc-main-article-list.interface';
import { CSLCArticle } from '@src/shared/contentstack';
import { BackgroundType } from '@src/shared/interfaces/theme.interface';
import {
  color_blue,
  color_indigo,
  color_light_grey,
  color_miami,
} from '@src/styles/colors';

export const standardFeatureMapper = (
  cards: CSLCArticle[],
  background: BackgroundType = 'light'
) =>
  cards?.map((card) => {
    return {
      image: card?.featured_image?.image,
      topics: card?.topics.map(({ display_name }) => display_name),
      topicColor: background === 'dark' ? color_miami.base : color_blue,
      headline: card?.headline,
      headlineLink: card?.url,
      headlineColor: background === 'dark' ? color_light_grey : null,
      teaser: card?.teaser_text,
      teaserColor: background === 'dark' ? color_light_grey : null,
      categoryLink: card?.url_category,
      categoryLinkColor: background === 'dark' ? color_light_grey : null,
      category: card?.category,
      fallbackImage: card?.featured_article_fallback_image,
      logoCard: {
        image: card?.logo_card?.logo,
        background: card?.logo_card?.card_color?.colors,
      },
      size: 280,
    };
  }) as LCArticleMainItemProps[];
