import styled from '@emotion/styled';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)<{ underline: string }>`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: ${(props) => props.underline};
  width: 100%;
`;

export const StyledLinkText = styled.span`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
`;

export const ExternalLink = styled.a<{ underline: string }>`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: ${(props) => props.underline};
    width: 100%;
`;
