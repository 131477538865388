import { CSHeroBannerFormProps } from '@shared/contentstack/contentstack.interface';
import { resolveColonDelimited } from '@shared/resolvers/content-stack.resolver';

export const mappedFormBannerData = (data: CSHeroBannerFormProps) => {
  return {
    ...data,
    banner_background_color: resolveColonDelimited(
      data.banner_background_color || 'light'
    ),
    secondary_background_color: resolveColonDelimited(
      data.secondary_background_color || 'light'
    ),
  };
};
