import styled from '@emotion/styled';
import { color_blue, color_miami } from '@styles/colors';

export const SpanIndigo = styled.span`
  color: ${color_blue};
`;

export const SpanMiami = styled.span`
  color: ${color_miami.base};
`;
