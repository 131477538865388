import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { global_colors } from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';

export const FormPanelContainer = styled.section<{ centered: boolean }>`
  display: ${({ centered }) => (centered ? 'block' : 'grid')};
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  row-gap: ${getStyleSizes(50)};
  padding: 0;
  font-family: Causten;
  align-items: start;

  ${mq_min('md')} {
    grid-template-columns: 60% auto;
    grid-template-rows: 1fr;
    padding: ${getStyleSizes([50, 20])};
  }

  ${mq_min('lg')} {
    grid-template-columns: ${getStyleSizes(610)} auto;
    grid-template-rows: 1fr;
    padding: ${getStyleSizes(50)} 0;
  }

  ${({ centered }) =>
    `div {${
      centered
        ? `
        margin-left: auto;
        margin-right: auto;
        `
        : ''
    }}`};
`;

export const FormPanelForm = styled.div`
  padding: ${getStyleSizes(20)};
  background: ${global_colors.background.white};
  border-radius: 4px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
  text-align: center;

  ${mq_min('md')} {
    padding: ${getStyleSizes(50)};
  }
`;

export const FormPanelTitle = styled.h2`
  font-size: ${getStyleSizes(24)};
  margin-bottom: 0;

  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(15)};
  }
`;

export const FormPanelCopy = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  padding: 0 ${getStyleSizes(20)};
  margin-bottom: ${getStyleSizes(50)};
  font-size: ${getStyleSizes(15)};

  h3 {
    font-size: ${getStyleSizes(18)};
  }

  ${mq_min('sm')} {
    padding: ${getStyleSizes([0, 30, 0, 40])};
  }

  ${mq_min('lg')} {
    padding: ${getStyleSizes([0, 30, 0, 90])};
  }
`;
