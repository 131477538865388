import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';

interface FallbackImageContainer {
  fallbackShadow: boolean | null;
  is_icon: boolean;
}

export const FallbackImageContainer = styled.div<FallbackImageContainer>`
  border-radius: 4px;
  overflow: hidden;

  ${({ is_icon }) => (is_icon ? `max-width: 40%; margin: 0 auto;` : null)}

  ${({ fallbackShadow }) =>
    fallbackShadow
      ? `box-shadow: ${getStyleSizes([0, 2, 30, 20])} rgb(0 0 0 / 10%);`
      : null}

  & img {
    object-fit: cover;
    width: 100%;
  }
`;
