import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { CSFormPanelBaseline } from '@shared/contentstack';
import { ContactLongFormBaseline } from '@shared/components/form-panel-baseline';
import { 
    FormBaselineContainer,
    FormInner,
    HeaderContainer,
    FormPanelContainer,
    FormPanelCopyStyled,
    FormPanelFormStyled,
    PreHeadlineStled,
    HeadlineStyled,
    SocialProofContainer,
    SocialProofHeadline,
    SocialProofImageStyled,
    LinkInfoStyled,
    LinkTextStyled,
    LogoStyled
 } from './longform-baseline.styles';
 import BasicLink from '@shared/components/basic-link';
 import { hasContent } from '@src/shared/utilities/string.utils';
 import { RichText } from '@src/shared/components';
 import { default_breakpoints } from '@src/styles/breakpoints';

export const LongFormBaseline = ({
  form, 
  form_logo,
  form_copy,
  preheadline, 
  headline,
  large_screen_background,
  desktop_background,
  tablet_background,
  mobile_background,
  social_proof,
  contact_info
}: CSFormPanelBaseline) => {

  const isValidFormConfig: boolean =
    !!form?.base_url && !!form.form_id && !!form.tracking_code;

  return (
    <FormBaselineContainer 
      large_screen_background={large_screen_background?.image?.url}
      desktop_background={desktop_background?.image?.url}
      tablet_background={tablet_background?.image?.url}
      mobile_background={mobile_background?.image?.url}
    >
      {form_logo && (
        <CMSComponentWrapper
          maxWidth={1240}
        >
          <HeaderContainer>
            <BasicLink url="/">
              <LogoStyled 
                src={form_logo?.image.url}
                alt={form_logo?.image_alt_text}
              />
            </BasicLink>
            {(contact_info?.link_url && contact_info?.link_text) && (
              <LinkInfoStyled>
                <img 
                  src={contact_info?.image.url}
                  alt={contact_info?.alt_text}
                />
                <BasicLink url={contact_info?.link_url}>
                  <LinkTextStyled>
                    {contact_info?.link_text}
                  </LinkTextStyled>
                </BasicLink>
              </LinkInfoStyled>
            )}
          </HeaderContainer>
        </CMSComponentWrapper>
      )}
      <CMSComponentWrapper
        horizontalPadding={0}
      >
        <FormInner>
          <FormPanelContainer>
            {hasContent(form_copy?.children) && (
              <FormPanelCopyStyled
                id={form_copy.uid}
              >
                <PreHeadlineStled>{preheadline}</PreHeadlineStled>
                <HeadlineStyled>{headline}</HeadlineStyled>
                <RichText content={form_copy} />
              </FormPanelCopyStyled>
            )}
            <FormPanelFormStyled>
              {isValidFormConfig ? (
                <ContactLongFormBaseline
                  base_url={form?.base_url}
                  title={form?.form_title}
                  success_message={form?.success_message}
                  tracking_code={form?.tracking_code}
                  form_id={form?.form_id}
                />
              ) : (
                <span>Please provide a form ID, base URL and tracking code</span>
              )}
            </FormPanelFormStyled>
            <SocialProofContainer>
              <SocialProofHeadline>{social_proof?.social_proof_headline}</SocialProofHeadline>
              <picture>
                <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={social_proof?.social_proof_desktop_image?.image.url} />
                <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={social_proof?.social_proof_tablet_image?.image.url} />
                <SocialProofImageStyled 
                  src={social_proof?.social_proof_mobile_image?.image.url}
                  alt={social_proof?.social_proof_headline}
                />
              </picture>
            </SocialProofContainer>
          </FormPanelContainer>
        </FormInner>
      </CMSComponentWrapper>
    </FormBaselineContainer>
  );
};
