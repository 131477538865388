import styled from '@emotion/styled';
import { mq_min } from '@src/styles/breakpoints';
import {
  color_darkest_grey,
  color_white,
  color_light_grey,
  color_dark_grey,
} from '@src/styles/colors';
import { getStyleSizes } from '@src/styles/styles.utils';

export const StyledArticleSmallContainer = styled.div`
  background-color: ${color_white};
  padding: ${getStyleSizes([37, 33])};
  border: 1px solid ${color_light_grey};
  border-radius: 4px;
  box-sizing: border-box;
`;
export const StyledDate = styled.div`
  font-weight: 500;
  color: ${color_dark_grey};
  font-family: Causten;
  line-height: ${getStyleSizes(14)};
  margin-bottom: ${getStyleSizes(10)};
`;
export const StyledHeadline = styled.h3`
  font-weight: 600;
  color: ${color_darkest_grey};
  font-family: Causten;
  line-height: ${getStyleSizes(26)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;
