import React from 'react';

import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import * as Styled from './banner-overhang.styles';
import * as StyledHero from '../../hero-banner.styles';

import {
  PrimaryButton,
  RichText,
  VideoPlayer,
  ImageComponent,
  SimpleHeader,
} from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import BasicLink from '@shared/components/basic-link';
import { OverhangBannerProps } from '@cms-components/hero-banner/hero-banner.interface';

import { default_breakpoints } from '@styles/breakpoints';
import { getLinearGradient } from '@styles/styles.utils';
import { background_color_mapper, text_color_mapper } from '@styles/colors';

export const BannerOverhang = (props: OverhangBannerProps) => {
  const {
    headline,
    banner_copy,
    button_link,
    secondary_link,
    banner_image,
    banner_background_color = 'light',
    secondary_background_color = 'light',
    video_id,
    make_headline_h1,
    site_configuration,
  } = props || {};

  const hasButtonLink = button_link?.link_url;
  const hasSecondaryLink = secondary_link?.link_url;
  const hasImage = banner_image?.image;

  const isDesktop = useBreakpoint(up('md')) || true;

  // in mobile, we need a single color background
  // in desktop, it may be two colors depending on what the user picked
  const secondaryBackgroundColor = isDesktop
    ? secondary_background_color
    : banner_background_color;

  const textColor = text_color_mapper[banner_background_color || 'dark'];

  return (
    <CMSComponentWrapper
      maxWidth={default_breakpoints.cw}
      backgroundColor={getLinearGradient(
        background_color_mapper[banner_background_color],
        background_color_mapper[secondaryBackgroundColor],
        80
      )}
      data-testid="banner-overhang__container"
    >
      <Styled.BannerOverhang color={textColor}>
        {headline && (
          <SimpleHeader text={headline} isH1={Boolean(make_headline_h1)} />
        )}
        {banner_copy && (
          <Styled.BannerOverhangCopy>
            <RichText content={banner_copy} />
          </Styled.BannerOverhangCopy>
        )}

        {(hasButtonLink || hasSecondaryLink) && (
          <Styled.BannerOverhangButtonsWrapper>
            {hasButtonLink && (
              <StyledHero.HeroBannerButton>
                <PrimaryButton
                  ariaLabel="see more"
                  path={button_link.link_url}
                  label={button_link.link_text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                />
              </StyledHero.HeroBannerButton>
            )}
            {hasSecondaryLink && (
              <StyledHero.HeroBannerButtonSecondary>
                <BasicLink
                  text={secondary_link?.link_text}
                  url={secondary_link?.link_url}
                  underline={true}
                  isExternal={secondary_link?.external_link}
                />
              </StyledHero.HeroBannerButtonSecondary>
            )}
          </Styled.BannerOverhangButtonsWrapper>
        )}

        {hasImage && !video_id && (
          <ImageComponent
            image={banner_image.image.gatsbyImageData}
            alt_text={banner_image?.image_alt_text}
            fallback={banner_image.image.url}
          />
        )}

        {video_id && (
          <Styled.BannerOverhangVideo>
            <VideoPlayer id={video_id} mobileHeight={'70%'} height={'45%'} fullwidth={true} />
          </Styled.BannerOverhangVideo>
        )}
      </Styled.BannerOverhang>
    </CMSComponentWrapper>
  );
};
