import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
  color_blue,
  color_miami,
  color_indigo,
} from '@styles/colors';

import { VariantProp } from './lc-main-article-list.interface';
import { global_typography } from '@src/styles/typography';

export const themes = {
  light: {
    containerPanelBackground: color_white,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
  },
};

export const Container = styled.div<VariantProp>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ variant }) => themes[variant]?.containerPanelBackground};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};
  }
`;

export const ImageContainer = styled.div<{ size?: number; color?: string }>`
  height: ${({ size }) => getStyleSizes(size)};
  background: ${({ color }) => color};
  .gatsby-image-wrapper {
    height: 100%;
    picture {
      border-radius: 4px;
    }
  }
`;

export const Title = styled.h2`
  font-size: ${getStyleSizes(34)};
  color: ${color_darkest_grey};
  margin-bottom: ${getStyleSizes(36)};
  text-align: center;
  font-weight: 400;

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
    margin-bottom: ${getStyleSizes(60)};
  }
`;

export const Inner = styled.div`
  overflow-x: auto;
  .swiper-slide {
    width: auto;
  }
`;

export const ItemTopic = styled.p<{ color: string }>`
  font-size: ${getStyleSizes(12)};
  font-weight: 600;
  color: ${({ color }) => color || color_blue};
  margin-bottom: ${getStyleSizes(8)};
  margin-top: ${getStyleSizes(4)};
  text-transform: uppercase;
`;

export const ItemHeadline = styled.h3`
  font-size: ${getStyleSizes(16)};
  font-weight: 600;
  color: ${color_darkest_grey};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const ItemTeaser = styled.p<{ teaserColor: string }>`
  font-family: NoToSans;
  font-size: ${getStyleSizes(global_typography.copy.small)};
  font-weight: 200;
  margin-top: ${getStyleSizes(10)};
  color: ${({ teaserColor }) => teaserColor};
`;

export const ItemCategory = styled.div<{ categoryLinkColor: string }>`
  font-size: ${getStyleSizes(15)};
  font-weight: 600;
  color: ${({ categoryLinkColor }) => categoryLinkColor};
  margin-top: ${getStyleSizes(24)};
  padding-bottom: ${getStyleSizes(6)};

  a {
    padding-bottom: ${getStyleSizes(4)};
    border-bottom: 1px solid ${({ categoryLinkColor }) => categoryLinkColor};
  }
`;
