import { useFetchArticles } from '@cms-components/feature-cards/hooks/useFetchArticles';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { CardPresentation, PrimaryButton } from '@src/shared/components';
import { LCArticleSmall } from '@src/shared/components/learning-center/lc-article-small/lc-article-small';
import { LCArticleMainItem } from '@src/shared/components/learning-center/lc-main-article-list/lc-main-article-item';
import { CSLCContentType, CSLCFeatureCard } from '@src/shared/contentstack';
import { default_breakpoints } from '@styles/breakpoints';
import React from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';
import { FeaturesCardsProps } from './feature-cards.interface';
import { featureCardsMapper } from './feature-cards.mapper';
import * as Styled from './feature-cards.styles';
import { quoteFeatureMapper } from './quote/quote-feature.mapper';
import { smallFeatureMapper } from './small/small-feature.mapper';
import { standardFeatureMapper } from './standard/standard-feature.mapper';
import { SwiperCustom } from './swiper-custom';
import { titleFeatureMapper } from './title/title-feature.mapper';
import { addIconToEndOfSting } from '@shared/utilities/string.utils';
import { FEATURED_CARDS_MOCK } from '@shared/contentstack/live-preview/mock-data/featured-cards-mock';

export const FeatureCards = ({
  background = 'light',
  cards,
  button,
  headline,
  teaser_text,
  type,
  id,
  uid,
}: FeaturesCardsProps) => {
  const isDesktop = useBreakpoint(up('lg'));
  const isMobile = !isDesktop;
  const showButtonMobile = !!button && isMobile && !teaser_text;
  const headlineWithArrow = addIconToEndOfSting({ text: headline || '' });
  const componentMap: any = {
    standard: {
      component: LCArticleMainItem,
      data: standardFeatureMapper(cards || [], background),
    },
    small: {
      component: LCArticleSmall,
      data: smallFeatureMapper(cards || []),
    },
    quote: {
      component: CardPresentation,
      data: quoteFeatureMapper(cards || []),
    },
    title: {
      component: CardPresentation,
      data: titleFeatureMapper(cards || []),
    },
  };

  return (
    <Styled.FeatureCardContainer
      data-testid="feature-card_container"
      background={background}
    >
      <CMSComponentWrapper uid={uid} maxWidth={default_breakpoints.cw}>
        <Styled.FeatureContainer>
          <Styled.StyledHead
            withTeaserText={!!teaser_text}
            withButton={!!button && !showButtonMobile}
          >
            {headlineWithArrow && (
              <Styled.HeadLineFeature background={background}>
                <span dangerouslySetInnerHTML={{ __html: headlineWithArrow }} />
              </Styled.HeadLineFeature>
            )}
            {teaser_text && (
              <Styled.TeaserTextFeature background={background}>
                {teaser_text}
              </Styled.TeaserTextFeature>
            )}
            {!!button && !showButtonMobile && (
              <Styled.StyledButton>
                <PrimaryButton
                  ariaLabel="see more"
                  path={button?.link_url}
                  label={button?.link_text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                />
              </Styled.StyledButton>
            )}
          </Styled.StyledHead>
          {!!cards && (
            <Styled.Inner type={type} data-slides-to-show={3}>
              <SwiperCustom
                id={id}
                type={type}
                Component={componentMap[type]?.component}
                background={background}
                data={componentMap[type]?.data}
              />
            </Styled.Inner>
          )}

          {showButtonMobile && (
            <Styled.StyledBottomButton>
              <PrimaryButton
                ariaLabel="see more"
                path={button?.link_url}
                label={button?.link_text}
                size={'large'}
                variant={'contained'}
                icon={'text'}
              />
            </Styled.StyledBottomButton>
          )}
        </Styled.FeatureContainer>
      </CMSComponentWrapper>
    </Styled.FeatureCardContainer>
  );
};

// LIVE SITE ENTRY COMPONENT...
export const MappedFeatureCards = (data: CSLCFeatureCard) => {
  const articles = data?.display_categories
    ?.map(({ id }: CSLCContentType) => {
      return useFetchArticles().filter(
        (article) => article.lc_content_type[0]?.id === id
      );
    })
    .flat();
  return <FeatureCards {...featureCardsMapper({ ...data, articles })} />;
};

// LIVE PREVIEW ENTRY COMPONENT...
export const LivePreviewMappedFeatureCards = (data: CSLCFeatureCard) => {
  return (
    <FeatureCards
      {...featureCardsMapper({ ...data, articles: FEATURED_CARDS_MOCK })}
    />
  );
};
