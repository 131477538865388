import { PrimaryButton, RichText, TextColor } from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { simplePromoMapper } from './mappers/simple-promo.mapper';
import { SimplePromoProps } from './simple-promo.interface';

import * as Styled from './simple-promo.styles';

import { default_breakpoints } from '@src/styles/breakpoints';

export const SimplePromo = ({
  backgroundColor,
  isCentered,
  headline,
  promoText,
  button,
  uid
}: SimplePromoProps) => {
  return (
    <Styled.SimplePromoContainer
      id={uid}
      data-testid="simple-promo__container"
      background={backgroundColor}
      isCentered={isCentered}
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <Styled.SimplePromoInner isCentered={isCentered}>
          <Styled.Headline background={backgroundColor} isCentered={isCentered}>
            <TextColor>{headline}</TextColor>
          </Styled.Headline>
          <Styled.DetailContainer
            background={backgroundColor}
            isCentered={isCentered}
          >
            {promoText && (
              <Styled.PromoText background={backgroundColor}>
                <RichText content={promoText} />
              </Styled.PromoText>
            )}
            <Styled.ButtonContainer>
              <PrimaryButton
                ariaLabel="see more"
                path={button?.url}
                label={button?.text}
                size={'large'}
                variant={'contained'}
                icon={'text'}
              />
            </Styled.ButtonContainer>
          </Styled.DetailContainer>
        </Styled.SimplePromoInner>
      </CMSComponentWrapper>
    </Styled.SimplePromoContainer>
  );
};

export const MappedSimplePromo = (data: any) => {
  const mapper: SimplePromoProps = simplePromoMapper(data, {
    background_color: data.background_color,
    centered: data.centered,
  });

  return <SimplePromo {...mapper} />;
};

export default SimplePromo;
