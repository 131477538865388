import styled from '@emotion/styled';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import {
  color_dark_grey,
  color_darkest_grey,
  global_colors,
} from '@styles/colors';

import { PanelLinksContainerStyledProps } from './panel-links.interface';
import { css } from '@emotion/react';

export const PanelLinksStyled = styled.section``;

export const PanelLinkStyled = styled.div`
  margin-top: ${getStyleSizes(18)};
  margin-bottom: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    margin-top: 0;
    margin-bottom: ${getStyleSizes(20)};
  }
`;

export const PanelLinksContainerStyled = styled.div<PanelLinksContainerStyledProps>`
  ${mq_min('lg')} {
    display: grid;
    ${({ variant }) => {
      if (variant === 'two-column') {
        return css`
          grid-template-columns: 1fr 1fr;
        `;
      }
      if (variant === 'three-column') {
        return css`
          grid-template-columns: 1fr 1fr 1fr;
        `;
      }
      return null;
    }}
  }
`;

export const PanelLinksTitleStyled = styled.div`
  font-weight: 600;
  font-size: ${getStyleSizes(12)};
  line-height: ${getStyleSizes(14)};
  letter-spacing: 0.05em;
  color: ${color_darkest_grey};
  text-transform: uppercase;
  margin-bottom: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(30)};
  }
`;

export const PanelLinkDescriptionStyled = styled.div`
  font-family: 'NoToSans', Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: ${getStyleSizes(13)};
  line-height: ${getStyleSizes(22)};
  letter-spacing: 0.01em;
  color: ${color_dark_grey};
  margin-top: ${getStyleSizes(5)};
`;

export const BasicLinkStyled = styled.span`
  color: ${global_colors.link.default};
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  line-height: 1.2;

  a {
    display: flex;
  }
`;
