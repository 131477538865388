import CMSComponentWrapper from '@shared/components/component-wrapper';
import React from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LCArticleMainItem } from './lc-main-article-item';
import { LCMainArticleListProps } from './lc-main-article-list.interface';
import { Container, Inner, Title } from './lc-main-article-list.styles';
import { default_breakpoints } from '@styles/breakpoints';

export const LCMainArticleList = ({
  variant = 'light',
  title,
  articleList,
}: LCMainArticleListProps) => {
  if (!articleList.length) return <></>;
  return (
    <Container
      id={articleList[0]?.uid}
      variant={variant}
      data-testid="lc_main_article_list"
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <Title>{title}</Title>
        <Inner data-slides-to-show={3}>
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            breakpoints={{
              [default_breakpoints.md]: {
                slidesPerView: 2,
              },
              [default_breakpoints.lg]: {
                slidesPerView: 3,
              },
            }}
          >
            {articleList.map((article, index) => (
              <SwiperSlide id={article.uid} key={index}>
                <LCArticleMainItem {...article} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Inner>
      </CMSComponentWrapper>
    </Container>
  );
};
