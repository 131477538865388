import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes, getStyleBorder } from '@styles/styles.utils';
import {
  color_dark_grey,
  color_miami,
  color_darkest_grey,
  color_lightest_grey,
  color_white,
  color_indigo,
} from '@styles/colors';

import {
  StandardPromoInnerProps,
  HeadlineProps,
  ImageProps,
  DetailContainerProps,
  ImageContainerProps,
  StandardPromoContainerProps,
  DescriptionProps,
} from './standard-promo.interface';
import { causten_font, global_typography, noTo_font } from '@styles/typography';

const standardPromoConfig = {
  topMargin: {
    mobile: getStyleSizes(12),
    desktop: getStyleSizes(32),
  },
};

export const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    descriptionColor: color_dark_grey,
    linkColor: color_darkest_grey,
  },
  mid: {
    containerBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
    descriptionColor: color_dark_grey,
    linkColor: color_darkest_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    descriptionColor: '#EFF2F8',
    linkColor: color_white,
  },
};

export const StandardPromoContainer = styled.div<StandardPromoContainerProps>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ background }) => themes[background]?.containerBackground};

  ${({ variant, imageAlignment }) =>
    variant === 'full-width' &&
    imageAlignment === 'right' &&
    `
    position: relative;
    padding: ${getStyleSizes([32, 0, 0])};
  `}

  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};
  }
`;

export const StandardPromoInner = styled.div<StandardPromoInnerProps>`
  border: ${getStyleBorder()};
  border-radius: ${getStyleSizes(8)};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${getStyleSizes([24, 0])};

  ${({ imageAlignment }) =>
    imageAlignment === 'right' &&
    `
      padding-bottom: 0;
  `}

  ${({ imageAlignment }) =>
    imageAlignment === 'inset' &&
    `
    flex-direction: column-reverse;
  `}

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      border: 0;
      border-radius: 0;
      overflow: initial;
      position: initial;
      padding: 0;
  `}

  ${({ hasImage }) =>
    !hasImage &&
    `
      padding: 0;
  `}

  ${mq_min('lg')} {
    padding: 0;
    flex-direction: row;
    align-items: ${({ imageAlignment }) =>
      imageAlignment === 'inset' ? 'center' : 'flex-end'};
  }
`;

export const DetailContainer = styled.div<DetailContainerProps>`
  padding: ${getStyleSizes([24, 32])};

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      padding: ${getStyleSizes([24, 0])};
  `}

  ${mq_min('lg')} {
    width: 632px;
    padding: ${getStyleSizes([60, 32, 60, 80])};

    ${({ variant }) =>
      variant === 'full-width' &&
      `
        padding: ${getStyleSizes([0, 50, 0, 0])};
        flex-basis: 50%;
    `}

    ${({ hasImage }) =>
      !hasImage &&
      `
        width: 100%;
        padding: ${getStyleSizes([60, 80])};
    `}
  }
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  display: flex;
  justify-content: center;
  padding: ${getStyleSizes([24, 32])};

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      padding: ${getStyleSizes([24, 0])};
  `}

  ${({ imageAlignment }) =>
    imageAlignment === 'right' &&
    `
      padding: ${getStyleSizes([24, 0, 0])};
      justify-content: flex-start;
  `}

  ${mq_min('lg')} {
    width: calc(100% - 632px);
    padding: ${getStyleSizes([60, 80, 60, 0])};

    ${({ variant }) =>
      variant === 'full-width' &&
      `
        padding: ${getStyleSizes([0, 0, 0, 50])};
        flex-basis: 50%;
    `}

    ${({ imageAlignment }) =>
      imageAlignment === 'right' &&
      `
        padding: ${getStyleSizes([24, 0, 0])};
        justify-content: flex-end;
    `}
  }
`;

export const Image = styled.img<ImageProps>`
  width: calc(100% + ${getStyleSizes(25)});
  height: auto;
  object-fit: cover;
  border-radius: 4px;

  ${({ variant, alignment }) =>
    variant === 'full-width' &&
    alignment === 'right' &&
    `
      margin-right: ${getStyleSizes(-25)};
  `}

  ${mq_min('lg')} {
    max-width: 100%;
    ${({ alignment }) =>
      alignment === 'right' &&
      `
        position: absolute;
        right: 0;
        bottom: 0;
        max-height: 85%;
        max-width: calc(100% - 570px);
        object-fit: initial;
        width: auto;
    `}

    ${({ variant, alignment }) =>
      variant === 'full-width' &&
      alignment === 'right' &&
      `
      margin-right: 0;
  `}
  }

  ${mq_min('xl')} {
    ${({ alignment }) =>
      alignment === 'right' &&
      `
        max-width: calc(95% - 632px);
    `}
  }
`;

export const Headline = styled.h3<HeadlineProps>`
  font-family: ${causten_font};
  font-size: ${getStyleSizes(26)};
  font-weight: ${({ isSmall }) => (isSmall ? 200 : 'normal')};
  color: ${({ background }) => themes[background]?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${({ isSmall }) =>
      isSmall ? getStyleSizes(28) : getStyleSizes(36)};
  }
`;

export const Subhead = styled.div`
  font-family: ${causten_font};
  font-size: ${getStyleSizes(16)};
  margin-top: ${getStyleSizes(8)};
  color: ${color_miami.base};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
    margin-top: ${getStyleSizes(12)};
  }
`;

export const Description = styled.div<DescriptionProps>`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(global_typography.copy.base)};
  margin-top: ${getStyleSizes(standardPromoConfig.topMargin.mobile)};
  color: ${({ background }) => themes[background]?.descriptionColor};

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(standardPromoConfig.topMargin.desktop)};
  }
`;

export const LinkContainer = styled.div`
  margin-top: ${getStyleSizes(standardPromoConfig.topMargin.mobile)};
  a {
    font-weight: 600;
  }

  &:first-of-type {
    margin-top: 0;
    a {
      font-size: ${getStyleSizes(24)};
      font-weight: 400;
    }
  }
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(standardPromoConfig.topMargin.desktop)};

    &:first-of-type {
      a {
        font-size: ${getStyleSizes(28)};
      }
    }
  }
`;
