import React from 'react';
import { addIconToEndOfSting } from '@shared/utilities/string.utils';

import { IconLinkProps } from './icon-link.interface';

// Styles
import * as Styled from './icon-link.styles';
import { color_miami, global_colors } from '@styles/colors';

export const IconLink = ({
  label,
  linkUrl,
  font = 'inherit',
  icon = 'assembly-arrow',
  color = global_colors.text.dark,
  iconColor = color_miami.base,
}: IconLinkProps) => {
  const textWithArrow = !!icon ? addIconToEndOfSting({text: label, icon}) : label;
  return (
    <Styled.LinkWrapper font={font} data-testid="icon-link__container">
      <>
        <Styled.BasicIconLink url={linkUrl}>
          {!!textWithArrow && (
            <Styled.LinkIcon
              color={color}
              iconColor={iconColor}
              dangerouslySetInnerHTML={{ __html: textWithArrow }}
            />
          )}
        </Styled.BasicIconLink>
      </>
    </Styled.LinkWrapper>
  );
};
