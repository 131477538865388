import { color_miami, color_white, text_color_mapper } from '@styles/colors';
import { addIconToEndOfSting } from '@shared/utilities/string.utils';

export const cardPresentationTextMapper = ({
  title,
  subtitle,
  ctaOrAttribution,
  background,
  type,
}: {
  title: any;
  subtitle: any;
  ctaOrAttribution: any;
  background: string;
  type: any;
}) => {

  const isBoxedPromo = type === 'boxed';

  const iconType = 'assembly-arrow';
  // is this an article promo (bg color is not passes to article promo)
  const isArticleCard = type === 'title' || type === 'quote';
  // is the bg light? we need to make sure the text is dark, if so...
  const isLightBackground =
    !background ||
    background === 'white' ||
    background === color_white ||
    isBoxedPromo;
  // is the arrow icon needed on the title?
  const isTitleLink =
    (!!subtitle && !!title && !ctaOrAttribution) ||
    (!subtitle && !ctaOrAttribution) ||
    isArticleCard;
  // is the arrow icon needed on the subtitle?
  const isContentLink = (!title && !!subtitle) || isArticleCard;
  // is the arrow icon needed on the cta?
  const isCtaLink = !!subtitle && !!title && !!ctaOrAttribution && type !== 'quote';

  return {
    isArticleCard: type === 'title' || type === 'quote',
    isLightBackground:
      !background || background === 'white' || background === color_white,
    title: isTitleLink ? addIconToEndOfSting({text: title, icon: iconType}) : title,
    subtitle: isContentLink ? addIconToEndOfSting({text: subtitle, icon: iconType}) : subtitle,
    ctaOrAttribution: isCtaLink
      ? addIconToEndOfSting({text: ctaOrAttribution, icon: iconType})
      : ctaOrAttribution,
    textColor: isLightBackground
      ? text_color_mapper['light']
      : text_color_mapper['dark'],
    iconColor:
      isLightBackground || type === 'boxed' ? color_miami.base : color_white,
    iconPositionClass: isBoxedPromo ? 'promo-icon-center' : 'promo-icon',
  };
};
