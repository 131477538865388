import React from 'react';

import { ImageScrollingContainer, Image } from './image-scrolling.styles';
import { ImageScrollingProps } from './image-scrolling.interface';
import { useActiveImage } from './../../context/active-image.context';
import { getImageHeight } from './../../utilities/image.utils';

export const ImageScrolling = ({
  background,
  contentGroupList,
}: ImageScrollingProps) => {
  const { activeImage } = useActiveImage();

  return (
    <ImageScrollingContainer>
      {contentGroupList?.map(({ image, imageAltText = '' }, index) => {
        return (
          image &&
          activeImage === index && (
            <Image
              key={index}
              src={image.src}
              alt={imageAltText}
              width={image.width}
              height={image.height}
              fitHeight={getImageHeight(image)}
              background={background}
            />
          )
        );
      })}
    </ImageScrollingContainer>
  );
};
