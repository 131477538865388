import { default_breakpoints, mq_max } from '@src/styles/breakpoints';
import { getStyleSizes } from '@src/styles/styles.utils';
import styled from 'styled-components';

export const ModalStyled = styled.div`
  background-color: transparent;
  z-index: 999;
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  @keyframes customFadeIn {
    0% {
      background: rgba(0, 0, 0, 0);
    }
    100% {
      background: rgba(0, 0, 0, 0.35);
    }
  }

  @keyframes customFadeOut {
    0% {
      background: rgba(0, 0, 0, 0.35);
    }
    100% {
      background: rgba(0, 0, 0, 0);
    }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.35) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
    100% {
      transform: scale(0.35) translateY(1000px);
      opacity: 0;
    }
  }

  &.revealing {
    transform: scale(1);
    .modal-overlay {
      background: rgba(0, 0, 0, 0);
      animation: customFadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .modal-content {
      opacity: 0;
      animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &.out {
      animation: quickScaleDown 0s 0.5s linear forwards;
      .modal-overlay {
        animation: customFadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)
          forwards;
      }
      .modal-content {
        animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
  }
`;

export const ModalContentStyled = styled.div<{
  width: number;
  height: number;
  unit: string;
}>`
  box-sizing: border-box;
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  width: ${(p) => (p.width ? p.width + p.unit : getStyleSizes(360))};
  max-width: 100%;
  min-height: ${(p) => (p.height ? p.height + p.unit : getStyleSizes(360))};
  padding: 0;
  overflow: initial;
  display: block;
  margin-left: ${(p) =>
    p.width ? '-' + p.width / 2 + p.unit : getStyleSizes(-180)};
  margin-top: ${(p) =>
    p.height ? '-' + p.height / 2 + p.unit : getStyleSizes(-180)};

  ${mq_max('md')} {
    background: transparent;
    width: 100%;
    margin-left: 0;
    left: 0;
  }
`;

export const ModalCloseButtonStyled = styled.span`
  cursor: pointer;
  position: absolute;
  top: ${getStyleSizes(-32)};
  right: 0;
  display: block;
  ${mq_max('md')} {
    right: ${getStyleSizes(8)};
  }
`;

export const ModalOverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  display: table-cell;
`;
