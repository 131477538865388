import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { color_indigo, color_miami } from '@styles/colors';

const LayoutContainer = styled.section`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${getStyleSizes(70)} 1fr auto;

  ${mq_min('lg')} {
    grid-template-rows: ${getStyleSizes(50)} 1fr auto;
  }

  .color-primary {
    color: ${color_miami.base};
  }

  .color-secondary {
    color: ${color_indigo.base};
  }

  .last-word-icon {
    white-space: nowrap;

    .icon- {
      margin-left: 5px;
      font-size: 0.75em;
    }
  }
`;

const LPLayoutContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  margin-bottom: 50px;

  header {
    margin-bottom: 20px;
  }

  .color-primary {
    color: ${color_miami.base};
  }

  .color-secondary {
    color: ${color_indigo.base};
  }

  .last-word-icon {
    white-space: nowrap;

    .icon- {
      margin-left: 5px;
      font-size: 0.75em;
    }
  }
`;

export { LayoutContainer, LPLayoutContainer };
