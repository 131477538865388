import { color_blue } from '@styles/colors';
import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { causten_font } from '@styles/typography';

export const PageRteWrapper = styled.div`
  margin: ${getStyleSizes([20, 0])};
  * + * {
    margin-top: 2em;
  }

  a {
    text-decoration: none;
    color: ${color_blue};
  }

  blockquote {
    font-size: ${getStyleSizes(18)};
    line-height: ${getStyleSizes(22)};
  }

  ol,
  ul {
    margin-top: 2em;
    list-style: none;
  }

  ol {
    counter-reset: item;
  }

  li {
    position: relative;
    ${mq_min('lg')} {
      width: 54rem;
    }
    &:before {
      display: block;
      content: counters(item, '.') ' ';
      counter-increment: item;
      ${mq_min('lg')} {
        top: 0;
        left: 0;
        position: absolute;
        margin-left: -3rem;
        width: 2rem;
      }
    }
  }

  h2 {
    font-family: ${causten_font};
    font-weight: 400;
    font-size: ${getStyleSizes(26)};
    line-height: ${getStyleSizes(30)};
    &:not(:first-child) {
      padding-top: ${getStyleSizes(24)};
    }
  }

  h3 {
    font-family: ${causten_font};
    font-weight: 400;
    font-size: ${getStyleSizes(22)};
    line-height: ${getStyleSizes(26)};
    &:not(:first-child) {
      padding-top: ${getStyleSizes(18)};
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
