import { CSQuotePromo } from '@shared/contentstack';
import {
  BackgroundType,
  VariantType,
} from '@src/shared/interfaces/theme.interface';
import { QuotePromoProps } from '../quote-promo.interface';

export function quotePromoMapper(
  query: CSQuotePromo,
  props: {
    backgroundColor: BackgroundType;
    variant: VariantType;
  }
): QuotePromoProps {
  const { uid, attribution, firm, quote, background_image, logo } = query || {};

  return {
    attribution,
    uid,
    firm,
    quote,
    variant: props?.variant,
    backgroundColor: props.backgroundColor,
    logoImage: logo,
    backgroundImage: background_image,
  };
}
