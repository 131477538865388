import React from 'react';
import BasicLink from '../../basic-link';
import { IconLink } from '../../icon-link';
import { ImageComponent } from '@shared/components';
import { LCArticleMainItemProps } from './lc-main-article-list.interface';
import * as Styled from './lc-main-article-list.styles';
import { color_white } from '@src/styles/colors';

export const LCArticleMainItem = ({
  image,
  logoCard,
  topics,
  topicColor,
  headline,
  headlineLink,
  headlineColor,
  teaser,
  teaserColor,
  categoryLink,
  categoryLinkColor,
  category,
  fallbackImage,
  size = 315,
}: LCArticleMainItemProps) => {
  const itemImage = (() => {
    if (image.image) return image;
    if (logoCard?.image?.image) return logoCard?.image;
    return fallbackImage;
  })();
  return (
    <article>
      {itemImage && itemImage.image && (
        <>
          <Styled.ImageContainer
            size={size}
            color={logoCard?.background || color_white}
          >
            <ImageComponent
              image={itemImage.image.gatsbyImageData}
              fallback={itemImage.image.url}
              alt_text={itemImage.image_alt_text}
            />
          </Styled.ImageContainer>
        </>
      )}

      <Styled.ItemTopic color={topicColor}>
        {topics.join(', ')}
      </Styled.ItemTopic>
      <Styled.ItemHeadline>
        <IconLink
          {...(headlineColor && { color: headlineColor })}
          isBold
          label={headline}
          linkUrl={headlineLink}
        />
      </Styled.ItemHeadline>
      <Styled.ItemTeaser teaserColor={teaserColor}>{teaser}</Styled.ItemTeaser>
      <Styled.ItemCategory categoryLinkColor={categoryLinkColor}>
        <BasicLink url={categoryLink} text={`See All ${category}`} />
      </Styled.ItemCategory>
    </article>
  );
};
