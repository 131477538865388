import React, { useState, SyntheticEvent } from 'react';
import { AccordionProps, AccordionItem } from './accordion.interface';
import { RichText } from '../rich-text';
// Styles
import {
  AccordionStyled,
  AccordionTitleStyled,
  MuiAccordionStyled,
  MuiAccordionSummaryStyled,
  MuiAccordionDetailsStyled,
  AccordionIndicatorStyled
} from './accordion.styles';

export const FAQAccordion = ({
  variant = 'light',
  itemList,
  headline,
}: AccordionProps) => {

  const [ expanded, setExpanded ] = useState<number | 0>(0);

  const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    if(panel !== expanded) {
      setExpanded(isExpanded ? panel : 0);
    }
  };
  
  if (itemList.length) {
    return (
      <>
        {headline && (
          <AccordionTitleStyled variant={variant}>{headline}</AccordionTitleStyled>
        )}
        <AccordionStyled data-testid="accordion__wrapper">
          {itemList.map((item: AccordionItem, index: number) => (
            <MuiAccordionStyled 
              disableGutters
              elevation={0} 
              square
              TransitionProps={{ unmountOnExit: true }}
              key={`accordion-item_${index}`}
              expanded={expanded === index} 
              onChange={handleChange(index)}
            >
              <MuiAccordionSummaryStyled
                 aria-controls={`panel_${index}`}
                 id={`panel_${index}`}
                 expandIcon={
                  <AccordionIndicatorStyled xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    {expanded === index ? (
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 15H24V18H17.3333H16.5H15.5H14.6667H8V15H14.6667H15.5L16.5 15L17.3333 15Z" fill="#25C9EF"/>
                    ) : (
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 14.6667H24V17.3333H17.3333V24H14.6667V17.3333H8V14.6667H14.6667V8H17.3333V14.6667Z" fill="#25C9EF"/>
                    )}
                  </AccordionIndicatorStyled>
                }
                >
                <p>{item.question}</p>
              </MuiAccordionSummaryStyled>
              <MuiAccordionDetailsStyled>
                <RichText content={item.answer} />
              </MuiAccordionDetailsStyled>
            </MuiAccordionStyled>
          ))}
        </AccordionStyled>
      </>
    );
  }

  return <></>;
};