import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { mq_min } from '@styles/breakpoints';
import { color_dark_grey, color_light_grey } from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';

import { ImageProps } from './image-scrolling.interface';

export const themes = {
  light: {
    imageShadow: color_light_grey,
  },
  mid: {
    imageShadow: color_light_grey,
  },
  dark: {
    imageShadow: color_dark_grey,
  },
};

export const ImageScrollingContainer = styled.div`
  display: none;
  ${mq_min('lg')} {
    display: block;
    flex-basis: 50%;
    padding-left: ${getStyleSizes(50)};
    text-align: right;
    position: relative;
  }
`;

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Image = styled.img<ImageProps>`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  filter: drop-shadow(
    0px 4px 30px ${({ background }) => themes[background]?.imageShadow}
  );
  border-radius: ${getStyleSizes(4)};
  position: sticky;
  top: 50px;
  animation: 1s ${FadeInAnimation};

  ${({ fitHeight }) =>
    `
      top: calc(50% - ${fitHeight}px / 2);
  `}
`;
