import styled from '@emotion/styled';
import { mq_min, mq_max } from '@styles/breakpoints';
import { FormSectionProps } from '../../form-panel.interface';
import { getStyleSizes } from '@styles/styles.utils';
import { 
    color_white,
    color_miami,
    color_lightest_grey
 } from '@src/styles/colors';

export const FormBaselineContainer = styled('div')<FormSectionProps>`
  background-image: url(${({mobile_background}) => mobile_background});
  background-position: top;
  background-size: cover;
  ${mq_min('md')} {
    background-image: url(${({tablet_background}) => tablet_background});
  }
  ${mq_min('lg')} {
    background-image: url(${({desktop_background}) => desktop_background});
  }
  ${mq_min('xl', 720)} {
    background-image: url(${({large_screen_background}) => large_screen_background});
  }
`

export const LogoStyled = styled('img')`
 max-height: ${getStyleSizes(40)};
  ${mq_max('sm')} {
    width: ${getStyleSizes(90)};
    height: auto;
  }
`

export const HeaderContainer = styled('div')`
  height: ${getStyleSizes(46)};
  ${mq_min('md')} {
    height: ${getStyleSizes(54)};
  }
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  > a{
    display: inline-flex;
  }
  a{
    width: auto;
  }
`

export const FormInner = styled('div')`
 width: 100%;
`

export const FormPanelContainer = styled('div')`
  max-width: ${getStyleSizes(1288)};
  margin: 0 auto;
  width: 100%;
  padding: ${getStyleSizes([40,20,49])};
  display: flex;
  justify-content: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  ${mq_min('lg')} {
    padding: ${getStyleSizes([56,24,48])};
    justify-content: space-between;
  }
`;

export const FormPanelCopyStyled = styled('div')`
   ${mq_min('lg')} {
    max-width: ${getStyleSizes(578)};
    width: 50%;
  }
  div[data-testid="rich-text__container"]{
    color: ${color_lightest_grey};
    font-size: ${getStyleSizes(16)};
    font-weight: 400;
    line-height: ${getStyleSizes(24)};
    margin-bottom: ${getStyleSizes(37)};
    ${mq_min('md')} {
      font-size: ${getStyleSizes(18)};
      line-height: ${getStyleSizes(27)};
      margin-bottom: ${getStyleSizes(38)};
    }
    ${mq_min('lg')} {
      padding-right: ${getStyleSizes(85)};
    }
    ul,
    ol {
      padding-left: ${getStyleSizes(4)};
      li {
        display: flex;
        flex-basis: 100%;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: ${getStyleSizes(19)};
        ${mq_min('md')} {
          margin-bottom: ${getStyleSizes(16)};
          width: calc(100% + ${getStyleSizes(15)});
        }
        &:before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'%3E%3Cpath d='M7.352 4.68953C7.2988 4.65 7.24307 4.61429 7.18533 4.58261L0.985552 0.614912L0.962739 0.598325L0.922379 0.574365C0.807993 0.512132 0.678219 0.488105 0.550466 0.505518C0.422712 0.522925 0.303079 0.580938 0.207606 0.671772C0.112132 0.762612 0.0453923 0.881931 0.016319 1.01373C-0.0127477 1.14554 -0.00274758 1.28352 0.0449724 1.40918L1.40145 5.9998L0.0449724 10.5885C-0.00420092 10.7181 -0.0133077 10.8607 0.0189257 10.9961C0.0511523 11.1315 0.123099 11.2528 0.224579 11.3429C0.326059 11.4329 0.451939 11.4872 0.584406 11.498C0.716872 11.5088 0.849226 11.4756 0.962739 11.4031L1.20139 11.2575L2.0595 10.7047L4.03367 9.44593L7.19233 7.4188C7.2466 7.38726 7.29933 7.3528 7.35027 7.3156C7.5508 7.16913 7.71467 6.97386 7.8278 6.74653C7.94093 6.5192 8 6.26647 8 6.00993C8 5.7534 7.94093 5.50066 7.8278 5.27333C7.71467 5.04593 7.5508 4.85073 7.35027 4.70427L7.352 4.68953Z' fill='%2325C9EF'/%3E%3C/svg%3E");
          margin-right: ${getStyleSizes(13)};
          margin-top: 0;
        }
        span{
          &:first-of-type{
            font-weight: 400 !important;
            color: ${color_miami.base};
          }
        }
      }
    }
  }
`;

export const FormPanelFormStyled = styled.div`
  width: 100%;
  max-width: ${getStyleSizes(620)};
  margin-top: ${getStyleSizes(-3)};
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(11)};
  }
  ${mq_min('lg')} {
    max-width: ${getStyleSizes(564)};
    margin-top: ${getStyleSizes(8)};
    width: 50%;
  }
`;

export const PreHeadlineStled = styled('span')`
  color: ${color_white};
  font-size:  ${getStyleSizes(14)};
  font-style: normal;
  font-weight: 400;
  line-height:  ${getStyleSizes(14)};
  letter-spacing: ${getStyleSizes(1.68)};
  text-transform: uppercase;
`

export const HeadlineStyled = styled('h1')`
  color: ${color_white};
  font-size:${getStyleSizes(32)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(38.4)};
  margin: ${getStyleSizes([8,0,24])};
  ${mq_min('md')} {
    font-size:${getStyleSizes(40)};
    line-height: ${getStyleSizes(48)};
    margin: ${getStyleSizes([8,0,32])};
  }
`

export const SocialProofContainer = styled('div')`
  margin-top: ${getStyleSizes(48)};
  width: 100%;
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(47)};
  }
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(32)};
  }
`

export const SocialProofHeadline = styled('h2')`
  color: ${color_white};
  font-size: ${getStyleSizes(14)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(14)};
  letter-spacing: ${getStyleSizes(1.68)};
  text-transform: uppercase;
`

export const SocialProofImageStyled = styled('img')`
  margin-top: ${getStyleSizes(28)}; 
  width: 100%;
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(37)}; 
  }
`

export const LinkInfoStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${getStyleSizes(3)}; 
  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(6)}; 
  }
  a{
    flex-shrink: 0;
    width: auto;
    margin-left: ${getStyleSizes(16)}; 
  }
`

export const LinkTextStyled = styled('span')`
  color: ${color_white};
  font-size: ${getStyleSizes(20)}; 
  font-style: normal;
  font-weight: 600;
  line-height: ${getStyleSizes(28)}; 

  &:hover{
    text-decoration: underline;
  }
`