import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { FeaturesAccordion as AccordionComponent, TextColor } from '@shared/components';

import { AccordionContainer, AccordionInner } from './accordion.styles';
import { AccordionProps } from './accordion.interface';

import { CSFeaturesAccordion } from '@shared/contentstack/contentstack.interface';
import { accordionMapper } from './mappers/accordion.mapper';

export const FeaturesAccordion = ({
  title,
  layout,
  template,
  headline,
  itemList,
  additionalInformationContent,
  additionalInformationTitle,
  uid,
}: AccordionProps) => {
  return (
    <AccordionContainer 
      layout={layout} 
      data-testid="accordion_container"
      template={template?.toLocaleLowerCase()}
    >
      <CMSComponentWrapper 
        uid={uid} 
        maxWidth={1304}
        horizontalPadding={0}
      >
        <AccordionInner>
          <AccordionComponent
            title={title}
            headline={headline}
            layout={layout}
            template={template}
            itemList={itemList?.map(({ summary, details,link, video_id, feature_image, feature_icon, tablet_feature_image, mobile_feature_image }) => ({
              summary: <TextColor>{summary}</TextColor>,
              details,
              link,
              video_id,
              feature_image,
              tablet_feature_image,
              mobile_feature_image,
              feature_icon
            }))}
            summarySize="large"
            additionalInfo={{
              title: additionalInformationTitle,
              content: additionalInformationContent,
            }}
          />
        </AccordionInner>
      </CMSComponentWrapper>
    </AccordionContainer>
  );
};

export const MappedFeaturesAccordion = (data: CSFeaturesAccordion) => {
  return <FeaturesAccordion {...accordionMapper(data)} />;
};
