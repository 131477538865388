import { SimplePromoProps } from '@cms-components/promos/simple-promo/simple-promo.interface';
import { CSSimplePromo } from '@src/shared/contentstack';
import { BackgroundType } from '@src/shared/interfaces/theme.interface';

export function simplePromoMapper(
  query: CSSimplePromo,
  props: {
    centered: boolean;
    background_color: BackgroundType;
  }
): SimplePromoProps {
  const { headline, button, promo_text, uid } = query || {};

  return {
    uid,
    button: {
      isExternal: !!button?.external_link,
      text: button?.link_text,
      url: button?.link_url,
    },
    headline,
    promoText: promo_text,
    isCentered: props.centered,
    backgroundColor: props.background_color,
  };
}
