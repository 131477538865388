import { graphql, useStaticQuery } from 'gatsby';

export const fetchHeaderData = (): any => {
  const data = useStaticQuery(graphql`
    {
      contentstackHeader {
        uid
        assembly_logo {
          link_url
          alt_text
          is_external
          logo_for_dark_header {
            gatsbyImageData(quality: 90, width: 125)
          }
          logo_for_light_header {
            gatsbyImageData(quality: 90, width: 125)
          }
        }
        demo_button {
          external_link
          link_text
          link_url
        }
        menu_sections {
          menu_dropdown {
            uid
            boxed_link_section {
              box_links {
                external_link
                link_text
                link_url
              }
              box_type
            }
            featured_logo {
              image {
                gatsbyImageData(quality: 90, width: 120)
              }
              alt_text
              is_external
              link_text
              link_url
            }
            menu_list {
              link_description
              link {
                external_link
                link_text
                link_url
              }
            }
            optional_see_all_link {
              external_link
              link_text
              link_url
            }
            secondary_logo_section {
              secondary_logos {
                image {
                  gatsbyImageData(quality: 90, width: 120)
                }
                alt_text
                is_external
                link_text
                link_url
              }
              section_title
            }
            section_title
            title
          }
          menu_name {
            external_link
            link_text
            link_url
          }
        }
        sign_in_link {
          external_link
          link_text
          link_url
        }
      }
    }
  `);

  return data?.contentstackHeader;
};
