import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@mui/material/Link';

import * as Styled from './secondary-logo-section.styles';
import { SecondaryLogoSectionProps } from './secondary-logo-section.interface';
import { ImageComponent } from '@shared/components/image-component/image-component';
import { useMainNav } from '@cms-components/header/context/main-nav.context';
import { IconLink } from '@src/shared/components';

export const SecondaryLogoSection = ({ data }: SecondaryLogoSectionProps) => {
  const { section_title, secondary_logos } = data || {};
  const { closeNav } = useMainNav();

  if (section_title && secondary_logos && secondary_logos.length) {
    return (
      <Styled.SecondaryLogoSection>
        <Styled.SecondaryLogoTitle>{section_title}</Styled.SecondaryLogoTitle>
        {secondary_logos.map(
          (
            { image, alt_text = '', link_url, is_external, link_text },
            index
          ) => {
            const { gatsbyImageData, url } = image || {};
            return (
              <Styled.SecondaryLogo key={index} onClick={() => closeNav()}>
                {image ? (
                  is_external ? (
                    <Link href={link_url} target="_blank">
                      <ImageComponent
                        image={gatsbyImageData}
                        alt_text={alt_text}
                        fallback={url}
                        fallback_shadow={false}
                      />
                    </Link>
                  ) : (
                    <GatsbyLink to={link_url}>
                      <ImageComponent
                        image={gatsbyImageData}
                        alt_text={alt_text}
                        fallback={url}
                        fallback_shadow={false}
                      />
                    </GatsbyLink>
                  )
                ) : (
                  <IconLink
                    isBold
                    label={link_text}
                    linkUrl={link_url}
                    isExternal={is_external}
                  />
                )}
              </Styled.SecondaryLogo>
            );
          }
        )}
      </Styled.SecondaryLogoSection>
    );
  }
  return null;
};
