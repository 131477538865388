import styled from '@emotion/styled';
import { getStyleSizes } from '../../styles/styles.utils';
import {
  color_black,
  color_dark_grey,
  color_darkest_grey,
  color_eggshell,
  color_white,
} from '@styles/colors';
import { mq_min } from '../../styles/breakpoints';
import { noTo_font } from '@src/styles/typography';
import {
  FeaturedCardContainerProps,
  FeaturedCardHeadlineProps,
} from './featured-cards.interface';

const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  mid: {
    containerBackground: color_eggshell.base,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    promoTextColor: '#EFF2F8',
  },
};

export const FeaturedCardContainer = styled.div<FeaturedCardContainerProps>`
  padding: ${getStyleSizes([77, 0, 73])};
  background: ${color_eggshell.base};
  text-align: center;
  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0, 81])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};
  }
`;

export const FeaturedCardTitle = styled.h1<FeaturedCardHeadlineProps>`
  text-align: center;
  color: ${color_black};
  font-size: ${getStyleSizes(48)};
  font-style: normal;
  font-weight: 700;
  line-height: ${getStyleSizes(56)};
  margin-bottom: ${getStyleSizes(22)};
`;

export const FeaturedCardSubTitle = styled.p`
  color: ${color_black};
  text-align: center;
  font-size: ${getStyleSizes(26)};
  font-style: normal;
  font-weight: 200;
  line-height: ${getStyleSizes(34)};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(30)};
    line-height: ${getStyleSizes(38)};
    padding: ${getStyleSizes([0,32])};
  }
`;

export const FeaturedCardFeaturesContainer = styled.div`
  text-align: center;
  margin-top: ${getStyleSizes(48)};
  ${mq_min('sm')} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  ${mq_min('md')} {
    margin: ${getStyleSizes([32, -14, 0])};
  }
  ${mq_min('lg')} {
    margin: ${getStyleSizes([21, -14, 0])};
  }
`;

export const FeaturedCardFeature = styled.div`
  text-align: center;
  margin: 10px 2%;
  padding: 2% 0;
  margin-bottom: ${getStyleSizes(38)};
  ${mq_min('sm')} {
    width: 33.33%;
  }
  ${mq_min('md')} {
    &:nth-of-type(1)> h3{
      margin-top: ${getStyleSizes(-5)};
      margin-bottom: 0;
    }
  }
`;

export const FeatureHeading = styled.h3`
  color: ${color_black};
  text-align: center;
  font-size: ${getStyleSizes(26)};
  font-style: normal;
  font-weight: 600;
  line-height: ${getStyleSizes(31.2)};
  margin-top: ${getStyleSizes(-3)};
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(-8)};
  }
  ${mq_min('lg')} {
    font-size: ${getStyleSizes(32)};
    line-height: ${getStyleSizes(38.4)};
  }
`;

export const FeatureSubHeading = styled.p`
  color: ${color_black};
  text-align: center;
  font-family: ${noTo_font};
  font-size:  ${getStyleSizes(16)};
  font-style: normal;
  font-weight: 400;
  line-height:  ${getStyleSizes(24)};
  padding: ${getStyleSizes([18,24,0])};
  ${mq_min('md')} {
    padding: ${getStyleSizes([18,15,0])};
  }
  ${mq_min('lg')} {
    font-size:  ${getStyleSizes(18)};
    line-height:  ${getStyleSizes(27)};
    padding: ${getStyleSizes([18,45,0])};
  }
`;

export const FeaturedCardButtonContainer = styled.div`
  margin-top: ${getStyleSizes(48)};
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(3)};
  }
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(-8)};
  }
`;

export const FeaturedCardImage = styled.img`
  width:${getStyleSizes(108)};
  height:${getStyleSizes(106)};
  margin: 0 auto 32px;
  display: block;
`;
