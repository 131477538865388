import { ThemeProvider } from '@mui/material';
import { primaryTheme } from '@styles/themes';
import { ImageComponent } from '../image-component/image-component';
import { RichText } from '../rich-text';
import { LogoCardProps } from './logo-card.interface';
import {
  Container,
  ContainerFooter,
  ImageContainer,
  Inner,
  LogoCardContainer,
  Title,
  VideoContainer,
} from './logo-card.styles';
import { VideoPlayer } from '../video-player';

export const LogoCard = ({
  title,
  image,
  copy,
  variant = 'default',
  uid,
  display_logo_image,
  display_video,
  video_id,
  playingVideoAsPopup = false,
}: LogoCardProps) => {
  return (
    <ThemeProvider theme={primaryTheme}>
      <LogoCardContainer variant={variant}>
        {((video_id && display_video) ||
          (video_id && !image?.image) ||
          (video_id && !display_video && image?.image)) && (
          <VideoContainer>
            <VideoPlayer
              playingAsPopup={playingVideoAsPopup}
              id={video_id}
              fullwidth={true}
            />
          </VideoContainer>
        )}
        <Container
          id={uid}
          data-testid="logo-card__container"
          variant={variant}
          hasVideo={!!video_id}
        >
          {((image?.image && display_logo_image) ||
            (image?.image && !video_id)) && (
            <ImageContainer
              variant={variant}
              imageHeight={image?.image?.gatsbyImageData?.height}
            >
              <ImageComponent
                image={image?.image?.gatsbyImageData}
                fallback={image?.image?.url}
                alt_text={image.image_alt_text}
              />
            </ImageContainer>
          )}

          <Inner variant={variant}>
            <ContainerFooter>
              {title && <Title>{title}</Title>}
              {copy && <RichText content={copy} />}
            </ContainerFooter>
          </Inner>
        </Container>
      </LogoCardContainer>
    </ThemeProvider>
  );
};
