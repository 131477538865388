import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import BasicLink from '@shared/components/basic-link';

export const BasicIconLink = styled(BasicLink)<any>`
  font-size: ${(props) =>
    props.fontSize ? getStyleSizes(props.fontSize) : 'inherit'};
  font-weight: ${(props) => props.fontWeight};
  text-decoration: none;
`;

export const LinkIcon = styled.span<any>`
    color: ${(props) => (props.color ? props.color : 'inherit')};
    .icon- {
      color: ${(props) => (props.iconColor ? props.iconColor : 'inherit')};
    }
`;

export const LinkWrapper = styled.div<{ font: string }>((props) => ({
  fontFamily: props.font,
}));
