import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';
import { up } from 'styled-breakpoints';

export type NavStateType = 'opened' | 'closed';

export type MainNavProps = {
  navState: NavStateType;
  closeNav: () => void;
  openNav: () => void;
};

const mainNavDefaultValue: MainNavProps = {
  navState: 'closed',
  closeNav: () => {},
  openNav: () => {},
};

const MainNavContext = createContext(mainNavDefaultValue);

const MainNavProvider: FC = ({ children }) => {
  const [navState, setNavState] = useState<NavStateType>('closed');
  const isDesktop = useBreakpoint(up('lg'));

  const closeNav = useCallback(() => {
    if (isDesktop) {
      return setNavState('opened');
    }
    return setNavState('closed');
  }, [isDesktop]);

  const openNav = useCallback(() => {
    if (isDesktop) {
      return setNavState('opened');
    }
    return setNavState('opened');
  }, [isDesktop]);

  return (
    <MainNavContext.Provider value={{ navState, closeNav, openNav }}>
      {children}
    </MainNavContext.Provider>
  );
};

const useMainNav = () => {
  const { navState, openNav, closeNav } = useContext(MainNavContext);

  const toggleNav = useCallback(
    () => (navState === 'closed' ? openNav() : closeNav()),
    [closeNav, navState, openNav]
  );

  if (!navState) {
    throw new Error('You need the MainNavProvider');
  }

  return {
    navState,
    toggleNav,
    openNav,
    closeNav,
  };
};

export { MainNavProvider, useMainNav };
