import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as Styled from './image-component.styles';

export const ImageComponent = ({
  image,
  alt_text = '',
  fallback = null,
  fallback_shadow = true,
  is_icon = false,
}: {
  image?: IGatsbyImageData;
  alt_text?: string;
  fallback?: string | null;
  fallback_shadow?: boolean | null;
  is_icon?: boolean;
}) => {
  return !!image ? (
    <GatsbyImage image={image} alt={alt_text} />
  ) : !!fallback ? (
    <Styled.FallbackImageContainer
      fallbackShadow={fallback_shadow}
      is_icon={is_icon}
    >
      <img src={fallback} alt={alt_text} />
    </Styled.FallbackImageContainer>
  ) : (
    <></>
  );
};
