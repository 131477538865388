import styled from '@emotion/styled';
import { 
    color_black, 
    color_white,
    color_lightest_grey
} from '@src/styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

export const LeadSectionContainer = styled('div')`
    background-color: ${color_black};
    padding: ${getStyleSizes([56, 20, 50])};
    ${mq_min('md')} {
        padding: ${getStyleSizes([63, 40, 84])};
    }
    ${mq_min('lg')} {
        padding: ${getStyleSizes([80, 0, 100])};
    }
`;

export const HeadlineStyled = styled('h1')`
    color: ${color_white};
    text-align: center;
    font-size:  ${getStyleSizes(40)};
    font-style: normal;
    font-weight: 400;
    line-height: ${getStyleSizes(48)};
    ${mq_min('md')} {
        font-size:  ${getStyleSizes(48)};
        line-height: ${getStyleSizes(57.6)};
    }
`

export const SubheadlineStyled = styled('p')`
    color: ${color_lightest_grey};
    text-align: center;
    font-size: ${getStyleSizes(16)};
    font-style: normal;
    font-weight: 400;
    line-height: ${getStyleSizes(24)};
    margin: ${getStyleSizes([16, 0, 32])};
    ${mq_min('md')} {
        font-size:  ${getStyleSizes(18)};
        line-height: ${getStyleSizes(27)};
        margin: ${getStyleSizes([18, 0, 32])};
    }
`

export const CTAContainer = styled('div')`
    text-align: center;
`
