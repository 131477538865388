import { ContentPanelProps } from '../content-panel.interface';

import { CSContentPanel, CSContentPanelGroup } from '@shared/contentstack';

export function contentPanelMapper({
  background_color,
  group = [],
  image_scrolling_behavior,
  panel_headline,
  site_configuration,
  uid,
}: CSContentPanel): ContentPanelProps {
  return {
    uid,
    background: background_color,
    imageScrolling: image_scrolling_behavior,
    headline: panel_headline,
    contentGroupList: group?.map(
      ({
        eyebrow,
        headline,
        promo_copy,
        promo_image,
        promo_link,
        display_image,
        display_video,
        video_id,
      }: CSContentPanelGroup) => ({
        eyebrow,
        headline,
        copy: promo_copy,
        imageAltText: promo_image?.image_alt_text,
        image: (() => {
          const image = promo_image?.image;
          return {
            src: image ? image?.url : '',
            width: image?.gatsbyImageData?.width || 0,
            height: image?.gatsbyImageData?.width || 0,
          };
        })(),
        link: {
          text: promo_link?.link_text,
          url: promo_link?.link_url,
          isExternal: Boolean(promo_link?.external_link),
        },
        displayImage: display_image,
        displayVideo: display_video,
        videoId: video_id,
      }),
      site_configuration
    ),
  };
}
