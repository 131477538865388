import { useState, useEffect } from 'react';

export interface IUseMarketo {
  baseUrl: string;
  munchkinId: string;
  formId: string;
  callback: any;
  onLoadCallback: any;
  exists: boolean;
}

function appendScript(baseUrl: string, setScriptLoaded: any) {
  const win = window as any;
  if (win.MktoForms2) return setScriptLoaded(true);
  const script = document.createElement('script');
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (win.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({
  baseUrl,
  munchkinId,
  formId,
  callback,
  onLoadCallback,
  exists,
}: IUseMarketo) {
  const win = window as any;
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    // if the script is loaded and a form doesn't already exist, initialize the form...
    if (scriptLoaded && !exists) {
      const mkForms = win.MktoForms2;
      mkForms.loadForm(baseUrl, munchkinId, formId, callback);
      mkForms.whenReady(onLoadCallback);
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [scriptLoaded, baseUrl, munchkinId, formId, callback]);
}

export default useMarketo;
