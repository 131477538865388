import { ImageGridProps } from '../image-grid.interface';
import { CSImageAlt, CSImageGrid } from '@shared/contentstack/contentstack.interface';

export function imageGridMapper({
  title,
  preheadline,
  grid_images,
  badges,
  site_configuration,
}: CSImageGrid): ImageGridProps {
  return {
    title,
    preheadline,
    badges,
    itemList:
      grid_images?.map(({ image, image_alt_text }: CSImageAlt)=>({
        image,
        image_alt_text
      }))|| [],
    site_configuration,
  };
}
