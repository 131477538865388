import React from 'react';

import { HeroBannerContainer } from '@cms-components/hero-banner/hero-banner.styles';
import { BannerOverhang, BannerSimple, BannerStandard } from './components';
import { BannerForm } from './components/banner-form/banner-form';

export const HeroBanner = (props: any) => {
  const bannerType = props?.type;
  const ComponentRenderer = () => {
    switch (bannerType) {
      case 'overhang_banner':
        return <BannerOverhang {...props} />;
      case 'simple_banner':
        return <BannerSimple {...props} />;
      case 'form_banner':
        return <BannerForm {...props} />;
      default:
        return <BannerStandard {...props} />;
    }
  };

  if (bannerType) {
    return (
      <HeroBannerContainer
        id={props.uid}
        heroType={bannerType}
        data-testid="hero-banner__container"
      >
        {ComponentRenderer()}
      </HeroBannerContainer>
    );
  }

  return <></>;
};

export const CMSHeroBanner = (props: any) => {
  return <HeroBanner {...props} />;
};
