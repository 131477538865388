import React, { useEffect } from 'react';
import {
  NavItemState,
  NavMenuItemProps,
} from '@cms-components/header/components/nav/nav.interface';
import { useNav } from '@cms-components/header/context/nav.context';
import {
  BasicLinkStyled,
  MenuItemLabel,
  NavItemIconStyled,
  NavItemLinkStyled,
  NavListItemStyled,
} from '@cms-components/header/components/nav-menu-item/nav-menu-item.styles';
import BasicLink from '@shared/components/basic-link';
import { usePageHeaderStyle } from '@shared/context/pageHeaderStyle.context';
import { useMainNav } from '@cms-components/header/context/main-nav.context';
import { CMSNavPanel } from '@cms-components/header/components/nav-panel/nav-panel';

export const NavMenuItem = ({
  menuSection,
  id,
  menuDropDown,
}: NavMenuItemProps) => {
  const { isOpen, toggle, closeAll } = useNav(id);
  const { closeNav } = useMainNav();
  const { currentColor } = usePageHeaderStyle();
  const { external_link, link_text, link_url } = menuSection || {};
  const state: NavItemState = isOpen ? 'open' : 'closed';
  const closeNavHandler = ($e: MouseEvent) => {
    const $target = $e.target as Element;
    if (!$target.closest('#main-header')) {
      closeAll();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mouseover', ($e) => closeNavHandler($e));
    }
    return () =>
      document.removeEventListener('mouseover', ($e) => closeNavHandler($e));
  }, [isOpen]);

  if (!link_url && !!menuSection) {
    return (
      <NavListItemStyled>
        <NavItemLinkStyled
          role="menuitem"
          onClick={() => toggle()}
          color={currentColor}
        >
          <MenuItemLabel state={state}>{link_text}</MenuItemLabel>
          <NavItemIconStyled state={state} />
        </NavItemLinkStyled>
        {menuDropDown && (
          <CMSNavPanel state={state} menu_dropdown={menuDropDown} />
        )}
      </NavListItemStyled>
    );
  }

  return (
    <NavListItemStyled>
      <BasicLinkStyled color={currentColor} onClick={() => closeNav()}>
        <BasicLink url={link_url} text={link_text} isExternal={external_link} />
      </BasicLinkStyled>
    </NavListItemStyled>
  );
};
