import React from 'react';

import CMSComponentWrapper from '@shared/components/component-wrapper';
import { ImageComponent, RichText, TextColor } from '@shared/components';

import {
  LCArticleDetailContainer,
  Headline,
  ImageContainer,
  ImageCaption,
  Content,
  Eyebrow,
  Topics,
  PublicationDate,
  BoxedPromoContainer,
} from './lc-article-detail.styles';
import { LCArticleDetailProps } from './lc-article-detail.interface';
import { BoxedPromoWrapper } from '@cms-components/promos/promo-wrapper/boxed-promo-wrapper';
import { SocialNetworks } from './components/social-networks';

export const LCArticleDetail = ({
  headline,
  image,
  imageCaption,
  content,
  topics,
  publicationDate,
  boxedPromoData,
}: LCArticleDetailProps) => {
  return (
    <LCArticleDetailContainer id={content?.uid} data-testid="lc-article-detail__container">
      <CMSComponentWrapper maxWidth={836}>
        <Eyebrow>
          <Topics>{topics?.join(', ')}</Topics>
          <PublicationDate>{publicationDate}</PublicationDate>
        </Eyebrow>
        <Headline>
          <TextColor>{headline}</TextColor>
        </Headline>

        {image && image.image && (
          <>
            <ImageContainer>
              <ImageComponent
                image={image.image.gatsbyImageData}
                alt_text={image.image_alt_text}
                fallback={image.image.url}
              />
            </ImageContainer>
            {imageCaption && <ImageCaption>{imageCaption}</ImageCaption>}
          </>
        )}

        <Content>
          <RichText content={content} />
        </Content>
      </CMSComponentWrapper>

      {!!boxedPromoData?.length && (
        <BoxedPromoContainer>
          {BoxedPromoWrapper({ boxed_promo: boxedPromoData }, 836)}
        </BoxedPromoContainer>
      )}

      <SocialNetworks />
    </LCArticleDetailContainer>
  );
};
