import React from 'react';

import { Button as MuiButton } from '@mui/material';

import { mui_sizes, mui_variants } from '@styles/themes';
import { color_miami } from '@styles/colors';
import { primary_button_icon_colors } from '@styles/elements';

import { ArrowIcon } from '@shared/components';

import { PrimaryButtonProps } from './primary-button.interface';
import { ButtonLabel, StyledLink } from './primary-button.styles';

/** Button Component
 *
 * @label the button text [required]
 * @fontSize the size of the text if you don't want it inherited from the parent
 * @isBold whether the text should be bolded
 * @path the url for the button. paths without http or https will be treated as internal
 * @variant MUI: contained, outlined, text
 * @size MUI: large, medium, small
 * @color MUI: color palette
 * @icon arrow icon color: text, blue, undefined. if undefined, the icon won't display
 *
 * @param props
 * @constructor
 */

const ButtonComponent = (props: PrimaryButtonProps) => {
  const {
    label,
    fontSize = 'inherit',
    isBold = true,
    path = undefined,
    fullwidth = false,
    variant = mui_variants[0],
    size = mui_sizes[0],
    color = color_miami.palette,
    icon = undefined,
    ariaLabel = undefined,
    onClick,
  } = props || {};

  const attr = {
    onClick,
    size,
    color,
    variant,
    ...(!!path ? { href: path } : {}),
  };

  return (
    <MuiButton
      aria-label={ariaLabel}
      sx={{ fontSize }}
      {...attr}
      disableElevation
      fullWidth={fullwidth}
    >
      <ButtonLabel weight={isBold ? 600 : 400}>{label}</ButtonLabel>
      {!!icon && (
        <ArrowIcon
          sx={{ fontSize: 'inherit', color: primary_button_icon_colors[icon] }}
        />
      )}
    </MuiButton>
  );
};

// Test to see if the path provided is an external one: [http, https]
// Can add additional tests as needed
const linkIsExternal = (path: string) => {
  const regex: RegExp = new RegExp(/(http(s)?:)/, 'gi');
  return regex.test(path);
};

const PrimaryButton = (props: PrimaryButtonProps) => {
  const { path, ...rest } = props || {};
  // If no path is provided, the button likely has an onClick handler so omit [path] from the props
  if (!path) {
    return <ButtonComponent {...rest} />;
  }
  // If the link is an external one, pass the [path] to be added as an href. MUI will handle the rest
  // If the link is an internal one, wrap the button in a Gatsby Link and omit the path from the button props to
  // avoid nested links.
  return linkIsExternal(path) ? (
    <ButtonComponent {...props} />
  ) : (
    <StyledLink to={path || ''}>
      <ButtonComponent {...rest} />
    </StyledLink>
  );
};

export default PrimaryButton;
