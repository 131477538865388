export const base_font_pixel_size = 16;

export const causten_font = `'Causten', Roboto, Helvetica, Arial, sans-serif`;

export const noTo_font = `'NoToSans', Roboto, Helvetica, Arial, sans-serif`;

export const global_typography = {
  copy: {
    base: 16,
    small: 13,
  },
};
