import React from 'react';
import {
  BasicLinkStyled,
  PanelLinkDescriptionStyled,
  PanelLinksContainerStyled,
  PanelLinksStyled,
  PanelLinksTitleStyled,
  PanelLinkStyled,
} from './panel-links.styles';
import { PanelLinksProps } from './panel-links.interface';
import { CSMenuLink } from '@shared/interfaces/links.resolver';
import BasicLink from '@shared/components/basic-link';
import { useMainNav } from '@cms-components/header/context/main-nav.context';

function renderMenus(menus: CSMenuLink[]) {
  const { closeNav } = useMainNav();
  return menus?.map((menu, index) => {
    const { link_description, link } = menu || {};
    const { link_url, link_text, external_link } = link || {};
    return (
      <PanelLinkStyled key={index}>
        <BasicLinkStyled onClick={() => closeNav()}>
          <BasicLink
            text={link_text}
            url={link_url}
            isExternal={external_link}
          />
        </BasicLinkStyled>
        <PanelLinkDescriptionStyled>
          {link_description}
        </PanelLinkDescriptionStyled>
      </PanelLinkStyled>
    );
  });
}

export const PanelLinks = ({ menus, title, variant }: PanelLinksProps) => {
  return (
    <PanelLinksStyled>
      {title && <PanelLinksTitleStyled>{title}</PanelLinksTitleStyled>}
      {menus && menus.length && (
        <PanelLinksContainerStyled variant={variant}>
          {renderMenus(menus)}
        </PanelLinksContainerStyled>
      )}
    </PanelLinksStyled>
  );
};
