import React from 'react';
import { ArrowIcon } from '@shared/components';
import { color_darkest_grey } from '@styles/colors';
import { ArrowControlProps } from './arrow-control.interface';
import { Container } from './arrow-control.styles';

export const ArrowControl = ({
  left = false,
  size = 34,
  color = color_darkest_grey,
}: ArrowControlProps) => {
  return (
    <Container
      data-testid="arrow-control__container"
      left={left}
      size={size}
      color={color}
    >
      <ArrowIcon sx={{ fontSize: size / 2.5, color }} />
    </Container>
  );
};
