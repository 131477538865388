import React from 'react';
import { AccordionItem, AccordionProps } from './accordion.interface';
// Styles
import {
  AccordionAddtionalInfoContentStyled,
  AccordionAddtionalInfoStyled,
  AccordionAdditionalInfoTitleStyled,
  AccordionDetailTextStyled,
  AccordionStyled,
  AccordionSummaryTextStyled,
  AccordionTitleStyled,
  ExpandMoreIconStyled,
  MuiAccordionDetailsStyled,
  MuiAccordionStyled,
  MuiAccordionSummaryStyled,
} from './accordion.styles';
import { RichText } from '@shared/components';

export const Accordion = ({
  variant = 'light',
  itemList,
  title,
  summarySize = 'normal',
  additionalInfo,
}: AccordionProps) => {
  const hasAdditionalInfo =
    additionalInfo && (additionalInfo?.title || additionalInfo?.content);

  if (itemList.length) {
    return (
      <AccordionStyled data-testid="accordion__wrapper">
        {title && (
          <AccordionTitleStyled variant={variant}>{title}</AccordionTitleStyled>
        )}
        {itemList.map((item: AccordionItem, index) => {
          return (
            <MuiAccordionStyled
              disableGutters
              elevation={0}
              square
              TransitionProps={{ unmountOnExit: true }}
              key={`accordion-item_${index}`}
            >
              <MuiAccordionSummaryStyled
                size={summarySize}
                expandIcon={<ExpandMoreIconStyled variant={variant} />}
                aria-controls={`panel_${index}`}
                id={`panel_${index}`}
              >
                <AccordionSummaryTextStyled
                  size={summarySize}
                  variant={variant}
                >
                  {item.summary}
                </AccordionSummaryTextStyled>
              </MuiAccordionSummaryStyled>
              <MuiAccordionDetailsStyled>
                <AccordionDetailTextStyled size={summarySize} variant={variant}>
                  <RichText content={item.details} />
                </AccordionDetailTextStyled>
              </MuiAccordionDetailsStyled>
            </MuiAccordionStyled>
          );
        })}
        {hasAdditionalInfo && (
          <AccordionAddtionalInfoStyled>
            <AccordionAdditionalInfoTitleStyled variant={variant}>
              {additionalInfo?.title}
            </AccordionAdditionalInfoTitleStyled>
            <AccordionAddtionalInfoContentStyled variant={variant}>
              <RichText content={additionalInfo?.content} />
            </AccordionAddtionalInfoContentStyled>
          </AccordionAddtionalInfoStyled>
        )}
      </AccordionStyled>
    );
  }

  return <></>;
};
