import styled from '@emotion/styled';
import { 
    color_white,
    color_light_grey,
    color_indigo,
    color_black
 } from '@src/styles/colors';
import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { FormSectionProps } from '../../form-panel.interface';

export const FormBaselineContainer = styled('div')<FormSectionProps>`
  background-image: url(${({mobile_background}) => mobile_background});
  background-position: top;
  background-size: cover;
  padding: ${getStyleSizes([48, 0, 64])};
  overflow: hidden;
  min-height: calc(100vh - 80px);
  ${mq_min('md')} {
    background-image: url(${({tablet_background}) => tablet_background});
    padding: ${getStyleSizes([48, 0, 102])};
  }
  ${mq_min('lg')} {
    background-image: url(${({desktop_background}) => desktop_background});
  }
  ${mq_min('xl', 720)} {
    background-image: url(${({large_screen_background}) => large_screen_background});
  }
`

export const FormInner = styled('div')`
  ${mq_min('md')} {
     padding: ${getStyleSizes([0,12])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes(0)};
  }
`

export const FormPanelContainer = styled('div')`
  max-width: ${getStyleSizes(1240)};
  margin: 0 auto;
  padding: ${getStyleSizes([24,24,36])};
  width: 100%;
  border-radius: ${getStyleSizes(24)};
  background: rgba(0, 0, 0, .4);
  display: flex;
  justify-content: left;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  position: relative;
  z-index: 1;
  ${mq_min('lg')} {
    padding: ${getStyleSizes(24)};
  }
`;

export const FormPanelFormStyled = styled.div`
  max-width: 100%;
  width: 100%;
  background: 0 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  ${mq_min('lg')} {
    max-width: ${getStyleSizes(570)};
    width: 50%;
  }
`;

export const FormPanelTitle = styled.h2`
  font-size: ${getStyleSizes(24)};
  margin-bottom: 0;

  ${mq_min('md')} {
    margin-bottom: ${getStyleSizes(15)};
  }
`;

export const FormPanelCopyStyled = styled('div')`
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  ${mq_min('md')} {
    padding-left: ${getStyleSizes(40)};
    padding-right: ${getStyleSizes(30)};
  }
  ${mq_min('lg')} {
    width: 50%;
    max-width: ${getStyleSizes(525)};
    padding-left: ${getStyleSizes(80)};
    padding-right: ${getStyleSizes(30)};
  }

  div[data-testid="rich-text__container"]{
    padding-top: ${getStyleSizes(23)};
    ${mq_min('lg')} {
      padding-top: ${getStyleSizes(30)};
    }
    h2{
        color: ${color_white};
        font-size: ${getStyleSizes(25.898)};
        font-style: normal;
        font-weight: 600;
        line-height: ${getStyleSizes(30)};
        padding-top: ${getStyleSizes(15)};
        padding-bottom: ${getStyleSizes(16)};
        margin-bottom: 0;
    }
    p{
      color: ${color_white};
      font-size: ${getStyleSizes(15)};
      font-style: normal;
      font-weight: 400;
      line-height: ${getStyleSizes(23)};
      padding-bottom: ${getStyleSizes(2)};

      &:nth-of-type(3){
        margin-bottom: ${getStyleSizes(3)};
      }
      > span:last-child{
        color: ${color_light_grey};
      }
      > a{
        font-weight: 400;
        &:hover,
        &:active{
          color: ${color_indigo.base};
        }
      }
    }
  }
  
`;

export const PageHeadlineStyled = styled('h1')`
  color: ${color_white};
  font-size: ${getStyleSizes(32)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(38.4)};
  text-align: center;
  margin-bottom: ${getStyleSizes(49)};

  ${mq_min('md')} {
    font-size: ${getStyleSizes(48)};
    line-height: ${getStyleSizes(57.6)};
  }
  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(50)};
  }
`;

export const LogoContainer = styled('div')`
  padding-bottom: ${getStyleSizes(63)};
  max-width: ${getStyleSizes(1280)};
  margin: auto;
  ${mq_min('md')} {
    padding-bottom: ${getStyleSizes(62)};
  }
  ${mq_min('lg')} {
    padding-bottom: ${getStyleSizes(62)};
    padding-left: ${getStyleSizes(20)};
    padding-right: ${getStyleSizes(20)};
  }
  img{
      max-width:  ${getStyleSizes(164)};
      height:  ${getStyleSizes(32)};
      -o-object-fit: contain;
      object-fit: contain;
      width: 100%;
      display: block;
      margin: 0 auto;
      ${mq_min('md')} {
        max-width:  ${getStyleSizes(165)};
      }
      ${mq_min('lg')} {
        margin: 0;
      }
  }
`

export const FooterStyled = styled('div')`

  div[data-testid="footer__container"]{
    position: relative;
    z-index: 99;
    background: ${color_black};

    > section:first-child{
      display: none;
    }
    > section:last-child{
      margin: 0;
      max-width: ${getStyleSizes(1280)};
      margin: 0 auto;
      padding: ${getStyleSizes([0,20])};
      border-top: 1px solid ${color_black};
      ${mq_min('md')} {
        padding: ${getStyleSizes([0,32])};
      }
      ${mq_min('lg')} {
        padding: ${getStyleSizes([0,20])};
      }
      >div{
        padding: 0px;

        > section{
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding:  ${getStyleSizes([19,0,25])};
          gap: 0;
          ${mq_min('md')} {
            padding:  ${getStyleSizes([25,0,31])};
          }
          ${mq_min('lg')} {
            padding:  ${getStyleSizes([31,0,31])};
          }
          > div:last-child{
            width: 100%; 
            ${mq_min('md')} {
              width: auto;
            }
          }

          ul{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

              li{
                margin: ${getStyleSizes([5,8,11])} !important;
                ${mq_min('md')} {
                  margin: ${getStyleSizes([4,17,0,0])} !important;
                }
                ${mq_min('lg')} {
                  margin: ${getStyleSizes([0,17,0,0])} !important;
                }
              a{
                font-family: 'Causten';
                color: ${color_light_grey};
                font-size: ${getStyleSizes(13)};
                font-style: normal;
                font-weight: 400;
                line-height: ${getStyleSizes(19.5)};
                -webkit-text-decoration-line: underline;
                text-decoration-line: underline;

                &:hover{
                  color: ${color_white};
                }
              }
            }
          }

          span{
            font-family: 'Causten';
            color: ${color_light_grey};
            font-size: ${getStyleSizes(13)};
            font-style: normal;
            font-weight: 400;
            line-height: ${getStyleSizes(19.5)};
            display: block;
            margin-top: ${getStyleSizes(6)};
            text-align: center;
            width: 100%;
            ${mq_min('md')} {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
`