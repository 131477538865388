import React from 'react';

import CMSComponentWrapper from '@shared/components/component-wrapper';
import { ContactForm } from '@shared/components/contact-form';
import * as CSFormPanelinterfaces from '@src/shared/interfaces/cs-form-panel.interface';

import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import { getLinearGradient } from '@styles/styles.utils';

import { background_color_mapper, text_color_mapper } from '@styles/colors';

import * as Styled from './form-panel.styles';
import { RichText } from '@src/shared/components';
import { hasContent } from '@src/shared/utilities/string.utils';
import { default_breakpoints } from '@styles/breakpoints';

export const FormPanel = (props: CSFormPanelinterfaces.CSFormPanelProps) => {
  const isDesktop = useBreakpoint(up('md'));

  const { background_color, secondary_background_color, copy, form, uid } =
    props;

  const isValidFormConfig: boolean =
    !!form?.base_url && !!form.form_id && !!form.tracking_code;

  // in mobile, we need a single color background
  // in desktop, it may be two colors depending on what the user picked
  const secondaryBackgoundColor = isDesktop
    ? secondary_background_color
    : background_color;

  return (
    <section id={uid} data-testid="form-panel__container">
      <CMSComponentWrapper
        maxWidth={default_breakpoints.cw}
        backgroundColor={getLinearGradient(
          background_color_mapper[background_color],
          background_color_mapper[secondaryBackgoundColor],
          80
        )}
      >
        <Styled.FormPanelContainer centered={!hasContent(copy?.children)}>
          <Styled.FormPanelForm>
            {isValidFormConfig ? (
              <ContactForm
                base_url={form?.base_url}
                title={form?.form_title}
                success_message={form?.success_message}
                tracking_code={form?.tracking_code}
                form_id={form?.form_id}
              />
            ) : (
              <span>Please provide a form ID, base URL and tracking code</span>
            )}
          </Styled.FormPanelForm>

          {hasContent(copy?.children) && (
            <Styled.FormPanelCopy
              id={copy.uid}
              color={text_color_mapper[background_color]}
            >
              <RichText content={copy} />
            </Styled.FormPanelCopy>
          )}
        </Styled.FormPanelContainer>
      </CMSComponentWrapper>
    </section>
  );
};
