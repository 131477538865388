import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { BadgesProps } from './image-grid.interface';
import { color_black, color_white, color_light_grey } from '@src/styles/colors';

export const ImageGridContainer = styled('div')<BadgesProps>`
  ${({ badges }) => badges && `
    background-color: ${color_black};
  `}
  padding: ${getStyleSizes([64, 0, 41])};
  position: relative;
  overflow: hidden;
  ${mq_min('md')} {
    padding: ${getStyleSizes([64, 0, 57])}
  }
  ${mq_min('lg')} {
    padding-top: ${getStyleSizes(100)};
    padding-bottom: ${getStyleSizes(94)};
  }
`;

export const ImageGridInner = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  ${mq_min('lg')} {
    width: 100%;
  }
`;

export const PreHeadlineStyled = styled('div')`
  color: ${color_light_grey};
  font-size: ${getStyleSizes(18)};
  font-style: normal;
  font-weight: 400;
  line-height: ${getStyleSizes(28.8)};
  letter-spacing: ${getStyleSizes(1.44)};
  text-transform: uppercase;
  text-align: center;
  margin: 0px auto ${getStyleSizes(16)};
  width: 100%;
  padding: ${getStyleSizes([0,40])};
  ${mq_min('md')} {
    margin: 0px auto ${getStyleSizes(30)};
  }
  span:last-child{
    color: ${color_white};
  }
`

export const BadgesContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: ${getStyleSizes(1012)};
  width: 100%;
  margin: auto;
  justify-content: space-between;
  ${mq_min('md')} {
    padding: ${getStyleSizes([0,26,0,12])};
  }
`
export const BadgeItemContainer = styled('div')`
  width: 48%;
  text-align: center;
  ${mq_min('md')} {
    width: 20%;
  }
  &:nth-of-type(2) {
    ${mq_min('md')} {
      padding-right: ${getStyleSizes(21)};
    }
  }
  &:nth-of-type(3) {
    ${mq_min('md')} {
      padding-right: ${getStyleSizes(27)};
    }
    ${mq_min('lg')} {
      padding-right: ${getStyleSizes(13)};
    }
    img{
      padding-left: ${getStyleSizes(12)};
      ${mq_min('md')} {
        padding-left: 0;
        padding-right: ${getStyleSizes(11)};
      }
    }
  }
  &:nth-of-type(4) {
    padding-right: ${getStyleSizes(28)};
    ${mq_min('md')} {
      padding-right: 0;
    }
  }
`
export const BadgeItemStyled = styled('img')`
  height:  ${getStyleSizes(162)};
  max-width: ${getStyleSizes(134)};
  object-fit: contain;
`