import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import {
  color_blue,
  color_darkest_grey,
  color_lightest_grey,
  color_miami,
  color_white,
} from '@styles/colors';
import { getStyleBorder, getStyleSizes } from '@styles/styles.utils';
import { zIndices } from '@styles/z-indices';
import { BackgroundProp, VariantProp } from './quote-promo.interface';
import { causten_font, noTo_font } from '@styles/typography';

export const themes = {
  light: {
    containerBackground: color_white,
    quoteColor: color_darkest_grey,
    attributionColor: color_blue,
    firmColor: color_darkest_grey,
  },
  mid: {
    containerBackground: color_lightest_grey,
    quoteColor: color_darkest_grey,
    attributionColor: color_blue,
    firmColor: color_darkest_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    quoteColor: color_white,
    attributionColor: color_miami.base,
    firmColor: color_white,
  },
};

export const QuotePromoContainer = styled.div<BackgroundProp & VariantProp>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ background }) => themes[background]?.containerBackground};
  position: relative;
  z-index: ${zIndices.quotePromo};

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      padding: 0;
      overflow: hidden;
  `}

  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};

    ${({ variant }) =>
      variant === 'full-width' &&
      `
      padding: 0;
    `}
  }
`;

export const QuotePromoInner = styled.div<BackgroundProp & VariantProp>`
  border: ${getStyleBorder()};
  border-radius: ${getStyleSizes(8)};
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding: ${getStyleSizes([32, 0])};

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      border: 0;
      border-radius: 0;
      overflow: initial;
      position: initial;
      padding: 0;
  `}

  ${mq_min('lg')} {
    flex-direction: row;
    align-items: center;
    padding: ${getStyleSizes([60, 108])};
    justify-content: space-between;

    ${({ variant, background }) =>
      variant === 'full-width' &&
      `
        padding: 0;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          z-index: 20;
          width: 50%;
          height: 100%;
          background: ${themes[background]?.containerBackground};
          opacity: 0.6;
        }
    `}
  }
`;

export const DetailContainer = styled.div<VariantProp>`
  padding: ${getStyleSizes([24, 28, 0, 28])};
  text-align: center;

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      padding: ${getStyleSizes([52, 0])};
      text-align: left;
  `}

  ${mq_min('lg')} {
    flex-basis: 50%;
    padding-top: 0;
    text-align: left;

    ${({ variant }) =>
      variant === 'full-width' &&
      `
        padding: ${getStyleSizes([140, 100, 120, 0])};
        position: relative;
        z-index: 30;
    `}
  }
`;

export const ImageContainer = styled.div<VariantProp>`
  padding: ${getStyleSizes([0, 28])};
  display: flex;
  justify-content: center;

  ${({ variant }) =>
    variant === 'full-width' &&
    `
      padding: 0;
      margin-right: ${getStyleSizes(-20)};
      margin-left: ${getStyleSizes(-20)};
      justify-content: flex-end;
  `}

  ${mq_min('lg')} {
    flex-basis: 50%;
    justify-content: flex-end;
    max-width: ${getStyleSizes(400)};
    max-height: ${getStyleSizes(270)};

    ${({ variant }) =>
      variant === 'full-width' &&
      `
        margin-right: 0;
    `}
  }

  img {
    object-fit: contain !important;
    object-position: center;
  }
`;

export const WrapperFullwidth = styled.div`
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  max-height: initial;
  display: block;
  margin-left: auto;
  margin-right: auto;
  ${mq_min('lg')} {
    position: absolute;
  }
`;

export const Quote = styled.div<BackgroundProp>`
  font-family: ${causten_font};
  font-size: ${getStyleSizes(26)};
  line-height: ${getStyleSizes(36)};
  color: ${({ background }) => themes[background]?.quoteColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(28)};
  }
`;

export const Attribution = styled.p<BackgroundProp>`
  font-family: ${causten_font};
  font-size: ${getStyleSizes(18)};
  color: ${({ background }) => themes[background]?.attributionColor};
  margin-top: ${getStyleSizes(32)};

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(20)};
  }
`;

export const Firm = styled.p<BackgroundProp>`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(13)};
  font-weight: 200;
  color: ${({ background }) => themes[background]?.firmColor};
  margin-top: ${getStyleSizes(12)};
`;
