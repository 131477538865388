import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { noTo_font, causten_font, global_typography } from '@styles/typography';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_light_grey,
  color_blue,
} from '@styles/colors';

export const LCArticleDetailContainer = styled.div`
  padding: ${getStyleSizes([32, 0])};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([72, 0])};
  }
`;

export const Eyebrow = styled.p`
  font-size: ${getStyleSizes(12)};
  margin-bottom: ${getStyleSizes(16)};

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(22)};
  }
`;

export const Topics = styled.span`
  text-transform: uppercase;
  color: ${color_blue};
  font-weight: 600;
`;

export const PublicationDate = styled.span`
  color: ${color_darkest_grey};
  font-weight: 200;
  font-family: ${noTo_font};
  margin-left: ${getStyleSizes(12)};
`;

export const Headline = styled.h1`
  font-size: ${getStyleSizes(34)};
  line-height: ${getStyleSizes(36)};
  font-weight: 400;
  color: ${color_darkest_grey};
  margin-bottom: ${getStyleSizes(32)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(50)};
    line-height: ${getStyleSizes(52)};
    margin-bottom: ${getStyleSizes(64)};
  }
`;

export const ImageContainer = styled.div`
  margin: ${getStyleSizes([0, -20])};

  ${mq_min('lg')} {
    margin: 0;
  }

  .gatsby-image-wrapper {
    &,
    & picture {
      width: 100%;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export const ImageCaption = styled.p`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(12)};
  font-weight: 200;
  color: ${color_dark_grey};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(13)};
  }
`;

export const Content = styled.div`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(global_typography.copy.base)};
  font-weight: 200;
  color: ${color_dark_grey};
  margin-top: ${getStyleSizes(24)};

  blockquote {
    margin: ${getStyleSizes([28, 0])};
    border: 1px solid ${color_light_grey};
    border-radius: ${getStyleSizes(8)};
    padding: ${getStyleSizes([24, 32])};
    margin-bottom: ${getStyleSizes([10])};
    font-family: ${causten_font};
    font-size: ${getStyleSizes(26)};
    font-weight: 400;
    line-height: ${getStyleSizes(34)};
    color: ${color_blue};
  }

  ul {
    padding-left: ${getStyleSizes(20)};
  }

  ol {
    padding-left: ${getStyleSizes(20)};
    li::marker {
      color: ${color_darkest_grey};
      font-weight: 400;
    }
  }

  h2,
  h3 {
    color: ${color_darkest_grey};
    font-weight: 400;
    margin-bottom: ${getStyleSizes(12)};
  }

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(64)};

    blockquote {
      padding: ${getStyleSizes([40, 48])};
      margin: ${getStyleSizes([0, 0, 10])};
      font-size: ${getStyleSizes(28)};
      line-height: ${getStyleSizes(36)};
    }

    ul,
    ol {
      padding-left: ${getStyleSizes(60)};
    }

    h2 {
      font-size: ${getStyleSizes(36)};
    }
    h3 {
      font-size: ${getStyleSizes(28)};
    }
  }
`;

export const BoxedPromoContainer = styled.div`
  padding-top: ${getStyleSizes(40)};

  > div {
    padding: 0;
  }

  ${mq_min('lg')} {
    padding-top: ${getStyleSizes(52)};
  }
`;
