import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';

import { primaryTheme } from '@styles/themes';

import CMSHeader from '@cms-components/header/header';
import { CMSFooter } from '@cms-components/footer';
import {
  PageHeaderStyleProvider,
  PageHeaderStyleType,
} from '@shared/context/pageHeaderStyle.context';

import { LayoutContainer, LPLayoutContainer } from './base-layout.styles';
import { PageMeta } from '@shared/components/page-meta/page-meta';
import { PageMetaProps } from '@shared/mappers/pageMeta.mapper';
import { LivePreviewHeader } from '@shared/contentstack/live-preview/widgets/live-preview-header';

const BaseLayout = (props: {
  children: any;
  headerStyle: PageHeaderStyleType;
  pageMeta: PageMetaProps | null;
  location: any;
  isLivePreview?: boolean;
}) => {
  const { children, headerStyle, pageMeta, location, isLivePreview } = props;

  return (
    <ThemeProvider theme={primaryTheme}>
      <MUIThemeProvider theme={primaryTheme}>
        {pageMeta && PageMeta(pageMeta, location)}
        <CssBaseline />
        <PageHeaderStyleProvider color={headerStyle}>
          {isLivePreview ? (
            <LPLayoutContainer>
              <header id={'main-header'}>
                <LivePreviewHeader />
              </header>
              <main>{children}</main>
            </LPLayoutContainer>
          ) : (
            <LayoutContainer>
              <header id={'main-header'}>
                <CMSHeader />
              </header>
              <main>{children}</main>
              <footer>
                <CMSFooter />
              </footer>
            </LayoutContainer>
          )}
        </PageHeaderStyleProvider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default BaseLayout;
