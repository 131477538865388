import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import {
  ComponentOuterWrapperStyles,
  ComponentWrapperBackgroundProps,
  ComponentWrapperContentProps,
} from './component-wrapper.interface';
import { global_colors } from '@styles/colors';
import { getStyleBorder, getStyleSizes } from '@styles/styles.utils';

export const OuterWrapper = styled.section(
  ({
    borderPosition,
    borderColor,
    margin,
    hasBackground,
    borderRadius,
  }: ComponentOuterWrapperStyles) => {
    const borderKey = !!borderPosition ? `border${borderPosition}` : 'border';
    const borderValue = !!borderPosition
      ? getStyleBorder(1, 'solid', borderColor || global_colors.border.dark)
      : 0;

    return {
      position: hasBackground ? 'relative' : 'initial',
      width: '100%',
      marginTop: getStyleSizes(margin[0] || 0),
      marginBottom: getStyleSizes(margin[1] || 0),
      [borderKey]: borderValue,
      borderRadius: getStyleSizes(borderRadius || 0),
      overflow: borderRadius && borderRadius > 0 ? 'hidden' : 'visible',
    };
  }
);

export const Background = styled.div(
  ({ image, color }: ComponentWrapperBackgroundProps) => {
    return {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: color || 'transparent',
      background: color || 'transparent',
    };
  }
);

export const ContentWrapper = styled.div<ComponentWrapperContentProps>`
  position: ${({ hasBackground }) => (hasBackground ? 'relative' : '')};
  padding: 0
    ${({ horizontalPadding }) =>
      horizontalPadding !== null
        ? getStyleSizes(horizontalPadding)
        : getStyleSizes(20)};

  ${mq_min('cw', 40)} {
    padding: 0 calc((100% - ${({ maxWidth }) => maxWidth}px) / 2);
  }
`;
