import styled from '@emotion/styled';
import { zIndices } from '@styles/z-indices';
import { color_black, color_white } from '@styles/colors';
import {
  VideoPlayerStyledProps,
  VimeoStyledProps,
  VideoShadowStyledProps
} from '@shared/components/video-player/video-player.interface';
import Vimeo from '@u-wave/react-vimeo';
import { mq_min } from '@styles/breakpoints';

export const VideoShadowStyled = styled.div<VideoShadowStyledProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 80%;
  ${({ fullwidth }) => fullwidth &&
    `${mq_min('lg')} {
       height: 100%;
       width: 80%;
     }
  `}
`;

export const VideoPlayerButtonStyled = styled.div`
  cursor: pointer;
  color: ${color_black};
  background-color: ${color_white};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 15px rgba(87, 95, 111, 0.3);
`;

export const VideoPlayerPlayButtonStyled = styled(VideoPlayerButtonStyled)`
  z-index: 100;
`;

export const VideoPlayerPauseButtonStyled = styled(VideoPlayerButtonStyled)`
  opacity: 1;
  transition: opacity 500ms linear;
  z-index: 90;
`;

export const VideoPlayerStyled = styled.div<VideoPlayerStyledProps>`
  position: relative;
  z-index: ${zIndices.videoPlayer};
  display: flex;
  align-items: center;
  justify-content: center;

  .video-player__play {
    display: ${({ paused }) => (paused ? 'flex' : 'none')};
  }

  .video-player__pause {
    opacity: ${({ paused }) => (paused ? 1 : 0)};
  }

  &:hover {
    .video-player__pause {
      opacity: 1;
    }
  }
`;

export const VimeoStyled = styled(Vimeo)<VimeoStyledProps>`
  width: 100%;
  height: auto;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ mobileHeight, height }) => {
    if (mobileHeight) {
      return `
      padding: ${mobileHeight} 0 0 0;
        ${mq_min('lg')} {
          padding: ${height ? height : '56.25%'} 0 0 0;
        }
      `;
    } else {
      return `padding: ${height ? height : '56.25%'} 0 0 0;`;
    }
  }}
`;
