import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { noTo_font } from '@src/styles/typography';
import {
  color_miami,
  color_light_grey,
  global_colors,
  color_black,
  color_concrete,
  color_blue,
  color_indigo
} from '@styles/colors';
import { mq_min } from '@styles/breakpoints';
import { ContactFormContainerProps } from './contact-form.interface';

export const FormTitleStyled = styled('h2')`
    font-size: ${getStyleSizes(32)};
    font-style: normal;
    font-weight: 600;
    line-height: ${getStyleSizes(38.4)};
    text-align: center;
    color: ${color_black};
    margin-bottom: ${getStyleSizes(26)};
    ${mq_min('md')} {
      margin-bottom: ${getStyleSizes(25)};
    }
`;

export const ContactFormContainer = styled('section')<ContactFormContainerProps>`
  border-radius: ${getStyleSizes(12)};
  background: #fff;
  -webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, .08);
  padding:  ${getStyleSizes([38,24,32])};
  position: relative;
  z-index: 9;
  ${mq_min('md')} {
    padding: ${getStyleSizes([40,40,40])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes([38,40,40])};
  }
  form {
    text-align: left;
    position: relative;
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0;
    
    .mktoFormRow{
      margin: 0 !important;
      width: 100%;
      ${mq_min('md')} {
        width: calc(50% - 8px);
      }
      &.textareaRow {
        width: 100%;

        textarea.mktoField{
          display: block;
          height: 120px;
        }
      }
      &.labelWidth{
        label.mktoLabel {
          width: calc(100% - 44px) !important;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &.field-Lead_Type__c option:first-of-type,
      &.field-State option:first-of-type {
        display: none;
      }

      ${({ isFormExpanded })=>
        !isFormExpanded && `
          &.field-Phone,
          &.field-State,
          &.field-Number_of_Users__c,
          &.field-Lead_Type__c,
          &.field-Comments_hidden__c{
            display: none;
          }
        `
      }
    }

    .mktoFieldWrap {
      margin-bottom: ${getStyleSizes(19)} !important;
      position: relative;

      &.typing,
      &.filled{
          label.mktoLabel {
          font-size: ${getStyleSizes(14)};
          line-height: ${getStyleSizes(22)};
          top: ${getStyleSizes(-10)};
          width: auto !important;
          max-width: calc(100% - 22px);
        }
      }
      &.typing{
        .mktoField{
          border-color: ${color_black} !important;
        }
        label.mktoLabel{
          color: ${color_black};
        }
      }
      &:not(.typing).filled label.mktoLabel{
        color: ${color_concrete.base};
      }
      &.error{
        .mktoField {
          border-color: #E30004 !important;
        }
        label.mktoLabel {
          color: ${color_concrete.base};
        }
        &.filled label.mktoLabel {
          color: ${color_black};
        }   
      } 
    }

    label.mktoLabel {
      display: block !important;
      position: absolute;
      top: ${getStyleSizes(15)};
      left: ${getStyleSizes(11)};
      width: calc(100% - 30px) !important;
      padding: ${getStyleSizes([0, 5])} !important;
      overflow: hidden;
      background: #FFF;
      color: ${color_concrete.base};
      font-family: "Noto Sans",'Causten','sans-serif','Lato';
      font-size: ${getStyleSizes(18)};
      font-style: normal;
      font-weight: 400 !important;
      line-height: ${getStyleSizes(27)};
      pointer-events: none;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  .mktoCaptchaDisclaimer {
    font-size: ${getStyleSizes(14)};
    font-style: normal;
    font-weight: 400;
    line-height: ${getStyleSizes(21)};
    width: 100%;
    max-width: ${getStyleSizes(398)};
    color: #757575;
    text-align: center;
    margin: 3px auto 25px;

    a {
      color: ${color_blue};
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      padding: 0;

      &:is(:hover, :focus, :active) {
        color: ${color_indigo.base};
      }
    }
  }
  .mktoOffset,
  .mktoAsterix,
  .mktoClear,
  .mktoGutter,
  .mktoErrorArrowWrap {
    display: none !important;
  }
  .mktoFieldWrap,
  .mktoFormCol {
    float: none !important;
    margin: 0 !important;
  }
  .mktoForm input[type='text'],
  .mktoForm input[type='url'],
  .mktoForm input[type='email'],
  .mktoForm input[type='tel'],
  .mktoForm input[type='number'],
  .mktoForm input[type='date'],
  .mktoForm select.mktoField,
  .mktoForm textarea.mktoField {
    width: 100% !important;
    height: ${getStyleSizes(57)};
    background: #fff !important;
    outline: 0 !important;
    border: 1px solid ${color_light_grey} !important;
    border-radius: 4px !important;
    color: #1a1a1a;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-family: ${noTo_font};
    font-size: ${getStyleSizes(18)} !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: ${getStyleSizes(27)};
    padding: ${getStyleSizes([14,15])} !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;

    &::-webkit-input-placeholder {
      color: transparent !important;
    }
    &::-moz-placeholder {
      color: transparent !important;
    }
    &:-ms-input-placeholder {
      color: transparent !important;
    }
    &::-ms-input-placeholder {
      color: transparent !important;
    }
    &::placeholder {
      color: transparent !important;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 56px white inset !important;
      -webkit-text-fill-color: #1A1A1A !important;
    }
  }

  .mktoForm select.mktoField{
    background-image: url('//res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/4001/chevron-down.webp') !important;
    background-position: calc(100% - 14px) 50% !important;
    background-repeat: no-repeat !important;
    background-size: ${getStyleSizes(24)} !important;
    padding-right:  ${getStyleSizes(38)} !important;
  }

  button[type='submit'],
  .mktoButton {
    background: ${color_miami.base} !important;
    padding: ${getStyleSizes([15, 30])} !important;
    border: 0 !important;
    border-radius: 4px !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none !important;
    text-transform: initial !important;
    cursor: pointer !important;
    width: 100%;
    height: ${getStyleSizes(56)};
    color: ${color_black};
    font-family: 'Causten';
    font-size: ${getStyleSizes(18)} !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: ${getStyleSizes(27)};
    margin: 0px !important;

    &:hover {
      background-color: ${color_miami.dark} !important;
    }
    &:is(:focus, :active) {
      background-color: #2299B5 !important;
    }
    svg {
      transform: translateX(10px);
      padding: 0;
    }
  }

  .mktoForm {
    .mktoError {
      position: relative;
      left: 0 !important;
      right: unset !important;
      bottom: unset !important;
      top: 100% !important;
      margin: 0 !important;

      .mktoErrorMsg {
        font-family: ${noTo_font};
        font-size: ${getStyleSizes(14)};
        line-height: ${getStyleSizes(21)};
        font-style: normal;
        font-weight: 400;
        text-align: left;
        list-style: none;
        margin: 0 !important;
        background-image: none !important;
        background-color: transparent !important;
        color: #e30004 !important;
        box-shadow: none !important;
        text-shadow: none !important;
        border: 0 !important;
        max-width: 100% !important;
        padding: ${getStyleSizes([4,0,0])}; !important;
      }
    }

    
  }

 

  .mktoButtonRow .mktoErrorMsg {
    text-align: left;
    margin-top: 10px !important;
  }

  .hs_error_rollup {
    position: relative;
  }
  .submitted-message {
    font-size: ${getStyleSizes(18)};
    font-style: italic;
  }

  .hs_recaptcha {
    margin-bottom: ${getStyleSizes(24)};
  }

  .mktoField:not([name='emailOptIn']) {
    width: 100% !important;
    float: none !important;
  }
  .mktoForm .mktoFormRow:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .form-utm-container,
  .form-utm-container > fieldset {
    visibility: hidden;
    height: 0;
    margin: 0 !important;
    line-height: 0;
  }

  .mktoFormRow:nth-last-of-type,
  .mktoButtonRow {
    margin: 0 !important;
  }

  .mktoButtonRow {
    width: 100%;
  }

  .mktoButtonWrap {
    margin: 0 !important;
  }

  .mktoValid {
    border: 1px solid transparent !important;
  }
  .mktoInvalid {
    border: 1px solid ${global_colors.notification.error} !important;
  }

  .mktoFormCol {
    width: 100%;
  }

  .form-select-container {
    display: flex !important;
    align-items: center;
    column-gap: 20px;
  }

  .form-select-container > label.mktoLabel {
    display: block !important;
    font-family: Causten !important;
    font-size: ${getStyleSizes(18)} !important;
    font-weight: 400 !important;
  }

  select {
    width: auto;
  }
`;
