import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

export const LPHeader = styled.div`
  color: white;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & img {
    width: 90px;
    order: 0;
    ${mq_min('lg')} {
      order: 1;
    }
  }
`;

export const LPHeaderDisclaimer = styled.div`
  color: #333;
  background: #eee;
  padding: 5px 20px;
  font-size: ${getStyleSizes(14)};
  border-bottom: 1px solid #ddd;
  text-align: center;
  ${mq_min('lg')} {
    padding: 5px calc((100% - 1240px) / 2);
  }
`;

export const HeaderLPContainer = styled.div`
  & > div {
    position: relative;
  }
`;

export const LPContainer = styled.div`
  padding: 0 calc((100% - 1240px) / 2);
`;

export const MenuSectionLPContainer = styled.div`
  position: relative;
  min-height: auto;
  padding: 0 calc((100% - 400px) / 2);
  ${mq_min('lg')} {
    min-height: 300px;
    padding: 0;
  }

  & > div {
    bottom: initial;
    top: 0;
  }
`;
