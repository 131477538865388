import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
  color_miami,
  global_colors,
} from '@styles/colors';

import { VariantProp } from './lc-filtered-article-list.interface';

export const themes = {
  light: {
    containerPanelBackground: color_white,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
  },
};

export const Container = styled.div<VariantProp>`
  overflow: hidden;
  padding: ${getStyleSizes([32, 0, 0, 0])};
  background: ${({ variant }) => themes[variant]?.containerPanelBackground};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([54, 0, 12, 0])};
  }
`;

export const Header = styled.header`
  margin-bottom: ${getStyleSizes(24)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(34)};
  }
`;

export const Title = styled.h2`
  font-size: ${getStyleSizes(26)};
  color: ${color_darkest_grey};
  font-weight: 400;
  padding-right: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
  }
`;

export const SeeAllLink = styled.div`
  text-align: right;
  a {
    font-size: ${getStyleSizes(15)};
    padding-bottom: ${getStyleSizes(4)};
    border-bottom: 1px solid ${color_dark_grey};
    color: ${color_darkest_grey};
    line-height: ${getStyleSizes(26)};
  }

  ${mq_min('lg')} {
    a {
      font-size: ${getStyleSizes(16)};
    }
  }
`;

export const Inner = styled.div`
  ${mq_min('lg')} {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: ${getStyleSizes([0, -20])};
  }
`;

export const ArticleWrapper = styled.div`
  margin-bottom: ${getStyleSizes(24)};

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(40)};
    padding: ${getStyleSizes([0, 20])};
  }
`;
