import styled from '@emotion/styled';
import * as Styled from '@cms-components/hero-banner/hero-banner.styles';

import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

export const BannerOverhang = styled.div<{ color: string }>`
  text-align: center;
  position: relative;
  padding-top: ${getStyleSizes(50)};
  padding-bottom: ${getStyleSizes(20)};
  color: ${({ color }) => color};
  ${mq_min('md')} {
    padding-top: ${getStyleSizes(132)};
    padding-bottom: 0;
  }

  h1,
  h2 {
    line-height: 1;
    font-size: ${getStyleSizes(34)};
    font-weight: normal;

    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
    }
  }

  .gatsby-image-wrapper,
  .gatsby-fallback-image-wrapper {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${getStyleSizes([0, 2, 30, 20])} rgb(0 0 0 / 10%);
  }
`;

export const BannerOverhangCopy = styled(Styled.HeroBannerCopy)`
  margin-top: ${getStyleSizes(24)};
  ul,
  ol {
    max-width: ${getStyleSizes(800)};
    margin: 0 auto;
  }
`;

export const BannerOverhangButtonsWrapper = styled(
  Styled.HeroBannerButtonsWrapper
)``;

export const BannerOverhangVideo = styled.div``;
