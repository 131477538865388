import React, { useState } from 'react';

import {
  PrimaryButton,
  RichText,
  SimpleHeader,
  ImageComponent,
  VideoPlayer,
} from '@shared/components';
import BasicLink from '@shared/components/basic-link';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import * as Styled from './banner-standard.styles';
import {
  HeroBackgroundColorType,
  HeroMediaAlignmentType,
  StandardBannerProps,
} from '../../hero-banner.interface';

import { default_breakpoints } from '@styles/breakpoints';
import {
  background_color_mapper,
  text_color_mapper,
  headline_color_mapper,
} from '@styles/colors';
import { SimpleHeaderWrapper } from './banner-standard.styles';

import { ArrowIcon } from '@shared/components';
import { VideoPlayerPlayButtonStyled } from '@src/shared/components/video-player/video-player.styles';

export const BannerStandard = (props: StandardBannerProps) => {
  const {
    headline,
    eyebrow,
    banner_copy,
    banner_image,
    button_link,
    secondary_link,
    banner_background_color,
    logo,
    make_headline_h1,
    media_alignment,
    image_placement,
    site_configuration,
    video_id,
  } = props;

  const hasVideo = !!video_id;
  const hasButtonLink = button_link?.link_url;
  const hasSecondaryLink = secondary_link?.link_url;
  const hasImage = banner_image?.image;
  const hasLogo = logo?.image;
  const [showVideo, setShowVideo] = useState(false);

  const headlineColor =
    headline_color_mapper[banner_background_color || 'dark'];
  const textColor = text_color_mapper[banner_background_color || 'dark'];
  const gridColStyleContent =
    image_placement === 'image-right' || !image_placement ? 1 : 2;
  const gridColStyleImage =
    image_placement && image_placement === 'image-left' ? 1 : 2;

  const backgroundColor: HeroBackgroundColorType = banner_background_color
    ? background_color_mapper[banner_background_color]
    : '';

  return (
    <CMSComponentWrapper
      maxWidth={default_breakpoints.cw}
      backgroundColor={backgroundColor}
      data-testid="banner-standard__container"
    >
      <Styled.BannerStandard color={textColor} noImage={!hasImage}>
        {hasLogo && (
          <Styled.BannerStandardLogo gridCol={gridColStyleContent}>
            <ImageComponent
              image={logo.image.gatsbyImageData}
              fallback={logo.image.url}
              alt_text={logo.image_alt_text}
            />
          </Styled.BannerStandardLogo>
        )}

        {eyebrow && (
          <Styled.BannerStandardEyebrow gridCol={gridColStyleContent}>
            {eyebrow}
          </Styled.BannerStandardEyebrow>
        )}

        {headline && (
          <SimpleHeaderWrapper
            gridCol={gridColStyleContent}
            color={headlineColor}
          >
            <SimpleHeader text={headline} isH1={Boolean(make_headline_h1)} />
          </SimpleHeaderWrapper>
        )}

        {banner_copy && (
          <Styled.BannerStandardCopy gridCol={gridColStyleContent}>
            <RichText content={banner_copy} />
          </Styled.BannerStandardCopy>
        )}
        {(hasButtonLink || hasSecondaryLink) && (
          <Styled.BannerStandardButtonsWrapper
            noImage={!hasImage}
            gridCol={gridColStyleContent}
          >
            {hasButtonLink && (
              <Styled.BannerStandardButton>
                <PrimaryButton
                  ariaLabel="see more"
                  path={button_link?.link_url}
                  label={button_link?.link_text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                />
              </Styled.BannerStandardButton>
            )}

            {hasSecondaryLink && (
              <Styled.BannerStandardButtonSecondary>
                <BasicLink
                  text={secondary_link?.link_text}
                  url={secondary_link?.link_url}
                  underline={true}
                  isExternal={secondary_link?.external_link}
                />
              </Styled.BannerStandardButtonSecondary>
            )}
          </Styled.BannerStandardButtonsWrapper>
        )}
        {hasImage && (
          <Styled.BannerStandardImageContainer
            media={(media_alignment as HeroMediaAlignmentType) || 'right'}
            gridCol={gridColStyleImage}
            onClick={() => setShowVideo(true)}
            hasVideo={hasVideo}
            showVideo={showVideo}
          >
            {showVideo && hasVideo ? (
              <VideoPlayer
                autoplay={true}
                id={video_id}
                mobileHeight={'70%'}
                height={'70%'}
              />
            ) : (
              <Styled.WrapperImage>
                {hasVideo && (
                  <VideoPlayerPlayButtonStyled>
                    <ArrowIcon />
                  </VideoPlayerPlayButtonStyled>
                )}
                <ImageComponent
                  image={banner_image.image.gatsbyImageData}
                  fallback={banner_image.image.url}
                  alt_text={banner_image.image_alt_text}
                />
              </Styled.WrapperImage>
            )}
          </Styled.BannerStandardImageContainer>
        )}
      </Styled.BannerStandard>
    </CMSComponentWrapper>
  );
};
