import { AccordionProps } from '../accordion.interface';
import { CSFAQAccordion } from '@shared/contentstack/contentstack.interface';
import { CSRichText } from '@shared/contentstack';

export function accordionMapper({
  headline,
  faq_items,
  site_configuration,
}: CSFAQAccordion): AccordionProps {
  return {
    headline,
    itemList:
      faq_items?.map(
        ({ question, answer }: { 
          question: string;
          answer: CSRichText;
        }) => ({
          question,
          answer
        })
      ) || [],
    site_configuration,
  };
}
