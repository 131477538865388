import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PromoCardsMapper } from './mappers/promo-cards.mapper';
import { PromoCardsProps } from './promo-cards.interface';
import { PrimaryButton } from '@src/shared/components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import {
  CardsContainer,
  DetailContainer,
  Headline,
  Inner,
  PromoCardsContainer,
  PromoCardsInner,
  PromoText,
  StyledButton,
} from './promo-cards.styles';
import { CardPresentation, RichText } from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import 'swiper/css';
import {
  processTextForColors,
  hasContent,
} from '@src/shared/utilities/string.utils';
import { default_breakpoints } from '@styles/breakpoints';

const SwiperContainer = ({ cards }: { cards: any[] }) => {
  return (
    <Swiper slidesPerView="auto">
      {cards?.map((card: any, key: number) => (
        <SwiperSlide key={key}>
          <CardPresentation {...card} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const RegularContainer = ({
  cards,
  cardType,
}: {
  cards: any[];
  cardType: any;
}) => {
  return (
    <CardsContainer data-length={cards?.length} cardType={cardType}>
      {cards?.map((card: any, key: number) => (
        <CardPresentation key={key} {...{ type: cardType, ...card }} />
      ))}
    </CardsContainer>
  );
};

export const PromoCards = ({
  backgroundColor,
  isCentered,
  headline,
  cards,
  cardType,
  content,
  button,
  uid,
}: PromoCardsProps) => {
  const isDesktop = useBreakpoint(up('md'));
  const usePlain = cardType === 'boxed' || isDesktop;

  const copyHasContent = !!content ? hasContent(content?.children) : false;
  const gridType = !copyHasContent ? 'heading' : 'stack';
  const colorizedHeadline = !!headline
    ? processTextForColors(headline)
    : headline;
  return (
    <PromoCardsContainer
      id={uid}
      data-testid="promo-cards__container"
      background={backgroundColor}
      isCentered={isCentered}
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <PromoCardsInner isCentered={isCentered}>
          <DetailContainer
            background={backgroundColor}
            isCentered={isCentered}
            gridType={gridType}
          >
            <Headline background={backgroundColor} isCentered={isCentered}>
              <span dangerouslySetInnerHTML={{ __html: colorizedHeadline }} />
            </Headline>
            {!!content && copyHasContent && (
              <PromoText background={backgroundColor}>
                <RichText content={content} />
              </PromoText>
            )}

            <StyledButton>
              {!!button?.link_url && (
                <PrimaryButton
                  ariaLabel="see more"
                  path={button?.link_url}
                  label={button?.link_text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                />
              )}
            </StyledButton>
            <Inner>
              {usePlain ? (
                <RegularContainer cards={cards} cardType={cardType} />
              ) : (
                <SwiperContainer cards={cards} />
              )}
            </Inner>
          </DetailContainer>
        </PromoCardsInner>
      </CMSComponentWrapper>
    </PromoCardsContainer>
  );
};

export const MappedPromoCards = (data: any) => {
  const { light_secondary_background, card_type, primary_background_color } =
    data;

  if (!data) {
    return null;
  }
  const mapper: PromoCardsProps = PromoCardsMapper(data, {
    light_secondary_background,
    card_type,
    primary_background_color,
  });

  return <PromoCards {...mapper} />;
};

export default PromoCards;
