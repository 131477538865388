import React from 'react';
import * as Styled from './featured-cards.styles';
import { FeaturedCardProps } from './featured-cards.interface';
import CMSComponentWrapper from '../../shared/components/component-wrapper/component-wrapper';
import { default_breakpoints } from '../../styles/breakpoints';
import { PrimaryButton } from '../../shared/components';

export const FeaturedCards: React.FC<FeaturedCardProps> = ({
  uid,
  backgroundColor,
  isCentered,
  title,
  sub_title,
  features,
  feature_button,
}) => {
  return (
    <Styled.FeaturedCardContainer
      id={uid}
      data-testid="featured-cards__container"
      background={backgroundColor}
      isCentered={isCentered}
    >
      <CMSComponentWrapper maxWidth={1240}>
        <Styled.FeaturedCardTitle isCentered={isCentered}>
          {title}
        </Styled.FeaturedCardTitle>
        <Styled.FeaturedCardSubTitle>{sub_title}</Styled.FeaturedCardSubTitle>

        <Styled.FeaturedCardFeaturesContainer>
          {features?.map((feature) => (
            <Styled.FeaturedCardFeature key={feature.heading}>
              <Styled.FeaturedCardImage
                src={feature.feature_icon.image.url}
                alt={feature.feature_icon.image_alt_text}
              />
              <Styled.FeatureHeading>{feature.heading}</Styled.FeatureHeading>
              <Styled.FeatureSubHeading>
                {feature.sub_heading}
              </Styled.FeatureSubHeading>
            </Styled.FeaturedCardFeature>
          ))}
        </Styled.FeaturedCardFeaturesContainer>

        <Styled.FeaturedCardButtonContainer>
          <PrimaryButton
            ariaLabel="see more"
            path={feature_button?.link_url}
            label={feature_button?.link_text}
            size={'large'}
            variant={'contained'}
            icon={'text'}
          />
        </Styled.FeaturedCardButtonContainer>
      </CMSComponentWrapper>
    </Styled.FeaturedCardContainer>
  );
};
