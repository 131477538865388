import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FacebookIcon: React.FunctionComponent<SvgIconProps> = (
  props
): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path d="M20 10.0611C20 4.50451 15.5229 0 10 0C4.47715 0 0 4.50451 0 10.0611C0 15.0828 3.65684 19.2452 8.4375 20V12.9694H5.89844V10.0611H8.4375V7.84452C8.4375 5.32296 9.93047 3.93012 12.2146 3.93012C13.3084 3.93012 14.4531 4.12663 14.4531 4.12663V6.60261H13.1922C11.95 6.60261 11.5625 7.37822 11.5625 8.17465V10.0611H14.3359L13.8926 12.9694H11.5625V20C16.3432 19.2452 20 15.0828 20 10.0611Z" />
    </SvgIcon>
  );
};
