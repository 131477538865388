export const default_breakpoints: any = {
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  cw: 1240,
};

const arrayOfBps: string[] = Object.keys(default_breakpoints);

const getMaxWidth = (bp: string): number => {
  return default_breakpoints[bp] - 0.02;
};

const getBpRange = (bp: string): string[] => {
  return [bp, arrayOfBps[arrayOfBps.indexOf(bp) + 1]];
};

// Media Query Functions

export const mq_min = (min: string, offset: number = 0): string => {
  return `@media screen and (min-width: ${default_breakpoints[min] + offset}px)`;
};

export const mq_max = (max: string): string => {
  return `@media screen and (max-width: ${getMaxWidth(max)}px)`;
};

export const mq_between = (min: string, max: string): string => {
  return `@media screen and (min-width: ${default_breakpoints[min]}px) and (max-width: ${getMaxWidth(max)}px)`;
};

export const mq_only = (bp: string): string => {
  const [min, max] = getBpRange(bp);
  return `@media screen and (min-width: ${default_breakpoints[min]}px) and (max-width: ${getMaxWidth(max)}px)`;
};
