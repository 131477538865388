import styled from '@emotion/styled';

import { RoundedBoxContainerProps } from './rounded-box.interface';

const RoundedBoxContainer = styled.div((props: RoundedBoxContainerProps) => ({
  borderRadius: props.borderRadius,
  border: props.border,
  padding: props.padding,
  backgroundColor: props.backgroundColor,
}));

export { RoundedBoxContainer };
