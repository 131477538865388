import {
  ArrowIcon,
  IconLink,
  PrimaryButton,
  RichText,
  TextColor,
} from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import React from 'react';
import { StandardPromoProps } from './standard-promo.interface';
import { default_breakpoints } from '@styles/breakpoints';
import {
  Description,
  DetailContainer,
  Headline,
  Image,
  ImageContainer,
  LinkContainer,
  StandardPromoContainer,
  StandardPromoInner,
  Subhead,
  themes,
} from './standard-promo.styles';

export const StandardPromo = ({
  linkAsButton = false,
  variant = 'full-width',
  promoType,
  headline,
  subhead,
  copy,
  link,
  image,
  imageAlignment,
  backgroundColor = 'light',
  maxWidth,
  uid,
}: StandardPromoProps) => {
  function hasCopy() {
    return !(
      copy?.children.length === 0 &&
      copy?.children[0]?.children?.length === 0 &&
      copy?.children[0]?.children[0]?.text?.trim() === ''
    );
  }

  const getMaxWidth = () => {
    if (maxWidth && typeof maxWidth === 'number') return maxWidth;
    return variant === 'full-width' && imageAlignment === 'right'
      ? default_breakpoints.cw
      : 1024;
  };

  const hasImage = Boolean(image && image.src);

  return (
    <StandardPromoContainer
      id={uid || copy?.uid}
      background={backgroundColor}
      imageAlignment={imageAlignment}
      variant={variant}
      data-testid="standard-promo__container"
    >
      <CMSComponentWrapper maxWidth={getMaxWidth()}>
        <StandardPromoInner
          imageAlignment={imageAlignment}
          variant={variant}
          hasImage={hasImage}
        >
          <DetailContainer variant={variant} hasImage={hasImage}>
            {promoType === 'standard' && (
              <Headline isSmall={!hasCopy()} background={backgroundColor}>
                <TextColor>{headline}</TextColor>
              </Headline>
            )}
            {promoType === 'standard' && subhead && (
              <Subhead>{subhead}</Subhead>
            )}

            {promoType === 'standard' && hasCopy() && (
              <Description background={backgroundColor}>
                <RichText content={copy} />
              </Description>
            )}

            {link && !linkAsButton && (
              <LinkContainer>
                <IconLink
                  isBold
                  label={link?.text}
                  linkUrl={link?.url}
                  isExternal={link?.isExternal}
                  color={themes[backgroundColor]?.linkColor}
                  icon={'assembly-arrow'}
                />
              </LinkContainer>
            )}

            {link && linkAsButton && (
              <LinkContainer>
                <PrimaryButton
                  ariaLabel="see more"
                  path={link?.url}
                  label={link?.text}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
                />
              </LinkContainer>
            )}
          </DetailContainer>
          {hasImage && (
            <ImageContainer variant={variant} imageAlignment={imageAlignment}>
              <Image
                src={image.src}
                variant={variant}
                alignment={imageAlignment}
                width={'100%'}
                height={'auto'}
                alt={image.alt || ''}
              />
            </ImageContainer>
          )}
        </StandardPromoInner>
      </CMSComponentWrapper>
    </StandardPromoContainer>
  );
};
export default StandardPromo;
