import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import { 
  ImageGridContainer, 
  ImageGridInner,
  BadgesContainer,
  BadgeItemStyled,
  PreHeadlineStyled,
  BadgeItemContainer } from './image-grid.styles';
import { BadgeContainerBackgroundStyled } from './image-grid-background.styles';
import { ImageGridProps } from './image-grid.interface';

import { CSImageGrid } from '@shared/contentstack/contentstack.interface';
import { imageGridMapper } from './mappers/image-grid.mapper';
import { RichText } from '@src/shared/components';

export const ImageGrid = ({
  itemList,
  preheadline,
  badges,
  uid,
}: ImageGridProps) => {
  return (
    <ImageGridContainer data-testid="image-grid_container" badges={badges}>
      {badges && (
        <BadgeContainerBackgroundStyled />
      )}
      <CMSComponentWrapper 
        uid={uid} 
        maxWidth={1240}
        horizontalPadding={0}
      >
        <ImageGridInner>
          {preheadline && (
            <PreHeadlineStyled>
              <RichText content={preheadline} />
            </PreHeadlineStyled>
          )}
          <BadgesContainer>
            {itemList?.map(({ image, image_alt_text }, index) =>(
              <BadgeItemContainer  key={index} >
                <BadgeItemStyled 
                  src={image.url} 
                  alt={image_alt_text} 
                />
              </BadgeItemContainer>
            ))}
          </BadgesContainer>
        </ImageGridInner>
      </CMSComponentWrapper>
    </ImageGridContainer>
  );
};

export const MappedImageGrid = (data: CSImageGrid) => {
  return <ImageGrid {...imageGridMapper(data)} />;
};
