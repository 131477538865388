import { ArrowControl } from '@src/shared/components';
import { default_breakpoints } from '@src/styles/breakpoints';
import { color_darkest_grey, color_white } from '@src/styles/colors';
import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BackgroundType, TypeCard } from './feature-cards.interface';

export const SwiperCustom = ({
  background,
  data,
  Component,
  type,
  id = 'featured-cards',
}: {
  background: BackgroundType;
  Component: any;
  data: any;
  type: TypeCard;
  id?: string;
}) => {
  const slidesPerViewDesktop = type === 'standard' || type === 'small' ? 4 : 3;
  const isSlider = (!!data && data.length > slidesPerViewDesktop) || false;
  const arrowColor = background === 'dark' ? color_white : color_darkest_grey;
  return (
    <Swiper
      className={isSlider ? 'withArrows' : ''}
      slidesPerView={1}
      spaceBetween={40}
      navigation={{
        nextEl: `.swiper-button-prev-${id}`,
        prevEl: `.swiper-button-next-${id}`,
      }}
      modules={[Navigation]}
      breakpoints={{
        [default_breakpoints.md]: {
          slidesPerView: 2,
        },
        [default_breakpoints.lg]: {
          slidesPerView: slidesPerViewDesktop,
        },
      }}
    >
      {isSlider && (
        <>
          <div className={`swiper-button-prev swiper-button-next-${id}`}>
            <ArrowControl size={40} color={arrowColor} left />
          </div>
          <div className={`swiper-button-next swiper-button-prev-${id}`}>
            <ArrowControl size={40} color={arrowColor} />
          </div>
        </>
      )}

      {!!data &&
        data?.map((item: any, key: number) => (
          <SwiperSlide key={key}>
            <Component {...item} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
