import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { TextColor } from '@shared/components';

import {
  ContentPanelContainer,
  ContentPanelInner,
  Headline,
} from './content-panel.styles';
import { ContentPanelProps } from './content-panel.interface';
import { ContentGroups } from './components/content-groups';
import { ImageScrolling } from './components/image-scrolling';
import { ActiveImageProvider } from './context/active-image.context';
import { contentPanelMapper } from './mappers/content-panel.mapper';
import { CSContentPanel } from '@shared/contentstack';
import { default_breakpoints } from '@styles/breakpoints';

export const ContentPanel = ({
  background = 'light',
  headline,
  imageScrolling,
  contentGroupList,
  uid,
}: ContentPanelProps) => {
  if (!contentGroupList.length) return <></>;
  const hasImageScrolling =
    imageScrolling &&
    contentGroupList?.filter((item) => !!item.videoId)?.length === 0;
  return (
    <ContentPanelContainer
      id={uid}
      background={background}
      imageScrolling={hasImageScrolling}
    >
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        {headline && (
          <Headline background={background} imageScrolling={hasImageScrolling}>
            <TextColor>{headline}</TextColor>
          </Headline>
        )}
        <ContentPanelInner imageScrolling={hasImageScrolling}>
          <ActiveImageProvider>
            <ContentGroups
              imageScrolling={hasImageScrolling}
              background={background}
              contentGroupList={contentGroupList}
            />
            {hasImageScrolling && (
              <ImageScrolling
                background={background}
                contentGroupList={contentGroupList}
              />
            )}
          </ActiveImageProvider>
        </ContentPanelInner>
      </CMSComponentWrapper>
    </ContentPanelContainer>
  );
};

export const MappedContentPanel = (data: CSContentPanel) => {
  const mapper: ContentPanelProps = contentPanelMapper(data);
  return <ContentPanel {...mapper} />;
};

export default ContentPanel;
