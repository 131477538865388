import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_blue,
  global_colors,
} from '@styles/colors';

import { HasImageProp } from './lc-article-tile.interface';
import { noTo_font } from '@styles/typography';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.div<{
  objectFit: 'contain' | 'cover';
  backgroundColor: string;
}>`
  width: ${getStyleSizes(136)};
  height: ${getStyleSizes(136)};
  border-radius: 4px;
  margin-right: ${getStyleSizes(24)};
  background: ${({ backgroundColor }) => backgroundColor};

  ${mq_min('lg')} {
    width: ${getStyleSizes(200)};
    height: ${getStyleSizes(200)};
    margin-right: ${getStyleSizes(40)};
  }

  ${mq_min('xl')} {
    width: ${getStyleSizes(280)};
    height: ${getStyleSizes(280)};
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

export const Detail = styled.div<HasImageProp>`
  width: ${getStyleSizes(164)};

  ${({ hasImage }) =>
    !hasImage &&
    `
    width: ${getStyleSizes(280)};
  `}

  ${mq_min('lg')} {
    width: ${getStyleSizes(210)};
  }

  ${mq_min('xl')} {
    width: ${getStyleSizes(280)};
  }
`;

export const Topics = styled.p`
  font-size: ${getStyleSizes(12)};
  font-weight: 600;
  color: ${color_blue};
  margin-bottom: ${getStyleSizes(8)};
  text-transform: uppercase;
`;

export const Headline = styled.h3`
  font-size: ${getStyleSizes(16)};
  font-weight: 600;
  color: ${color_darkest_grey};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const Teaser = styled.p<HasImageProp>`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(13)};
  line-height: ${getStyleSizes(22)};
  font-weight: 200;
  margin-top: ${getStyleSizes(12)};
  color: ${color_dark_grey};

  ${({ hasImage }) =>
    hasImage &&
    `
    display: none;
  `}

  ${mq_min('lg')} {
    display: block;
  }
`;

export const ItemDate = styled.time`
  font-size: ${getStyleSizes(12)};
  font-weight: 300;
  color: ${global_colors.link.dark};
  margin-top: ${getStyleSizes(6)};
  padding-bottom: ${getStyleSizes(6)};
  font-family: ${noTo_font};
  display: none;
  ${mq_min('lg')} {
    display: block;
  }
`;
