import React from 'react';
import { IconLink } from '../../icon-link';
import { LCArticleSmallProps } from './lc-article-small.interface';
import {
  StyledArticleSmallContainer,
  StyledDate,
  StyledHeadline,
} from './lc-article-small.styles';

export const LCArticleSmall = ({
  url,
  headline,
  publication_date,
}: LCArticleSmallProps) => {
  return (
    <StyledArticleSmallContainer>
      {publication_date && <StyledDate>{publication_date}</StyledDate>}
      {headline && (
        <StyledHeadline>
          <IconLink isBold label={headline} linkUrl={url} />
        </StyledHeadline>
      )}
    </StyledArticleSmallContainer>
  );
};
