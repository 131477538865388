import React from 'react';
import { PrimaryButton } from '@shared/components';
import {
  HamburgerMenuButton,
  LogoStyled,
  MobileMenuButtons,
  TopMenuStyled,
} from './top-menu.styles';
import { useMainNav } from '../../context/main-nav.context';
import { TopMenuProps } from '@cms-components/header/components/top-menu/top-menu.interface';
import { usePageHeaderStyle } from '@shared/context/pageHeaderStyle.context';
import { ImageComponent } from '@shared/components/image-component/image-component';

export const TopMenu = ({ demo, logo }: TopMenuProps) => {
  const { navState, toggleNav } = useMainNav();
  const { currentColor } = usePageHeaderStyle();
  const { link_url: demoUrl, link_text: demoLabel } = demo || {};
  const { logo_for_dark_header, logo_for_light_header, alt_text } = logo || {};

  const { gatsbyImageData, url } =
    currentColor === 'light' ? logo_for_light_header : logo_for_dark_header;

  return (
    <TopMenuStyled>
      <LogoStyled to={'/'} color={currentColor}>
        <ImageComponent
          image={gatsbyImageData}
          alt_text={alt_text || 'Assembly Legal'}
          fallback={url}
          fallback_shadow={false}
        />
      </LogoStyled>
      <MobileMenuButtons>
        <PrimaryButton
          ariaLabel="get a demo"
          label={demoLabel}
          icon={currentColor === 'dark' ? 'white' : 'text'}
          size={'small'}
          fontSize={'14px'}
          variant={currentColor === 'light' ? 'contained' : 'outlined'}
          path={demoUrl}
        />
        <HamburgerMenuButton
          aria-label="Open Menu"
          role="button"
          reverse={currentColor === 'dark'}
          onClick={() => toggleNav()}
          state={navState === 'opened' ? 'open' : 'closed'}
        />
      </MobileMenuButtons>
    </TopMenuStyled>
  );
};
