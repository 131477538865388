import { css } from '@mui/material/styles';

import styled from '@emotion/styled';
import {
  color_darkest_grey,
  color_light_grey,
  color_white,
} from '@src/styles/colors';
import { shadow_defaults } from '@styles/settings';
import { getStyleSizes } from '@styles/styles.utils';

import { mq_min } from '@styles/breakpoints';

import {
  ContainerAttributionProps,
  ContainerProps,
  InnerProps,
} from './card-presentation.interface';
import {
  causten_font,
  global_typography,
  noTo_font,
} from '@src/styles/typography';

export const Container = styled.article<ContainerProps>`
  display: flex;
  height: 100%;
  border-radius: ${getStyleSizes(4)};
  transition: 0.2s linear;
  &:hover {
    box-shadow: ${getStyleSizes(shadow_defaults.size)} ${shadow_defaults.color};
    .link {
      text-decoration: underline;
    }
  }
  box-sizing: border-box;

  color: ${color_darkest_grey};
  border: 1px solid ${color_light_grey};
  position: relative;

  ${({ variant, background }) => {
    switch (variant) {
      case 'boxed':
        return css`
          background: transparent;
          align-items: center;
          justify-content: center;
        `;
      case 'square':
        return css`
          background: ${background};
          width: 100%;
          min-height: ${getStyleSizes(267)};

          ${mq_min('lg')} {
            min-height: ${getStyleSizes(374)};
          }
        `;
      case 'tall':
        return css`
          background: ${background};

          width: 100%;
          min-height: ${getStyleSizes(372)};

          ${mq_min('lg')} {
            min-height: ${getStyleSizes(500)};
          }
        `;
    }
  }}
`;

export const Inner = styled.div<InnerProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${getStyleSizes([32, 24])};
  z-index: 10;

  &.inner-boxed {
    height: 100%;
  }

  header {
    display: flex;
    font-family: ${causten_font};
    font-weight: 400;
    line-height: ${getStyleSizes(32)};
    justify-content: center;
    align-items: center;
    color: ${({ color }) => color};

    &.header-boxed {
      color: ${color_darkest_grey};
      font-size: ${getStyleSizes(28)};
    }

    &.header-square,
    &.header-tall {
      text-align: left;
      font-size: ${getStyleSizes(28)};
      justify-content: flex-start;
    }

    h3 {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .logo-icon {
    flex: 1;
    width: 50%;
    text-align: left;

    &.promo-icon-center {
      width: 100%;
      text-align: center;
    }

    &.fallback-image {
      align-self: center;
    }
  }
`;

export const ContainerCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.copy-boxed {
    color: ${color_darkest_grey};
    font-size: ${getStyleSizes(15)};
    align-items: center;
    text-align: center;
  }

  &.copy-square {
    color: ${color_white};
    align-items: flex-start;
    text-align: left;
  }

  &.copy-tall {
    color: white;
    justify-content: flex-end;
    text-align: left;
    a {
      color: white;
    }
  }
`;

export const Title = styled.h3`
  font-size: ${getStyleSizes(26)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(28)};
  }

  font-size: ${getStyleSizes(26)};
  font-family: ${causten_font};
  line-height: 1.2;
  font-weight: normal;
`;

export const ContainerAttribution = styled.div<ContainerAttributionProps>`
  display: flex;
  width: 100%;
  padding-top: ${getStyleSizes(10)};
  font-family: ${causten_font};
  font-size: ${getStyleSizes(global_typography.copy.base)};
  font-weight: 600;
  line-height: ${getStyleSizes(26)};

  justify-content: center;
  align-items: center;

  &.cta-square,
  &.cta-tall {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.type-quote {
    font-family: ${noTo_font};
    font-size: ${getStyleSizes(global_typography.copy.small)};
    line-height: ${getStyleSizes(22)};
    font-weight: 400;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const Paragraph = styled.p`
  font-size: ${getStyleSizes(16)};
  font-weight: 500;
  line-height: ${getStyleSizes(26)};
  padding-top: ${getStyleSizes(10)};

  &.quote-promo {
    font-family: ${noTo_font};
    font-size: ${getStyleSizes(13)};
    padding-top: ${getStyleSizes(20)};
    line-height: ${getStyleSizes(22)};
  }
`;

export const StyledLogo = styled.div``;
