import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { CheckIconStyled } from './check-icon.style';

type CheckIconProps = {
  accessibilitySvg?: JSX.Element;
} & SvgIconProps;
export const CheckIcon: React.FunctionComponent<CheckIconProps> = (
  props
): JSX.Element => {
  return (
    <CheckIconStyled {...props}>
      {props.accessibilitySvg}
      <path d="M3 12L6 9L10 13L19 4L22 7L10 19L3 12Z" />
    </CheckIconStyled>
  );
};
