import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_white,
  color_light_grey,
} from '@styles/colors';

import { VariantProp } from '../../list-panel.interface';
import { ListContentItemProps } from './list-content.interface';
import { global_typography } from '@src/styles/typography';

export const themes = {
  light: {
    headlineColor: color_darkest_grey,
    copyColor: color_dark_grey,
    imageShadow: color_light_grey,
    linkColor: color_darkest_grey,
    seeAllColor: color_darkest_grey,
  },
  mid: {
    headlineColor: color_darkest_grey,
    copyColor: color_dark_grey,
    imageShadow: color_light_grey,
    linkColor: color_darkest_grey,
    seeAllColor: color_darkest_grey,
  },
  dark: {
    headlineColor: '#65DFFF',
    copyColor: '#EFF2F8',
    imageShadow: color_dark_grey,
    linkColor: color_white,
    seeAllColor: color_white,
  },
};

export const ListContentContainer = styled.div`
  ${mq_min('lg')} {
  }
`;

export const ListContentItemContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${mq_min('lg')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ListContentItem = styled.li<ListContentItemProps>`
  margin-bottom: ${getStyleSizes(36)};
  display: ${({ showAllItems }) => (showAllItems ? 'flex' : 'none')};

  &:nth-of-type(-n + 3) {
    display: flex;
  }

  ${mq_min('lg')} {
    display: flex;
    flex-basis: calc(50% - ${getStyleSizes(30)});
    margin-bottom: ${getStyleSizes(64)};
    position: relative;

    &:nth-last-of-type(-n + 2) {
      margin-bottom: 0;
    }
  }
`;

export const Bullet = styled.div`
  margin-right: 12px;

  ${mq_min('lg')} {
    position: absolute;
    top: 0;
    left: ${getStyleSizes(-36)};
  }
`;

export const DetailContainer = styled.div``;

export const Headline = styled.h3<VariantProp>`
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  color: ${({ variant }) => themes[variant || 'light']?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const Copy = styled.div<VariantProp>`
  font-family: NoToSans;
  font-weight: 200;
  font-size: ${getStyleSizes(global_typography.copy.base)};
  color: ${({ variant }) => themes[variant || 'light']?.copyColor};
  margin-top: ${getStyleSizes(16)};
`;

export const LinkContainer = styled.div<VariantProp>`
  font-weight: 600;
  font-size: ${getStyleSizes(16)};
  margin-top: ${getStyleSizes(16)};
  color: ${({ variant }) => themes[variant || 'light']?.linkColor};
`;

export const SeeAll = styled.p<VariantProp>`
  display: inline-block;
  color: ${({ variant }) => themes[variant || 'light']?.seeAllColor};
  text-decoration: underline;
  font-size: ${getStyleSizes(16)};
  cursor: pointer;

  ${mq_min('lg')} {
    display: none;
  }
`;
