import { createTheme, Theme } from '@mui/material/styles';
import { ButtonSizeType, ButtonVariantType } from './styles.interface';
// import { fonts } from './fonts';
import {
  color_charcoal,
  color_concrete,
  color_eggshell,
  color_indigo,
  color_miami,
  color_white,
  global_colors,
} from '@styles/colors';
import { default_breakpoints } from '@styles/breakpoints';
// MUI Component Props...
export const mui_variants: ButtonVariantType[] = ['contained', 'outlined', 'text'];
export const mui_sizes: ButtonSizeType[] = ['large', 'medium', 'small'];

declare module '@mui/material/styles' {
  interface Palette {
    miami: Palette['primary'];
    indigo: Palette['primary'];
    charcoal: Palette['primary'];
    concrete: Palette['primary'];
    eggshell: Palette['primary'];
  }

  interface PaletteOptions {
    miami: PaletteOptions['primary'];
    indigo: PaletteOptions['primary'];
    charcoal: PaletteOptions['primary'];
    concrete: PaletteOptions['primary'];
    eggshell: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    miami: true;
    indigo: true;
    charcoal: true;
    concrete: true;
    eggshell: true;
  }
}

// MUI Themes...
export const primaryTheme: Theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'miami' },
          style: {
            color: color_white,
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: 'Causten, Roboto, Helvetica, Arial, sans-serif',
  },
  breakpoints: {
    values: default_breakpoints,
  },
  palette: {
    miami: {
      main: color_miami.base,
      dark: color_miami.dark,
      light: color_miami.light,
      contrastText: global_colors.text.dark,
    },
    indigo: {
      main: color_indigo.base,
      dark: color_indigo.dark,
      light: color_indigo.light,
      contrastText: global_colors.text.light,
    },
    charcoal: {
      main: color_charcoal.base,
      dark: color_charcoal.dark,
      light: color_charcoal.light,
      contrastText: global_colors.text.light,
    },
    concrete: {
      main: color_concrete.base,
      dark: color_concrete.dark,
      light: color_concrete.light,
      contrastText: global_colors.text.light,
    },
    eggshell: {
      main: color_eggshell.base,
      dark: color_eggshell.dark,
      light: color_eggshell.light,
      contrastText: global_colors.text.dark,
    },
    error: {
      main: global_colors.notification.error,
    },
  },
  spacing: 8,
  direction: 'rtl',
});
