import {
  CSLCArticle,
  CSLCFeatureCard,
} from '@src/shared/contentstack/contentstack.interface';
import { FeaturesCardsProps } from './feature-cards.interface';
import { sortByPublicationDate } from './feature-cards.utils';

export const featureCardsMapper = ({
  background_color,
  button,
  card_type_display,
  headline,
  teaser_text,
  articles,
  site_configuration,
  cards_to_display,
  id,
  uid
}: CSLCFeatureCard) => {
  return {
    id,
    background: background_color,
    cards: articles
      ?.sort(sortByPublicationDate)
      ?.slice(0, cards_to_display || 9)
      ?.map((article) => {
        return {
          ...article,
          featured_article_fallback_image:
            site_configuration?.featured_article_fallback_image,
          url: `/learning-center/${article?.lc_content_type[0]?.url_segment}/${article?.page_url}`,
          url_category: `/learning-center/${article?.lc_content_type[0]?.url_segment}`,
          category: article?.lc_content_type[0]?.navigation_name,
        };
      }),
    type: card_type_display,
    button,
    headline,
    teaser_text,
    uid
  } as FeaturesCardsProps;
};
