import { MainNavDataProps } from '@cms-components/header/components/main-nav/main-nav.interface';

export function headerMapper(query: any): MainNavDataProps {
  const { menu_sections, ...rest } = query || {};

  return {
    allMenuSections: menu_sections,
    headerData: rest,
  };
}
