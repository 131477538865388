import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import {
  color_darkest_grey,
  color_lightest_grey,
  color_white,
  color_dark_grey,
} from '@styles/colors';
import {
  SimplePromoContainerProps,
  HeadlineProps,
  PromoTextProps,
  SimplePromoInnerProps,
  DetailContainerProps,
} from './simple-promo.interface';

const themes = {
  light: {
    containerBackground: color_white,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  mid: {
    containerBackground: color_lightest_grey,
    headlineColor: color_darkest_grey,
    promoTextColor: color_dark_grey,
  },
  dark: {
    containerBackground: color_darkest_grey,
    headlineColor: color_white,
    promoTextColor: '#EFF2F8',
  },
};

export const SimplePromoContainer = styled.div<SimplePromoContainerProps>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ background }) => themes[background]?.containerBackground};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([100, 0])};
  }
`;

export const SimplePromoInner = styled.div<SimplePromoInnerProps>`
  display: flex;
  flex-direction: column;

  ${mq_min('lg')} {
    ${({ isCentered }) =>
      !isCentered &&
      `
        flex-direction: row;
        align-items: flex-start;
    `}
  }
`;

export const Headline = styled.h3<HeadlineProps>`
  font-weight: 400;
  font-size: ${getStyleSizes(34)};
  color: ${({ background }) => themes[background]?.headlineColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(48)};

    ${({ isCentered }) =>
      !isCentered &&
      `
        flex-basis: 50%;
    `}
  }
`;

export const DetailContainer = styled.div<DetailContainerProps>`
  padding-top: ${getStyleSizes(16)};

  ${mq_min('lg')} {
    ${({ isCentered }) =>
      !isCentered &&
      `
        flex-basis: 50%;
        padding: ${getStyleSizes([0, 0, 0, 80])};
    `}
  }
`;

export const PromoText = styled.div<PromoTextProps>`
  font-family: NoToSans;
  font-size: ${getStyleSizes(16)};
  font-weight: 200;
  color: ${({ background }) => themes[background]?.promoTextColor};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${getStyleSizes(32)};

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(40)};
  }
`;
