import React, { useEffect, useRef, useCallback } from 'react';
import { RichText, IconLink, VideoPlayer } from '@shared/components';

import {
  ContentGroupItem,
  ContentGroupsContainer,
  Image,
  DetailContainer,
  ImageContainer,
  Eyebrow,
  Headline,
  Copy,
  LinkContainer,
  themes,
  VideoContainer,
} from './content-groups.styles';
import { ContentGroupsProps } from './content-groups.interface';
import { getImageHeight } from './../../utilities/image.utils';
import { useActiveImage } from './../../context/active-image.context';

export const ContentGroups = ({
  background,
  imageScrolling,
  contentGroupList,
}: ContentGroupsProps) => {
  const refContainer = useRef<any>(null);
  const { setActiveImage } = useActiveImage();

  const handleScroll = useCallback(() => {
    if (!(refContainer.current && refContainer.current.childNodes)) return;

    const centerPageY: number = window.scrollY + window.innerHeight / 2;
    const items = [...refContainer.current.childNodes];

    if (items.length > 0) {
      const newImageActive: number = items
        .map(({ offsetTop, clientHeight }) =>
          Math.abs(centerPageY - (offsetTop + clientHeight / 2))
        )
        .reduce(
          (imageActiveResult, distanceItem, index) => {
            if (
              imageActiveResult?.index === -1 ||
              imageActiveResult?.distanceItem > distanceItem
            ) {
              return {
                distanceItem,
                index,
              };
            }

            return imageActiveResult;
          },
          {
            distanceItem: 0,
            index: -1,
          }
        ).index;

      setActiveImage(newImageActive);
    }
  }, [setActiveImage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <ContentGroupsContainer imageScrolling={imageScrolling} ref={refContainer}>
      {contentGroupList?.map(
        (
          {
            eyebrow,
            headline,
            copy,
            image,
            imageAltText = '',
            link,
            videoId,
            displayImage,
            displayVideo,
          },
          index
        ) => {
          return (
            <ContentGroupItem
              id={copy.uid}
              background={background}
              imageScrolling={imageScrolling}
              imageHeight={getImageHeight(image)}
              key={index}
            >
              <DetailContainer imageScrolling={imageScrolling}>
                {eyebrow && (
                  <Eyebrow background={background}>{eyebrow}</Eyebrow>
                )}
                <Headline background={background}>{headline}</Headline>
                <Copy background={background}>
                  <RichText content={copy} />
                </Copy>
                {link.text && (
                  <LinkContainer>
                    <IconLink
                      isBold
                      label={link.text}
                      linkUrl={link?.url}
                      isExternal={link?.isExternal}
                      color={themes[background]?.linkColor}
                    />
                  </LinkContainer>
                )}
              </DetailContainer>
              {((image && image.src && displayImage) ||
                (image && image.src && !videoId)) && (
                <ImageContainer imageScrolling={imageScrolling}>
                  <Image
                    src={image.src}
                    background={background}
                    alt={imageAltText}
                    width={image.width}
                    height={image.height}
                  />
                </ImageContainer>
              )}
              {((videoId && displayVideo) ||
                (videoId && !image) ||
                (videoId && !displayVideo && image)) && (
                <VideoContainer>
                  <VideoPlayer id={videoId} fullwidth={true} />
                </VideoContainer>
              )}
            </ContentGroupItem>
          );
        }
      )}
    </ContentGroupsContainer>
  );
};
