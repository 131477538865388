import React, { useState, useRef, MutableRefObject, useEffect, useCallback } from 'react';
import { ContactFormProps } from '../form-panel-baseline.interface';
import useMarketo from '@shared/hooks/useMarketo';
import { isBrowser, isElement } from '@shared/utilities';
import { CONTACT_FORM_CONSTANTS } from '../form-panel.constants';
import { 
  ContactFormContainer,
  FormTitleStyled } from './contact-form.styles';

export const ContactFormBaseline = ({
  base_url,
  tracking_code,
  form_id,
  title = CONTACT_FORM_CONSTANTS.TITLE,
  success_message = CONTACT_FORM_CONSTANTS.SUCCESS_MESSAGE,
}: ContactFormProps) => {
  const formRef: MutableRefObject<any> = useRef();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const isValidFormConfig: boolean = !!base_url && !!form_id && !!tracking_code;
  const form_element_id = `${CONTACT_FORM_CONSTANTS.FORM_BASE_ID}_${form_id}`;
  const [ isFormExpanded, setFormExpanded ] = useState<boolean>(false);

  const initForm = useCallback((form: any): void => {
    if (formRef.current) {
      const currentForm = formRef.current;
      const fieldData = [
        { name: 'FirstName', label: 'First Name'},
        { name: 'LastName', label: 'Last Name'},
        { name: 'Email', label: 'Work Email' },
        { name: 'Phone', label: 'Phone Number' },
        { name: 'Company', label: 'Firm Name' },
        { name: 'State', label: 'State' },
        { name: 'Number_of_Users__c', label: 'Number of Users' },
        { name: 'Lead_Type__c', label: 'Current Customer?' },
        { name: 'Comments_hidden__c', label: 'Briefly describe your request' }
      ]
      fieldData.forEach(field => {
        const fieldElement = currentForm.querySelector(`#${field.name}`);
        if(fieldElement === null) return;

        // Field Wrapper names
        fieldElement.closest('.mktoFormRow').classList.add(`field-${field.name}`);

        // Replace labels
        const labelElement = currentForm.querySelector(`#Lbl${field.name}`);
        if (labelElement) {
          labelElement.textContent = field.label;
        }

        // Remove select first option text
        const stateField = formRef.current.querySelector('#State');
        if (stateField && stateField.options.length > 0) {
          stateField.options[0].textContent = '';
        }
        const currentCustomerField = formRef.current.querySelector('#Lead_Type__c');
        if (currentCustomerField && currentCustomerField.options.length > 0) {
          currentCustomerField.options[0].textContent = '';
        }

        // Add event listener for input focus
        fieldElement.addEventListener('focus', (event: any) => {
          event.target.closest('.mktoFieldWrap').classList.add('typing');
        });	
        // Add event listener for input blur
        fieldElement.addEventListener('blur', (event: any) => {
          event.target.closest('.mktoFieldWrap').classList.remove('typing');
          if(event.target.value === ''){
            event.target.closest('.mktoFieldWrap').classList.remove('filled');
          }else{
            event.target.closest('.mktoFieldWrap').classList.add('filled');
          }
        });	

        // Add event listener for input changes
        fieldElement.addEventListener('input', () => {
          const watchFields = [
            { name: 'FirstName', label: 'First Name' },
            { name: 'LastName', label: 'Last Name' },
            { name: 'Email', label: 'Work Email' },
            { name: 'Company', label: 'Firm Name' }
          ];
          const hasEmptyField = watchFields.some(field => {
            const watchFieldElement = formRef.current.querySelector(`#${field.name}`);
            return !watchFieldElement || watchFieldElement.value.trim() === '';
          });
          if (!hasEmptyField) {
            setFormExpanded(true);
          }
        });
      });
  
      // Add classes to field mktoFormRow
      currentForm.querySelector('#Comments_hidden__c')?.closest('.mktoFormRow').classList.add('textareaRow');
      currentForm.querySelector('#State')?.closest('.mktoFormRow').classList.add('labelWidth');
      currentForm.querySelector('#Lead_Type__c')?.closest('.mktoFormRow').classList.add('labelWidth');

      // Change position of the fields
      currentForm.querySelector('#Company')?.closest('.mktoFormRow').after(currentForm.querySelector('#Phone').closest('.mktoFormRow'));
      currentForm.querySelector('#State')?.closest('.mktoFormRow').before(currentForm.querySelector('#Number_of_Users__c').closest('.mktoFormRow'));

      // Submit Button text
      if(currentForm.querySelector('button.mktoButton')){
        currentForm.querySelector('button.mktoButton').innerHTML=`
        <span>Submit</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
        <path d="M7.352 4.68953C7.2988 4.65 7.24307 4.61429 7.18533 4.58261L0.985552 0.614912L0.962739 0.598325L0.922379 0.574365C0.807993 0.512132 0.678219 0.488105 0.550466 0.505518C0.422712 0.522925 0.303079 0.580938 0.207606 0.671771C0.112132 0.762612 0.0453923 0.881931 0.016319 1.01373C-0.0127477 1.14554 -0.00274758 1.28352 0.0449724 1.40918L1.40145 5.9998L0.0449724 10.5885C-0.00420091 10.7181 -0.0133077 10.8607 0.0189257 10.9961C0.0511523 11.1315 0.123099 11.2528 0.224579 11.3429C0.326059 11.4329 0.451939 11.4872 0.584406 11.498C0.716872 11.5088 0.849226 11.4756 0.962739 11.4031L1.20139 11.2575L2.0595 10.7047L4.03367 9.44593L7.19233 7.4188C7.2466 7.38726 7.29933 7.3528 7.35027 7.3156C7.5508 7.16913 7.71467 6.97386 7.8278 6.74653C7.94093 6.5192 8 6.26646 8 6.00993C8 5.7534 7.94093 5.50066 7.8278 5.27333C7.71467 5.04593 7.5508 4.85073 7.35027 4.70426L7.352 4.68953Z" fill="#1A1A1A"></path>
          </svg>`;
      }

      form.onSuccess(onSuccess);
    }
  }, []);

  const checkFieldErrors = useCallback((mutationsList: any) =>{
    for (var mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        let mktoFieldWraps = document.querySelectorAll('.mktoFieldWrap');
        mktoFieldWraps.forEach(fieldWrap => {
          let mktoErrorElemet = fieldWrap.querySelector('.mktoError');
          let isVisible = mktoErrorElemet ? getComputedStyle(mktoErrorElemet).display !== 'none' : false;
          if(mktoErrorElemet && isVisible){
            fieldWrap.classList.add('error');
          }else{
            fieldWrap.classList.remove('error');
          }
        })
      }
    }
  },[]);

  const useMutationObserver = (
    ref: any,
    callback: any,
    options = {
      subtree: true, 
      childList: true, 
      attributes: true, 
      attributeFilter: ['style']
    }
  ) => {
    useEffect(() => {
      if (ref) {
        const observer = new MutationObserver(callback);
        observer.observe(ref, options);
        return () => observer.disconnect();
      }
    }, [ ref ]);
  };

  useMutationObserver(formRef.current, checkFieldErrors);

  // If needed in the future, the Marketo success callback includes:
  // - submitted form values
  // - redirect url
  const onSuccess = () => {
    setFormSubmitted(true);
    return false;
  };

  if (isBrowser()) {
    useMarketo({
      baseUrl: base_url,
      munchkinId: tracking_code,
      formId: form_id,
      callback: () => {},
      onLoadCallback: (form: any) => initForm(form),
      exists:
        formRef.current &&
        isElement(formRef.current) &&
        formRef.current.childNodes.length > 0,
    });
  }
  return (
    <ContactFormContainer isFormExpanded={isFormExpanded}>
      <FormTitleStyled>{title}</FormTitleStyled>
      {formSubmitted && <div>{success_message}</div>}
      {isValidFormConfig && !formSubmitted && (
        <form ref={formRef} id={form_element_id} />
      )}
    </ContactFormContainer>
  );
};
