import React from 'react';
import {
  TextColorProps,
  ListTextType,
  SentenceType,
} from './text-color.interface';
import { SpanIndigo, SpanMiami } from './text-color.styles';

const sentences: SentenceType[] = [
  {
    regexOpen: /{{/,
    regexClose: /}}/,
    nodeStyled: SpanIndigo,
  },
  {
    regexOpen: /{/,
    regexClose: /}/,
    nodeStyled: SpanMiami,
  },
];

export const TextColor = ({ children }: TextColorProps) => {
  function splitText(
    currentText: string,
    sentenceEvaluate: SentenceType
  ): ListTextType[] {
    const splitTextOpen = currentText?.split(sentenceEvaluate.regexOpen);

    return splitTextOpen?.reduce((listTextSplit, text) => {
      const splitTextClose = text.split(sentenceEvaluate.regexClose);
      const hasToSplit = 2;

      if (splitTextClose.length === hasToSplit) {
        return listTextSplit.concat(
          {
            text: splitTextClose[0],
            sentence: sentenceEvaluate,
          },
          {
            text: splitTextClose[1],
          }
        );
      }

      return listTextSplit.concat({
        text,
      });
    }, [] as ListTextType[]);
  }

  function evaluateAddSentence(
    listTextToEvaluate: ListTextType[],
    sentenceEvaluate: SentenceType
  ): ListTextType[] {
    return listTextToEvaluate?.filter((text) => text).reduce((listTextResult, { text, sentence }) => {
      if (!sentence) {
        return listTextResult?.concat(splitText(text, sentenceEvaluate));
      }
      return listTextResult?.concat({ text, sentence });
    }, [] as ListTextType[]);
  }

  function getListText(): ListTextType[] {
    return sentences.reduce(
      (listText, sentence) => evaluateAddSentence(listText, sentence),
      [{ text: children }]
    );
  }

  return (
    <>
      {getListText().map(({ text, sentence }, index) => {
        if (sentence) {
          const NodeStyled = sentence.nodeStyled;
          return <NodeStyled key={index}>{text}</NodeStyled>;
        }
        return text;
      })}
    </>
  );
};
