import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterIcon: React.FunctionComponent<SvgIconProps> = (
  props
): JSX.Element => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M7.92098 21C16.2208 21 20.7618 14.0741 20.7618 8.0699C20.7618 7.87519 20.7575 7.67616 20.7489 7.48146C21.6322 6.83819 22.3945 6.04141 23 5.12857C22.1773 5.49714 21.3038 5.73785 20.4094 5.84248C21.3512 5.27405 22.0563 4.38106 22.3941 3.32906C21.5082 3.85777 20.5393 4.23073 19.529 4.43196C18.8483 3.70363 17.9482 3.22139 16.968 3.0598C15.9879 2.89821 14.9821 3.06626 14.1063 3.53798C13.2304 4.00969 12.5333 4.7588 12.1227 5.66948C11.7121 6.58016 11.6108 7.60169 11.8346 8.57613C10.0406 8.48548 8.28561 8.01622 6.68332 7.19877C5.08102 6.38131 3.66722 5.23392 2.53355 3.83097C1.95737 4.83129 1.78105 6.01501 2.04044 7.14153C2.29984 8.26805 2.97547 9.25286 3.93004 9.89579C3.21341 9.87288 2.51248 9.6786 1.88516 9.32899V9.38524C1.88451 10.435 2.24492 11.4526 2.90512 12.265C3.56531 13.0775 4.48455 13.6346 5.50656 13.8418C4.84272 14.0247 4.14598 14.0513 3.47027 13.9197C3.75867 14.8225 4.31978 15.6121 5.07529 16.1783C5.8308 16.7446 6.74303 17.0592 7.68465 17.0782C6.08606 18.3427 4.11133 19.0285 2.07852 19.0253C1.71801 19.0247 1.35787 19.0025 1 18.9586C3.06512 20.2927 5.4674 21.0013 7.92098 21Z" />
    </SvgIcon>
  );
};
