import { ImageType } from './../content-panel.interface';

export const getImageHeight = (image: ImageType) => {
  const maxWidthImage = 462;

  if (image.width > maxWidthImage) {
    return (maxWidthImage * image.height) / image.width;
  }
  return image.height;
};
