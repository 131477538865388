import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import { color_dark_grey } from '@styles/colors';

export const SocialNetworksInner = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${getStyleSizes(40)};
  ${mq_min('lg')} {
    padding-top: ${getStyleSizes(52)};
  }
`;

export const TextShare = styled.p`
  font-size: ${getStyleSizes(12)};
  font-weight: 600;
  color: ${color_dark_grey};
  margin-right: ${getStyleSizes(32)};
`;

export const LinkContainer = styled.div`
  font-size: 0;
  button {
    margin-right: ${getStyleSizes(32)};
  }
`;

export const Link = styled.a`
  font-size: 0;
  cursor: pointer;
  margin-right: ${getStyleSizes(32)};
`;
