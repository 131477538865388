import styled from '@emotion/styled';
import { zIndices } from '@styles/z-indices';
import { color_black, color_miami } from '@styles/colors';
import {
  VideoPlayerStyledProps,
  VimeoStyledProps,
  VideoShadowStyledProps
} from './video-player.interface';
import Vimeo from '@u-wave/react-vimeo';
import { mq_min } from '@styles/breakpoints';
import {  getStyleSizes } from '@styles/styles.utils';

export const VideoShadowStyled = styled.div<VideoShadowStyledProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 80%;
  ${({ fullwidth }) => fullwidth &&
    `${mq_min('lg')} {
       height: 100%;
       width: 80%;
     }
  `}
`;

export const VideoPlayerButtonStyled = styled.div`
  cursor: pointer;
  color: ${color_black};
  background-color: ${color_miami.base};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getStyleSizes(48)};
  height: ${getStyleSizes(48)};
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 8px 16px 16px rgba(53, 62, 91, 0.14) !important;
  ${mq_min('md')} {
    width: ${getStyleSizes(88)};
    height: ${getStyleSizes(88)};
  }
  &:hover{
    background-color: ${color_miami.dark};
  }
  &:active{
    background-color: #2294AF;
    box-shadow: none;
  }
`;

export const VideoPlayerPlayButtonStyled = styled(VideoPlayerButtonStyled)`
  z-index: 100;
`;

export const VideoPlayerPauseButtonStyled = styled(VideoPlayerButtonStyled)`
  opacity: 1;
  transition: opacity 500ms linear;
  z-index: 90;
  box-shadow: none;
`;

export const VideoPlayerStyled = styled.div<VideoPlayerStyledProps>`
  position: relative;
  z-index: ${zIndices.videoPlayer};
  display: flex;
  align-items: center;
  justify-content: center;
  filter: none;

  .video-player__play {
    display: ${({ paused }) => (paused ? 'flex' : 'none')};
  }

  .video-player__pause {
    opacity: ${({ paused }) => (paused ? 1 : 0)};
  }

  &:hover {
    .video-player__pause {
      opacity: 1;
    }
  }
`;

export const IconContainerStyled = styled('div')`
  transform: scale(1);
  margin-top: 0;

  ${mq_min('md')} {
    transform: scale(2);
    margin-top: ${getStyleSizes(13)};
  }
`

export const VimeoStyled = styled(Vimeo)<VimeoStyledProps>`
  width: 100%;
  height: auto;
  position: relative;
  box-shadow: none;
  filter: none;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${({ mobileHeight, height }) => {
    return `padding: ${height ? height : '56.25%'} 0 0 0;`;
  }}
`;
