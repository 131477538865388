import React from 'react';

import { PanelLinks } from '@cms-components/header/components/panel-links/panel-links';
import { SeeAllLink } from '@cms-components/header/components/see-all-link/see-all-link';
import { BoxedLinks } from '@cms-components/header/components/boxed-links/boxed-links';
import { FeatureLogo } from '@cms-components/header/components/feature-logo/feature-logo';
import { SecondaryLogoSection } from '@cms-components/header/components/secondary-logo-section/secondary-logo-section';

import {
  BottomLinks,
  NavInner,
  NavPanelStyled,
  NavWrapper,
} from './nav-panel.styles';
import { NavPanelProps, NavPanelVariant } from './nav-panel.interface';
import { CSMenuDropDown } from '@cms-components/header/components/main-nav/main-nav.interface';

export const NavPanel = ({ state, menu_dropdown }: NavPanelProps) => {
  const {
    featured_logo,
    menu_list,
    section_title,
    optional_see_all_link,
    boxed_link_section,
    secondary_logo_section,
  } = menu_dropdown || {};

  const MAX_ADDONS_IN_TWO_COLUMNS = 4;

  const targetPanelVariant: NavPanelVariant =
    !!featured_logo && !!featured_logo?.image ? 'two-column' : 'one-column';

  const targetPanelVariantColumnThree =
    menu_list && menu_list?.length > MAX_ADDONS_IN_TWO_COLUMNS
      ? 'three-column'
      : targetPanelVariant;
  return (
    <NavPanelStyled state={state} variant={targetPanelVariant}>
      <NavWrapper>
        <NavInner variant={targetPanelVariantColumnThree}>
          {featured_logo && <FeatureLogo data={featured_logo} />}
          <div>
            {menu_list && menu_list.length && (
              <PanelLinks
                menus={menu_list}
                title={section_title}
                variant={targetPanelVariantColumnThree}
              />
            )}
            <BottomLinks variant={targetPanelVariantColumnThree}>
              {optional_see_all_link && (
                <SeeAllLink data={optional_see_all_link} />
              )}
              {boxed_link_section && <BoxedLinks data={boxed_link_section} />}
            </BottomLinks>
          </div>
        </NavInner>
        {secondary_logo_section && (
          <SecondaryLogoSection data={secondary_logo_section} />
        )}
      </NavWrapper>
    </NavPanelStyled>
  );
};

export const CMSNavPanel = ({ state, menu_dropdown }: NavPanelProps) => {
  return !!menu_dropdown ? (
    <NavPanel state={state} menu_dropdown={menu_dropdown} />
  ) : (
    <></>
  );
};
