import styled from '@emotion/styled';
import { getStyleSizes } from '@styles/styles.utils';
import { global_colors } from '@styles/colors';
import BasicLink from '@shared/components/basic-link';

export const FooterLinkSection = styled.section`
  margin-bottom: ${getStyleSizes(40)};

  header {
    margin-bottom: ${getStyleSizes(20)};
  }
`;

export const FooterLinkHeader = styled.h3`
  display: block;
  font-family: Causten;
  font-weight: 600;
  font-size: ${getStyleSizes(12)};
  color: ${global_colors.text.dark};
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`;

export const FooterLinkList = styled.ul`
  padding: 0;
  margin: 0;
`;

export const FooterLinkListItem = styled.li`
  list-style: none;
  margin-bottom: ${getStyleSizes(15)};
  font-family: NoToSans;
  font-weight: 400;
  font-size: ${getStyleSizes(13)};
  text-decoration: none;
  line-height: 1;
  color: ${global_colors.text.grey};
`;

export const FooterLinkItem = styled(BasicLink)`
  display: block;
`;
