import React from 'react';

import { ComponentWrapperProps } from './component-wrapper.interface';
import {
  Background,
  ContentWrapper,
  OuterWrapper,
} from './component-wrapper.styles';

/** CMS COMPONENT WRAPPER
 * @summary wraps CMS component and provides ability to inset content, provide background color or image
 *
 * @param maxWidth: maximum width of the content on the page
 * @param border: determines the position of the border ["top", "bottom"]?. border will be omitted if left undefined
 * @param borderRadius: border radius
 * @param borderColor: determines the color of the border. can be any color string. will be ignored if [border] is undefined
 * @param backgroundImage: image to be used as a background. fills the whole component.
 * @param backgroundColor: background color for the whole component
 * @param margin: spacing above and/or below the container. Takes an array of numbers. [number (top margin), number (bottom margin)]
 * @param horizontalPadding: spacing for left and right container
 * @param children
 */

const CMSComponentWrapper: React.FC<ComponentWrapperProps> = ({
  maxWidth,
  border = undefined,
  borderRadius = 0,
  borderColor = undefined,
  backgroundImage = undefined,
  backgroundColor = undefined,
  margin = [0, 0],
  children,
  horizontalPadding = null,
  uid
}) => {
  const hasBackground = Boolean(backgroundImage) || Boolean(backgroundColor);
  return (
    <OuterWrapper
      id={uid}
      borderPosition={border}
      borderColor={borderColor}
      borderRadius={borderRadius}
      margin={margin}
      hasBackground={hasBackground}
      data-testid="component-wrapper__container"
    >
      {hasBackground && (
        <Background image={backgroundImage} color={backgroundColor}>
          {backgroundImage}
        </Background>
      )}
      <ContentWrapper
        maxWidth={maxWidth}
        hasBackground={hasBackground}
        horizontalPadding={horizontalPadding}
      >
        {children}
      </ContentWrapper>
    </OuterWrapper>
  );
};

export default CMSComponentWrapper;
