import styled from '@emotion/styled';

import { mq_min, mq_max, mq_only } from '@styles/breakpoints';
import { global_colors } from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';

export const FooterLogo = styled.div`
  margin-bottom: ${getStyleSizes(30)};
`;

export const FooterContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: ${getStyleSizes(50)};

  ${mq_min('md')} {
    grid-template-columns: auto 1fr minmax(225px, auto);
  }
`;

export const FooterContainerLinkSection = styled.section`
  columns: 2;

  ${mq_min('sm')} {
    columns: 3;
  }

  ${mq_min('md')} {
    columns: 2;
  }

  ${mq_min('lg')} {
    columns: 3;
  }

  section {
    break-inside: avoid;
  }
`;

export const FooterContactInfoSection = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: ${getStyleSizes(20)};
  margin-bottom: ${getStyleSizes(20)};

  ${mq_min('md')} {
    row-gap: ${getStyleSizes(50)};
  }
  ${mq_max('md')} {
    margin-top: ${getStyleSizes(20)};
  }
`;

export const DemoButtonContainer = styled.div`
  width: 100%;

  ${mq_only('sm')} {
    max-width: 300px;
  }
`;

export const FooterContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mq_only('sm')} {
    justify-content: flex-start;
  }

  ${mq_min('md')} {
    flex-direction: column;
  }
`;

export const FooterContactLink = styled.a`
  display: flex;
  align-items: center;
  font-family: NoToSans;
  font-weight: 200;
  font-size: ${getStyleSizes(13)};
  color: ${global_colors.text.dark};
  margin-bottom: 0;

  ${mq_min('sm')} {
    margin-right: ${getStyleSizes(20)};
    margin-bottom: ${getStyleSizes(10)};
  }

  svg {
    font-size: ${getStyleSizes(20)};
    margin-right: ${getStyleSizes(8)};
  }
`;

export const FooterUtility = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${getStyleSizes(15)};
  font-family: NoToSans;
  font-weight: 200;
  padding-top: ${getStyleSizes(20)};

  ${mq_min('md')} {
    gap: ${getStyleSizes(30)};
    grid-template-columns: 1fr auto;
  }
`;

export const FooterUtilityLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: ${getStyleSizes(13)};

  li {
    display: inline-block;
    margin-right: ${getStyleSizes(10)};
  }
`;

export const FooterUtilityCopyright = styled.span`
  font-size: ${getStyleSizes(13)};
`;
