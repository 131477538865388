import React from 'react';

export const useOutOfBounds = () => {
  const componentRef = React.useRef<HTMLDivElement>();
  const [isOutOfBounds, setIsOutOfBounds] = React.useState(false);

  const mutationObserverCallback = () => {
    if (componentRef.current) {
      const { top } = componentRef.current.getBoundingClientRect();
      const currentRefPosition = Math.abs(0 - top);

      const windowHeight = Math.min(
        document.documentElement.clientHeight,
        window.innerHeight
      );

      const inOutTheViewPort = currentRefPosition < windowHeight;

      if (inOutTheViewPort) {
        setIsOutOfBounds(false);
      } else {
        setIsOutOfBounds(true);
      }
    }
  };

  React.useLayoutEffect(() => {
    if (componentRef.current) {
      window.addEventListener('scroll', mutationObserverCallback);
    }
    return () => window.removeEventListener('scroll', mutationObserverCallback);
  }, [componentRef]);

  return [componentRef, isOutOfBounds];
};
