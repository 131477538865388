import { CSImage, CSPageConfigurationProps } from '@shared/contentstack';

export interface PageMetaProps {
  canonicalUrl?: string;
  title?: string;
  description?: string;
  keywords?: string;
  noIndex?: boolean | undefined;
  noFollow?: boolean | undefined;
  socialDescription?: string;
  socialTile?: string;
  socialImage?: string;
  domain: string;
  siteConfig: any;
}

const pageMetaMapper = (
  pageMeta: CSPageConfigurationProps,
  social_fallback_image: CSImage,
  domain: string,
  site_configuration: any
): PageMetaProps | null => {
  if (pageMeta) {
    const siteConfig =
      site_configuration?.managed_key_value_pairs?.value.reduce(
        (memo: any, { key, value }: any) => {
          return {
            ...memo,
            [key]: value,
          };
        },
        {}
      );
    const { seo_metadata, social_media_metadata } = pageMeta || {};
    return {
      canonicalUrl: seo_metadata?.canonical_url || '',
      keywords: seo_metadata?.meta_keywords || '',
      noIndex: seo_metadata?.no_index || false,
      noFollow: seo_metadata?.no_follow || false,
      title: seo_metadata?.meta_title || '',
      description: seo_metadata?.meta_description || '',
      socialDescription: social_media_metadata?.description || '',
      socialTile: social_media_metadata?.title || '',
      socialImage:
        social_media_metadata?.image?.url || social_fallback_image?.url,
      domain,
      siteConfig,
    };
  }
  return null;
};

export default pageMetaMapper;
