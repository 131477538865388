import React from 'react';
import { CustomerTestimonialItem, CustomerTestimonialProps } from '../../testimonial-slider.interface';
import { SliderCard } from './slider-card';
import { 
  TestimonialSplideStyled
} from './trialworks-slider.styles';
import { SplideSlide } from '@splidejs/react-splide';

export const TrialworksSlider = ({
  itemList
}: CustomerTestimonialProps) => {
  if (itemList.length) {

    const moreThanOneItem = itemList.length > 1;

    return (
        <TestimonialSplideStyled
          options={{
            type: 'loop',
            focus: 'center'
          }}
        >
            {itemList.map((item: CustomerTestimonialItem, index: number) => {
            return (
                <SplideSlide key={`testimonial__item-${index}`}>
                    <SliderCard {...item} loop={moreThanOneItem}/>
                </SplideSlide>
            )
            })}
        </TestimonialSplideStyled>
    );
  }

  return <></>;
};
