import React from 'react';
import { QuotePromo } from '../quote-promo';
import { quotePromoMapper } from '../quote-promo/mappers/quote-promo.mapper';
import { StandardPromo } from '../standard-promo';
import { standardPromoMapper } from '../standard-promo/mappers/standard-promo.mapper';

export const BoxedPromoWrapper = ({ boxed_promo }: any, maxWidth?: number) => {
  const boxedPromo = boxed_promo[0];

  if (boxedPromo?.internal?.type === 'Contentstack_standard_promo') {
    return (
      <StandardPromo
        {...standardPromoMapper(boxedPromo, {
          backgroundColor: 'light',
          button: false,
          variant: 'boxed',
        })}
        maxWidth={maxWidth}
      />
    );
  }

  if (boxedPromo?.hasOwnProperty('quote')) {
    return (
      <QuotePromo
        {...quotePromoMapper(boxedPromo, {
          backgroundColor: 'light',
          variant: 'boxed',
        })}
      />
    );
  }

  return null;
};
