import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import {
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
  color_miami,
  global_colors,
  color_lighter_grey,
} from '@styles/colors';

import { VariantProp } from './lc-carousel-article-list.interface';

export const themes = {
  light: {
    containerPanelBackground: color_white,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
  },
};

export const Container = styled.div<VariantProp>`
  overflow: hidden;
  padding: ${getStyleSizes([32, 0])};
  background: ${({ variant }) => themes[variant]?.containerPanelBackground};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([96, 0])};
  }
`;

export const Header = styled.header`
  margin-bottom: ${getStyleSizes(24)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(34)};
  }
`;

export const Title = styled.h2`
  font-size: ${getStyleSizes(26)};
  color: ${color_darkest_grey};
  font-weight: 400;
  padding-right: ${getStyleSizes(20)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(36)};
  }
`;

export const SeeAllLink = styled.div`
  display: none;
  text-align: right;
  a {
    font-size: ${getStyleSizes(16)};
    padding-bottom: ${getStyleSizes(4)};
    border-bottom: 1px solid ${color_dark_grey};
    color: ${color_darkest_grey};
    line-height: ${getStyleSizes(26)};
  }

  ${mq_min('lg')} {
    display: block;
    padding-right: ${getStyleSizes(104)};
  }
`;

export const SeeAllLinkMobile = styled(SeeAllLink)`
  display: block;
  ${mq_min('lg')} {
    display: none;
  }
`;

export const SeeAllTile = styled.div`
  background-color: ${color_lighter_grey};
  text-align: center;
  height: 100%;
`;

export const SeeAllTileContainer = styled.div`
  height: 100%;
`;

export const SeeAllTileDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${getStyleSizes(136)};
  width: ${getStyleSizes(164)};

  ${mq_min('lg')} {
    width: ${getStyleSizes(210)};
    height: ${getStyleSizes(200)};
  }

  ${mq_min('xl')} {
    height: ${getStyleSizes(280)};
    width: ${getStyleSizes(280)};
  }
`;

export const Inner = styled.div`
  .swiper {
    overflow: initial;

    .swiper-slide {
      height: 100%;
      width: auto;
    }

    .swiper-pagination {
      position: relative;
      text-align: left;
      bottom: 0;
      margin-top: ${getStyleSizes(24)};

      span {
        background: ${global_colors.swiper.inactiveBullet};
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background: ${color_miami.base};
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &.swiper-button-lock {
        display: none;
      }
    }
  }

  ${mq_min('lg')} {
    .swiper {
      .swiper-pagination {
        display: none;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: block;
        width: auto;
        top: ${getStyleSizes(-54)};
        bottom: auto;
        left: auto;

        &:after {
          content: '';
        }
      }

      .swiper-button-prev {
        right: ${getStyleSizes(44)};
      }
      .swiper-button-next {
        right: 0;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &.swiper-button-lock {
          display: none;
        }
      }
    }
  }
`;
