import React, { useEffect } from 'react';
import { FormBaseline } from './components/form-baseline/form-baseline';
import { LongFormBaseline } from './components/longform-baseline/longform-baseline';

export const FormPanelBaseline = (props: any) => {
  
  useEffect(()=>{
    const mainFooter = document.querySelector('footer');
    const mainHeader = document.querySelector('header');
    if(mainFooter && mainHeader){
      mainFooter.style.display = 'none';
      mainHeader.style.display = 'none';
    }
    return ()=> {
      if(mainFooter && mainHeader){
        mainFooter.removeAttribute('style');
        mainHeader.removeAttribute('style');
      }
    }
  }, []);

  const pageType = props?.page_type;
  const ComponentRenderer = () => {
    switch(pageType.toLowerCase()){
      case 'baseline':
        return <FormBaseline {...props} />;
      case 'longform baseline':
        return <LongFormBaseline {...props} />;
      default:
        return <></>;
    }
  };

  if (pageType) {
    return (
      <section
        id={props.uid}
        data-testid="form-panel__container"
      >
        {ComponentRenderer()}
      </section>
    );
  }

  return <></>;
};
