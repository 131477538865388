import React from 'react';
import { CustomerTestimonialItem, CustomerTestimonialProps } from '../../testimonial-slider.interface';
import { SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import { SliderCard } from './slider-card';
import { 
  TestimonialSwiperStyled, 
  TestimonialArrowContainerStyled,
  TestimonialArrowStyled,
  TestimonialSplideStyled
} from './standard-slider.styles';
import { SplideSlide } from '@splidejs/react-splide';

export const StandardSlider = ({
  itemList
}: CustomerTestimonialProps) => {
  if (itemList.length) {

    const moreThanOneItem = itemList.length > 1;

    return (
      <> 
        {moreThanOneItem ? (
          <TestimonialSplideStyled
            options={{
              type: 'loop',
              autoWidth: true,
              autoHeight: true,
              perMove: 1,
              focus: 'center',
              gap: '64px',
              breakpoints: {
                767: {
                  perPage: 1,
                  gap: '0',
                },
                991: {
                  gap: '48px',
                },
              },
            }}
          >
            {itemList.map((item: CustomerTestimonialItem, index: number) => {
              return (
                <SplideSlide key={`testimonial__item-${index}`}>
                  <SliderCard {...item} loop={moreThanOneItem}/>
                </SplideSlide>
              )
            })}
          </TestimonialSplideStyled>
        ) : (
          <TestimonialSwiperStyled
            slidesPerView={1}
            spaceBetween={24}
            loop={moreThanOneItem}
            initialSlide={1}
            centeredSlides={true}
            pagination={{ clickable: true }}
            modules={[ Navigation, Pagination, A11y ]}
            autoHeight={true}
            navigation={{
              prevEl: `.swiper-button-prev`,
              nextEl: `.swiper-button-next`,
            }}
            breakpoints={{
              768: {
                slidesPerView: 1.27,
                spaceBetween: 32
              },
              1380: {
                slidesPerView: moreThanOneItem ? 1.805 : 1,
                spaceBetween: 48
              },
            }}
          >
            {itemList.map((item: CustomerTestimonialItem, index: number) => {
              return (
                <SwiperSlide key={`testimonial__item-${index}`}>
                  <SliderCard {...item} loop={moreThanOneItem}/>
                </SwiperSlide>
              )
            })}
            {moreThanOneItem && (
              <TestimonialArrowContainerStyled>
                <TestimonialArrowStyled direction="left" className="swiper-button-prev">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.648 8.81047C4.7012 8.85 4.75693 8.88571 4.81467 8.91739L11.0144 12.8851L11.0373 12.9017L11.0776 12.9256C11.192 12.9879 11.3218 13.0119 11.4495 12.9945C11.5773 12.9771 11.6969 12.9191 11.7924 12.8282C11.8879 12.7374 11.9546 12.6181 11.9837 12.4863C12.0127 12.3545 12.0027 12.2165 11.955 12.0908L10.5986 7.5002L11.955 2.91147C12.0042 2.78187 12.0133 2.63927 11.9811 2.50387C11.9488 2.36854 11.8769 2.2472 11.7754 2.15714C11.6739 2.06707 11.5481 2.0128 11.4156 2.002C11.2831 1.9912 11.1508 2.0244 11.0373 2.09693L10.7986 2.24253L9.9405 2.79534L7.96633 4.05407L4.80767 6.0812C4.7534 6.11274 4.70067 6.1472 4.64973 6.1844C4.4492 6.33087 4.28533 6.52614 4.1722 6.75347C4.05907 6.9808 4 7.23353 4 7.49007C4 7.7466 4.05907 7.99934 4.1722 8.22667C4.28533 8.45407 4.4492 8.64927 4.64973 8.79573L4.648 8.81047Z" fill="#1A1A1A"/>
                  </svg>
                </TestimonialArrowStyled>
                <TestimonialArrowStyled direction="right" className="swiper-button-next">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.352 7.18953C11.2988 7.15 11.2431 7.11429 11.1853 7.08261L4.98555 3.11491L4.96274 3.09832L4.92238 3.07436C4.80799 3.01213 4.67822 2.9881 4.55047 3.00552C4.42271 3.02292 4.30308 3.08094 4.20761 3.17177C4.11213 3.26261 4.04539 3.38193 4.01632 3.51373C3.98725 3.64554 3.99725 3.78352 4.04497 3.90918L5.40145 8.4998L4.04497 13.0885C3.9958 13.2181 3.98669 13.3607 4.01893 13.4961C4.05115 13.6315 4.1231 13.7528 4.22458 13.8429C4.32606 13.9329 4.45194 13.9872 4.58441 13.998C4.71687 14.0088 4.84923 13.9756 4.96274 13.9031L5.20139 13.7575L6.0595 13.2047L8.03367 11.9459L11.1923 9.9188C11.2466 9.88726 11.2993 9.8528 11.3503 9.8156C11.5508 9.66913 11.7147 9.47386 11.8278 9.24653C11.9409 9.0192 12 8.76647 12 8.50993C12 8.2534 11.9409 8.00066 11.8278 7.77333C11.7147 7.54593 11.5508 7.35073 11.3503 7.20427L11.352 7.18953Z" fill="#1A1A1A"/>
                    </svg>
                </TestimonialArrowStyled>
              </TestimonialArrowContainerStyled>
            )}
          </TestimonialSwiperStyled>
        )}
        
      </>
    );
  }

  return <></>;
};
