import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

import { color_light_grey } from '@styles/colors';

export const AccordionContainer = styled.div`
  padding: ${getStyleSizes([20, 0, 40, 0])};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([40, 0, 100, 0])};
  }
`;

export const AccordionInner = styled.div`
  border: 1px solid ${color_light_grey};
  border-radius: ${getStyleSizes(8)};
  padding: ${getStyleSizes([40, 32])};

  ${mq_min('lg')} {
    padding: ${getStyleSizes([40, 52])};
  }
`;
