import styled from '@emotion/styled';
import { getStyleBorder, getStyleSizes } from '@styles/styles.utils';
import { color_white } from '@styles/colors';
import { mq_min } from '@styles/breakpoints';

export const SecondaryLogoSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: ${getStyleSizes(35)};

  ${mq_min('lg')} {
    margin: 0;
    padding: ${getStyleSizes([25, 48])};
    border-top: ${getStyleBorder()};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SecondaryLogoTitle = styled.section`
  font-weight: 600;
  font-size: ${getStyleSizes(12)};
  margin-bottom: ${getStyleSizes(20)};
  flex-basis: 100%;
  text-transform: uppercase;

  ${mq_min('lg')} {
    flex-basis: auto;
    margin-bottom: 0;
  }
`;

export const SecondaryLogo = styled.div`
  background: ${color_white};
  border-radius: ${getStyleSizes(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 45%;
  padding: ${getStyleSizes([20, 22])};
  overflow: hidden;
  max-width: ${getStyleSizes(160)};
  aspect-ratio: 5 / 2;

  ${mq_min('lg')} {
    padding: ${getStyleSizes([0, 22])};
  }

  &:first-of-type {
    margin-right: ${getStyleSizes(20)};
  }

  & img {
    display: block;
    height: 26px;
    width: 100%;
    object-fit: contain !important;
    object-position: center;
  }
`;
