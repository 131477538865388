import { ColorTheme, GlobalColors } from './styles.interface';
// GREYSCALE COLORS
export const color_black = '#1A1A1A';
export const color_darkest_grey = '#1A1A1A';
export const color_dark_grey = '#444d5e';
export const color_mid_grey = '#A4A4A4';
export const color_light_grey = '#C4C7CD';
export const color_lighter_grey = '#DEDFE5';
export const color_text_grey = '#EFF2F8';
export const color_lightest_grey = '#F9F9F9';
export const color_white = '#FFFFFF';
export const color_gray = '#697282';
export const color_dark_gray = '#444d5d';
export const color_light_gray = '#28292E';

// GREYSCALE COLORS VARIANTS
export const color_gray_10 = '#E7E7E7';
export const color_gray_40 = '#BDBDBD';
export const color_gray_100 = '#212833';

// BLUE VARIANTS
export const color_blue = '#2270DD';
export const color_blue_10 = '#2C75DE';
export const color_blue_20 = '#2773DD';
export const color_bright_blue = '#65DFFF';

// LOGO BASE COLORS
export const color_logo_blue_dk = '#212833';
export const color_logo_blue_lt = '#5DDAFB';
export const color_logo_grad_10 = '#2BC1E9';
export const color_logo_grad_20 = '#0F9ABA';
export const color_logo_grad_40 = '#13A4C6';
export const color_logo_grad_70 = '#17AFD3';
export const color_logo_grad_100 = '#18B3D7';

// THEME COLORS
export const color_miami: ColorTheme = {
  palette: 'miami',
  base: '#25c9ef',
  dark: '#3ACDEF',
  light: '#8DE7FF',
};

export const color_indigo: ColorTheme = {
  palette: 'indigo',
  base: '#304cb4',
  dark: '#1C399E',
  light: '#5F96E6',
};

export const color_charcoal: ColorTheme = {
  palette: 'charcoal',
  base: color_darkest_grey,
  dark: color_black,
  light: color_dark_grey,
};

export const color_concrete: ColorTheme = {
  palette: 'concrete',
  base: '#85898c',
  dark: '#6d7174',
  light: '#a0a4a6',
};

export const color_eggshell: ColorTheme = {
  palette: 'eggshell',
  base: '#f5f5f5',
  dark: '#dbdbdb',
  light: color_white,
};

// GLOBAL COLORS
export const global_colors: GlobalColors = {
  background: {
    black: color_black,
    white: color_white,
    dark: color_darkest_grey,
    grey: '#737578',
    blue: '#2773dd',
    darkblue: '#061248',
    green: '#028378',
  },
  border: {
    dark: color_light_grey,
    light: color_white,
  },
  notification: {
    error: '#FF0000',
    warn: '#FFCC00',
  },
  text: {
    dark: color_darkest_grey,
    grey: color_dark_grey,
    light: color_white,
  },
  link: {
    default: '#1976d2',
    dark: '#000000de',
  },
  swiper: {
    inactiveBullet: '#dadbe1',
  },
  socialNetwork: {
    background: '#14142B',
  },
};

export const background_color_mapper: any = {
  light: color_white,
  mid: color_lightest_grey,
  dark: color_black,
};

export const headline_color_mapper: any = {
  light: color_darkest_grey,
  mid: color_darkest_grey,
  dark: color_white,
};

export const text_color_mapper: any = {
  light: global_colors.text.dark,
  mid: global_colors.text.grey,
  dark: global_colors.text.light,
};

export const eyebrow_color_mapper: any = {
  light: color_blue,
  mid: color_blue,
  dark: color_miami.base,
};

// STORYBOOK ITEMS

export const storybook_palettes: string[] = [
  color_miami.palette,
  color_indigo.palette,
  color_charcoal.palette,
  color_concrete.palette,
  color_eggshell.palette,
];
export const storybook_colors: { [key: string]: string } = {
  [color_miami.base]: 'Miami',
  [color_indigo.base]: 'Indigo',
  [color_charcoal.base]: 'Charcoal',
  [color_concrete.base]: 'Concrete',
  [color_eggshell.base]: 'Eggshell',
  [global_colors.text.dark]: 'Text',
  [global_colors.text.light]: 'Text Reverse',
};
