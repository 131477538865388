import { CardPresentationProps } from '@src/shared/components/card-presentation/card-presentation.interface';
import { CSLCArticle } from '@src/shared/contentstack';
import { color_blue } from '@src/styles/colors';

export const titleFeatureMapper = (cards: CSLCArticle[]) =>
  cards?.map((card) => {
    const { logo_card, featured_image } = card || {};
    const imageLogic = featured_image?.image?.image
      ? { image: featured_image?.image?.image }
      : { icon: logo_card?.logo.image };

    return {
      url: card?.url,
      type: 'title',
      variant: 'tall',
      title: card?.headline,
      subtitle: card?.customer,
      background: card?.logo_card?.card_color?.colors || color_blue,
      ...imageLogic,
    };
  }) as CardPresentationProps[];
