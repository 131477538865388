import styled from '@emotion/styled';
import * as StyledHero from '@cms-components/hero-banner/hero-banner.styles';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

export const BannerSimple = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${getStyleSizes([40, 10])};
  color: ${({ color }) => color};
`;

export const BannerSimpleHeadline = styled.div`
  &,
  & > h1 {
    line-height: 1;
    font-size: ${getStyleSizes(34)};
    font-weight: normal;
    text-align: center;

    ${mq_min('lg')} {
      font-size: ${getStyleSizes(50)};
    }
  }
`;

export const BannerSimpleCopy = styled(StyledHero.HeroBannerCopy)`
  text-align: center;
  margin-top: ${getStyleSizes(22)};
`;
