import styled from '@emotion/styled';
import ExpandLessMore from '@mui/icons-material/ExpandMoreOutlined';

import { getStyleSizes } from '@styles/styles.utils';
import { color_black, color_light_grey, color_white } from '@styles/colors';

import { NavItemIconStyledProps } from '@cms-components/header/components/nav/nav.interface';
import { mq_min } from '@styles/breakpoints';
import {
  BasicLinkStyledProps,
  MenuItemLabelProps,
  NavItemLinkStyledProps,
} from '@cms-components/header/components/nav-menu-item/nav-menu-item.interface';

export const NavListItemStyled = styled.li`
  padding: ${getStyleSizes([0, 16])};
  border-bottom: ${getStyleSizes(1)} solid ${color_light_grey};
  width: 100%;

  ${mq_min('lg')} {
    cursor: pointer;
    border: 0;
    width: auto;
    position: relative;
    padding: ${getStyleSizes([0, 8])};

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  &:first-of-type {
    border-top: ${getStyleSizes(1)} solid ${color_light_grey};

    ${mq_min('lg')} {
      border: 0;
    }
  }
`;

export const NavItemLinkStyled = styled.a<NavItemLinkStyledProps>`
  border: 0;
  background: transparent;
  font-family: 'Causten', Arial, sans-serif;
  color: ${color_black};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: ${getStyleSizes(60)};
  width: 100%;

  ${mq_min('lg')} {
    height: auto;
    color: ${({ color }) => (color === 'dark' ? color_white : color_black)};
  }
`;

export const NavItemIconStyled = styled(ExpandLessMore)<NavItemIconStyledProps>`
  width: ${getStyleSizes(28)};
  transition: transform 100ms linear;
  ${({ state }) =>
    `transform: ${state === 'open' ? 'rotateX(180deg);' : 'rotateX(0);'}`};
`;

export const MenuItemLabel = styled.div<MenuItemLabelProps>`
  font-size: ${getStyleSizes(18)};
  font-weight: 500;
  word-break: keep-all;

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(14)};
    font-weight: ${({ state }) => (state === 'open' ? 600 : 'normal')};
  }
`;

export const BasicLinkStyled = styled.span<BasicLinkStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${color_black};
  height: ${getStyleSizes(60)};
  width: 100%;
  font-size: ${getStyleSizes(18)};
  font-family: 'Causten', Arial, sans-serif;

  ${mq_min('lg')} {
    height: auto;
    font-size: ${getStyleSizes(14)};
    color: ${({ color }) => (color === 'dark' ? color_white : color_black)};
  }

  & span {
    font-weight: 500;
    word-break: keep-all;
  }
`;
