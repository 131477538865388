import { styled } from '@mui/material/styles';
import {  getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { causten_font } from '@src/styles/typography';
import {
  color_black,
  color_dark_grey,
  color_text_grey,
  color_darkest_grey,
  color_white,
  color_miami,
} from '@styles/colors';
import { VariantProp } from '@shared/components/accordion/accordion.interface';
import { AccordionLayoutProps } from './accordion.interface';
export const variants = {
  light: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  mid: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  dark: {
    headlineColor: color_white,
    detailColor: color_text_grey,
    iconColor: color_white,
  },
};

export const AccordionStyled = styled('div')<AccordionLayoutProps>`
  display: flex;
  justify-content: ${({ layout }) => layout === 'Vertical' ? `space-between` : `center`};
  ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
    align-items: flex-start;
  ` : `
    align-items: center;
  `};
  ${mq_min('lg')} {
    width: 100%;
  }
`;

export const AccordionItemsStyled = styled('div')<AccordionLayoutProps>`
  max-width: 100%;
  width: 100%;
  ${mq_min('lg')} {
    ${({ layout, template }) => layout === 'Vertical' && `
      ${(template === 'needles' || template === 'trialworks') ? `
        max-width: ${getStyleSizes(500)};
        flex-shrink: 0;
      ` : `
        max-width: ${getStyleSizes(500)};
      `};
    `};
  }
`;

export const AccordionTitleStyled = styled('div')<VariantProp & AccordionLayoutProps>`
  font-family: ${causten_font};
  text-align: center;
  margin-bottom: ${getStyleSizes(48)};
  font-size: ${getStyleSizes(32)};
  font-weight : 600;
  line-height: ${getStyleSizes(38.4)};
  color: ${({ variant }) => variants[variant]?.iconColor};
  width: 100%;
  padding: ${getStyleSizes([0,16])};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(40)};
    line-height: ${getStyleSizes(48)};
    ${({ template }) => `
      ${(template === 'needles' || template === 'trialworks') ? `
        font-size: ${getStyleSizes(36)};
        line-height: ${getStyleSizes(44)};
      ` : `
        font-size: ${getStyleSizes(40)};
        line-height: ${getStyleSizes(48)};
      `};
    `};
  }

  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(66)};
  }
`;
