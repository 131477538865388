import { ListPanelProps } from '../list-panel.interface';
import { CSListPanel } from '@shared/contentstack';

export function listPanelMapper(data: CSListPanel): ListPanelProps {
  const {
    background_color = 'light',
    eyebrow,
    panel_headline,
    panel_copy,
    list_type,
    paragraph,
    faq_list,
    uid
  } = data || {};

  const listContent = paragraph?.map(
    ({ item_headline, item_copy, item_link }) => ({
      headline: item_headline,
      copy: item_copy,
      link: {
        text: item_link?.link_text,
        url: item_link?.link_url,
        isExternal: Boolean(item_link?.external_link),
      },
    })
  );

  return {
    variant: background_color || 'light',
    eyebrow: eyebrow,
    headline: panel_headline,
    copy: panel_copy,
    listType: list_type,
    uid: uid || '',
    paragraphContent: listContent,
    bulletsContent: listContent,
    faqContent: faq_list?.map((item) => {
      return {
        title: item?.accordion_title || '',
        items: item?.accordion_items || [],
      };
    }),
  };
}
