import React from 'react';
import {
  RichText,
} from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import {
  CSHeroBannerSPZProps,
} from '../../hero-banner.interface';
import { 
  BannerHeadlineContainer,
  BannerContainer,
  BannerInner,
  BannerLogoImageStyled,
  BannerLeftSideContainer,
  BannerRightSideContainer,
  BannerImageStyled,
  BannerContentContainer
} from './banner-standard.styles';
 import { PrimaryButton } from '@shared/components';
 import { default_breakpoints } from '@src/styles/breakpoints';

export const BannerStandard = ({
  variant = 'light',
  template = '',
  logo,
  headline,
  content,
  button_link,
  banner_image,
  tablet_banner_image,
  mobile_banner_image
}: CSHeroBannerSPZProps) => {

  return (
    <BannerContainer
      data-testid={`banner-${template}__container`}
    >
      <CMSComponentWrapper 
        horizontalPadding={0}
      >
        <BannerInner>
          <BannerLeftSideContainer>
            {logo?.image?.url && (
              <BannerLogoImageStyled
                src={logo?.image.url}
                alt={logo?.image_alt_text}
              />
            )}
            <BannerHeadlineContainer>
              <RichText content={headline} />
            </BannerHeadlineContainer>
            <BannerContentContainer>
              <RichText content={content} />
            </BannerContentContainer>
            <PrimaryButton
              ariaLabel="see more"
              path={button_link?.link_url}
              label={`${button_link?.link_text}`}
              size={'large'}
              variant={'contained'}
              icon={'text'}
            />
          </BannerLeftSideContainer>
          <BannerRightSideContainer>
              <picture>
                <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={banner_image?.image.url} />
                <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={tablet_banner_image?.image.url} />
                <BannerImageStyled
                    src={mobile_banner_image?.image.url}
                    alt={banner_image?.image_alt_text}
                  />
              </picture>
          </BannerRightSideContainer>
        </BannerInner>
      </CMSComponentWrapper>
    </BannerContainer>
  );
};
