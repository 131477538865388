import { Helmet } from 'react-helmet';
import React from 'react';
import { PageMetaProps } from '@shared/mappers/pageMeta.mapper';

export const PageMeta = (
  {
    canonicalUrl,
    title,
    description,
    keywords,
    noIndex,
    noFollow,
    socialDescription,
    socialTile,
    socialImage,
    domain,
    siteConfig,
  }: PageMetaProps,
  location: any
) => {
  if (!!location) {
    const { origin, pathname } = location || {};
    canonicalUrl = canonicalUrl || `${origin}${pathname}`;
  }
  return (
    <Helmet htmlAttributes={{ lang: 'en-us' }}>
      <meta property="og:type" content="website" />
      {noIndex && <meta name="robots" content="noindex" />}
      {noFollow && <meta name="robots" content="nofollow" />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {socialTile && <meta property="og:title" content={socialTile} />}
      {socialTile && <meta name="twitter:title" content={socialTile} />}

      {socialDescription && (
        <meta property="og:description" content={socialDescription} />
      )}
      {socialDescription && (
        <meta name="twitter:description" content={socialDescription} />
      )}
      {socialImage && <meta property="og:image:url" content={socialImage} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={siteConfig?.og_site_name} />
      <meta name="twitter:card" content={siteConfig?.twitter_card} />
      <meta name="twitter:site" content={siteConfig?.twitter_site} />
      <meta
        name="facebook-domain-verification"
        content={siteConfig?.FACEBOOK_DOMAIN_VERIFICATION}
      />
    </Helmet>
  );
};
