import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import {  getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { causten_font, noTo_font } from '@src/styles/typography';
import {
  color_black,
  color_dark_grey,
  color_text_grey,
  color_darkest_grey,
  color_white,
  color_eggshell,
  color_light_grey,
  color_miami,
} from '@styles/colors';
import { VariantProp } from '@shared/components/accordion/accordion.interface';
export const variants = {
  light: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  mid: {
    headlineColor: color_darkest_grey,
    detailColor: color_dark_grey,
    iconColor: color_black,
  },
  dark: {
    headlineColor: color_white,
    detailColor: color_text_grey,
    iconColor: color_white,
  },
};

export const AccordionStyled = styled('div')`
  border-bottom: 1px solid ${color_dark_grey};
`;

export const AccordionItemsStyled = styled('div')`
 
`;

export const AccordionTitleStyled = styled('div')<VariantProp>`
  font-family: ${causten_font};
  text-align: center;
  margin-bottom: ${getStyleSizes(64)};
  font-size: ${getStyleSizes(32)};
  font-weight: 600;
  line-height: ${getStyleSizes(38.4)};
  color: ${color_white};
  width: 100%;
  padding: ${getStyleSizes([0,16])};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(36)};
    line-height: ${getStyleSizes(44)};
  }
  ${mq_min('lg')} {
    margin-bottom: ${getStyleSizes(66)};
  }
`;

export const MuiAccordionStyled = styled(MuiAccordion)`
  position: relative;
  transition: height 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  overflow: hidden;
  padding: ${getStyleSizes(0)};
  border: none;
  max-width: 100%;
  margin: auto;
  background: transparent;
  &.Mui-expanded{
    .MuiAccordionDetails-root{
      border-top: none !important;
    }
    .MuiAccordionSummary-root{
      background: rgba(68, 77, 94, 0.12);
    }
  }
  ${mq_min('lg')} {
    max-width: calc(100% - ${getStyleSizes(64)});
    max-width: 100%;
  }
`;

export const MuiAccordionSummaryStyled = styled(MuiAccordionSummary)`
  position: relative;
  color: ${color_eggshell.base};
  font-family: ${causten_font};
  font-size: ${getStyleSizes(18)};
  font-style: normal;
  font-weight: 600;
  line-height: ${getStyleSizes(27)};
  padding: ${getStyleSizes(24)};
  border-top: 1px solid ${color_dark_grey};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(20)};
    line-height: ${getStyleSizes(24)};
  }
  .MuiAccordionSummary-content{
    margin: 0 !important;
    padding-right: ${getStyleSizes(32)};
    ${mq_min('md')} {
      padding-right: 0;
    }
  }
`;

export const MuiAccordionDetailsStyled = styled(MuiAccordionDetails)`
  padding: ${getStyleSizes([0,24,24])};
  background: rgba(68, 77, 94, 0.12);
  border-top: 0;
  border-top: 1px solid ${color_dark_grey};

  div[data-testid="rich-text__container"]{
    max-width:  ${getStyleSizes(700)};
    p, span{
      color: ${color_light_grey};
      font-family: ${noTo_font};
      font-size:  ${getStyleSizes(14)};
      font-style: normal;
      font-weight: 400;
      line-height: ${getStyleSizes(22)};
      ${mq_min('md')} {
        font-size:  ${getStyleSizes(16)};
        line-height: ${getStyleSizes(24)};
      }
    }

    ul, ol{
      padding-left:  ${getStyleSizes(18)};
      li{
        padding-left:  ${getStyleSizes(6)};
        margin-bottom: ${getStyleSizes(4)};
        &::marker{
          color: ${color_light_grey};
          font-size:  ${getStyleSizes(12)};
        }
      }
    }
  }
`;

export const AccordionIndicatorStyled = styled('svg')`
  width: ${getStyleSizes(32)};
  height: ${getStyleSizes(32)};
  fill: none;
`