import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';

export const AccordionContainer = styled.div`
  background: radial-gradient(58.79% 80.15% at 50% 11.37%, #223A57 0%, #1C232B 59.23%, #1A1B1B 100%), #1A1A1A;
  padding: ${getStyleSizes([56, 20])};
  ${mq_min('md')} {
     padding: ${getStyleSizes([64, 20])};
  }
`;

export const AccordionInner = styled.div`

`;
