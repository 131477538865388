import React, { useState,useEffect } from 'react';
import { default_breakpoints } from '@src/styles/breakpoints';

const useIsMobile = (breakpoint: number = default_breakpoints.lg) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
  
    useEffect(() => {
      setIsMobile(window.innerWidth < breakpoint);
      const handleResize = () => {
        setIsMobile(window.innerWidth < breakpoint);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [breakpoint]);
  
    return isMobile;
  };
  
  export default useIsMobile;