import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { Accordion as AccordionComponent, TextColor } from '@shared/components';

import { AccordionContainer, AccordionInner } from './accordion.styles';
import { AccordionProps } from './accordion.interface';

import { CSAccordion } from '@shared/contentstack/contentstack.interface';
import { accordionMapper } from './mappers/accordion.mapper';

export const Accordion = ({
  title,
  itemList,
  additionalInformationContent,
  additionalInformationTitle,
  site_configuration,
  uid,
}: AccordionProps) => {
  return (
    <AccordionContainer data-testid="accordion_container">
      <CMSComponentWrapper uid={uid} maxWidth={650}>
        <AccordionInner>
          <AccordionComponent
            title={title}
            itemList={itemList?.map(({ summary, details }) => ({
              summary: <TextColor>{summary}</TextColor>,
              details,
            }))}
            summarySize="large"
            additionalInfo={{
              title: additionalInformationTitle,
              content: additionalInformationContent,
            }}
          />
        </AccordionInner>
      </CMSComponentWrapper>
    </AccordionContainer>
  );
};

export const MappedAccordion = (data: CSAccordion) => {
  return <Accordion {...accordionMapper(data)} />;
};
