import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

import { CustomGridProps } from './feature-tile.interface';
import { getStyleSizes } from '@styles/styles.utils';
import { color_miami } from '@styles/colors';
import { shadow_defaults } from '@styles/settings';
import { global_typography } from '@src/styles/typography';

const featureTileConfig = {
  containerTextPadding: 20,
  topMargin: {
    mobile: getStyleSizes(12),
    desktop: getStyleSizes(32),
  },
};

export const CustomGrid = styled(Grid)<CustomGridProps>`
  flex-direction: column-reverse;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  ${({ theme, variant }) =>
    variant === 'default' &&
    `
    ${theme.breakpoints.up('sm')} {
      flex-direction: row-reverse;
    }
  `}
`;

export const ContainerText = styled('article')`
  padding-bottom: ${getStyleSizes(featureTileConfig.containerTextPadding)};
`;

export const Image = styled(GatsbyImage)`
  max-width: 100%;
  margin: ${getStyleSizes([0, 'auto'])};
  display: block;
  filter: drop-shadow(
    ${getStyleSizes(shadow_defaults.size)} ${shadow_defaults.color}
  );
`;

export const Title = styled(Typography)`
  padding: 20px 10px;
  font-weight: normal;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${getStyleSizes(36)};
  }
`;

export const Description = styled('div')`
  font-family: 'NotoSans', 'Roboto', 'Helvetica, Arial, sans-serif';
  font-size: ${getStyleSizes(global_typography.copy.base)};
  margin-top: ${featureTileConfig.topMargin.mobile};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${getStyleSizes(15)};
    margin-top: ${featureTileConfig.topMargin.desktop};
  }
`;

export const Tag = styled(Typography)`
  color: ${color_miami.base};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${getStyleSizes(18)};
    margin-bottom: ${getStyleSizes(16)};
  }
`;
export const FooterText = styled('div')`
  margin-top: ${featureTileConfig.topMargin.mobile};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${featureTileConfig.topMargin.desktop};
  }
`;
