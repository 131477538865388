import React from 'react';
import { FeatureLogoStyled } from './feature-logo.styles';
import { NavPanelFeatureLogo } from './feature-logo.interface';
import BasicLink from '@shared/components/basic-link';
import { ImageComponent } from '@shared/components/image-component/image-component';
import { useMainNav } from '@cms-components/header/context/main-nav.context';

export const FeatureLogo = ({ data }: NavPanelFeatureLogo) => {
  const { closeNav } = useMainNav();
  const { link_text, link_url, alt_text = '', is_external, image } = data || {};
  if (link_url && alt_text && (image?.gatsbyImageData || image?.url)) {
    return (
      <FeatureLogoStyled onClick={() => closeNav()}>
        <BasicLink
          text={''}
          url={link_url}
          isExternal={is_external}
          underline={false}
        >
          <ImageComponent
            image={image.gatsbyImageData}
            alt_text={alt_text}
            fallback={image.url}
            fallback_shadow={false}
          />
        </BasicLink>

        <span onClick={() => closeNav()}>
          <BasicLink
            text={link_text}
            url={link_url}
            isExternal={is_external}
            underline={true}
          />
        </span>
      </FeatureLogoStyled>
    );
  }
  return null;
};
